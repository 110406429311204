import { styled } from '@mui/material';
import type { ComponentProps, FC } from 'react';

import type { FramingSuggestionsProps } from '../../..';
import { preventForwardProps } from '../../../../utilities/preventForwardProps';

const SVG: FC<ComponentProps<'svg'>> = (props) => (
  <svg width="280" height="320" viewBox="0 0 280 320" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6 21C6 22.6569 4.65685 24 3 24C1.34315 24 0 22.6569 0 21V7.99999C0 3.58171 3.58172 0 8 0H21C22.6569 0 24 1.34315 24 3C24 4.65685 22.6569 6 21 6H8C6.89542 6 6 6.89543 6 7.99999V21Z"
      fill="white"
    />
    <path
      d="M259 6H272C273.105 6 274 6.89543 274 8V21C274 22.6569 275.343 24 277 24C278.657 24 280 22.6569 280 21V8C280 3.58172 276.418 0 272 0H259C257.343 0 256 1.34315 256 3C256 4.65685 257.343 6 259 6Z"
      fill="white"
    />
    <path
      d="M3 296C1.34315 296 0 297.343 0 299V312C0 316.418 3.58172 320 8 320H21C22.6569 320 24 318.657 24 317C24 315.343 22.6569 314 21 314H8C6.89543 314 6 313.105 6 312V299C6 297.343 4.65685 296 3 296Z"
      fill="white"
    />
    <path
      d="M277 296C275.343 296 274 297.343 274 299V312C274 313.105 273.105 314 272 314H259C257.343 314 256 315.343 256 317C256 318.657 257.343 320 259 320H272C276.418 320 280 316.418 280 312V299C280 297.343 278.657 296 277 296Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M141.271 19C115.919 19 88.5721 23.4814 69.4589 43.0398C50.2894 62.6559 40.152 96.6417 47.294 153.816C51.8614 190.381 66.2554 222.409 84.3423 245.35C102.327 268.162 124.407 282.5 144.5 282.5C164.56 282.5 187.283 268.338 204.826 245.524C222.466 222.585 235.271 190.434 235.271 153.444C235.271 99.208 225.057 65.4745 207.903 45.2998C190.634 24.9892 166.845 19 141.271 19ZM53.2477 153.073C46.2041 96.6853 56.4378 64.9489 73.7501 47.2333C91.1189 29.4599 116.365 25 141.271 25C165.954 25 187.666 30.7608 203.332 49.1863C219.114 67.7477 229.271 99.7365 229.271 153.444C229.271 189.05 216.941 219.927 200.07 241.867C183.103 263.931 161.94 276.5 144.5 276.5C127.093 276.5 106.558 263.838 89.0541 241.636C71.6516 219.563 57.6811 188.564 53.2477 153.073Z"
      fill="white"
    />
    <path
      d="M98.3652 276.889C98.8563 275.307 97.9716 273.626 96.3892 273.135C94.8068 272.644 93.1259 273.528 92.6348 275.111C92.5882 275.261 92.5377 275.411 92.4835 275.561C91.52 278.227 89.3734 280.832 86.3233 283.301C83.1323 285.884 79.1927 288.125 75.28 289.975C71.3826 291.818 67.6054 293.23 64.7953 294.182C63.3933 294.657 62.2394 295.015 61.4411 295.253C61.0421 295.372 60.7324 295.461 60.5255 295.519C60.4668 295.535 60.4165 295.549 60.3747 295.561L60.294 295.583L60.2393 295.598L60.2278 295.601L60.226 295.602C58.6257 296.029 57.6745 297.672 58.1013 299.273C58.5282 300.874 60.1721 301.826 61.773 301.399L61 298.5C61.773 301.399 61.7745 301.398 61.7745 301.398L61.7767 301.398L61.8042 301.39L61.8784 301.37C61.9419 301.353 62.0332 301.328 62.1506 301.294C62.3853 301.228 62.7245 301.131 63.1546 301.003C64.0145 300.747 65.2395 300.366 66.7203 299.865C69.6758 298.864 73.6799 297.369 77.845 295.4C81.9948 293.437 86.3989 290.959 90.0985 287.965C93.7675 284.994 96.9914 281.316 98.3652 276.889Z"
      fill="white"
    />
    <path
      d="M192.938 275.392C192.602 273.77 191.015 272.727 189.392 273.062C187.77 273.398 186.727 274.985 187.062 276.608C188.049 281.379 191.427 284.697 195.292 286.969C199.157 289.241 203.854 290.684 208.249 291.622C212.677 292.566 216.978 293.033 220.156 293.265C221.75 293.382 223.073 293.441 224.003 293.47C224.468 293.485 224.835 293.492 225.09 293.496C225.217 293.498 225.316 293.499 225.385 293.499L225.466 293.5L225.489 293.5H225.498C225.498 293.5 225.5 293.5 225.5 290.5V293.5C227.157 293.5 228.5 292.157 228.5 290.5C228.5 288.843 227.157 287.5 225.501 287.5H225.499L225.487 287.5L225.428 287.5C225.374 287.499 225.29 287.498 225.179 287.497C224.956 287.493 224.622 287.487 224.193 287.473C223.333 287.446 222.094 287.391 220.594 287.281C217.584 287.061 213.573 286.622 209.501 285.753C205.396 284.878 201.406 283.603 198.333 281.797C195.261 279.991 193.451 277.871 192.938 275.392Z"
      fill="white"
    />
  </svg>
);

const Head = styled(SVG)(({ theme }) => ({
  position: 'absolute',
  display: 'block',
  width: 'auto',
  opacity: 0.8,
  color: theme.palette.brand.white,
}));

export const HeadHorizontal = styled(
  Head,
  preventForwardProps(['facingMode'])
)<Pick<FramingSuggestionsProps, 'facingMode'>>(({ facingMode }) => ({
  height: '69%',
  top: '6%',
  left: facingMode === 'user' ? '16%' : undefined,
  right: facingMode === 'user' ? undefined : '16%',
}));

export const HeadVertical = styled(Head)(() => ({
  height: '40%',
  top: '18%',
  left: '50%',
  transform: 'translateX(-50%)',
}));

export const HeadSquare = styled(Head)(() => ({
  height: '65%',
  top: '5%',
  left: '50%',
  transform: 'translateX(-50%)',
}));

export const HeadPortrait = styled(Head)(() => ({
  height: '62%',
  top: '7%',
  left: '50%',
  transform: 'translateX(-50%)',
}));
