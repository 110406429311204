import type { FC } from 'react';

import { Markdown, Typography } from '@cofenster/web-components';

export const CaptureInstruction: FC<{ instruction?: string | null }> = ({ instruction }) => {
  if (!instruction) return null;

  return (
    <Typography variant="l" component="div">
      <Markdown>{instruction}</Markdown>
    </Typography>
  );
};
