import type { FC } from 'react';

import { Message } from '@cofenster/web-components';

import { useDialogs } from '../../../context/dialogs/useDialogs';
import { useTracking } from '../../../context/tracking';

export const FilmingTipsMessage: FC = () => {
  const { openDialog } = useDialogs();
  const tracking = useTracking();

  const handleClick = () => {
    openDialog('FilmingTipsDialog', {});
    tracking.trackEvent({ event: 'recordingTipsOpened' });
  };

  return (
    <Message
      title="i18n.FilmingTipsLink.text"
      icon="GraduationCapIcon"
      description="i18n.FilmingTipsLink.textSecondary"
      actionText="i18n.FilmingTipsLink.learnMoreButton"
      onClick={handleClick}
    />
  );
};
