import { useCallback, useContext, useMemo } from 'react';

import { TasksContributionsContext } from './TaskProgressProvider';

export const useCurrentUserTaskContributionIds = (taskId: string) => {
  const [contributionIdsByTaskId, setContributionIdsByTaskId] = useContext(TasksContributionsContext);

  const contributionIds = useMemo(() => contributionIdsByTaskId[taskId] ?? [], [contributionIdsByTaskId, taskId]);
  const addContributionId = useCallback(
    (contributionId: string) =>
      setContributionIdsByTaskId((contributionIdsByTaskId) => ({
        ...contributionIdsByTaskId,
        [taskId]: [...new Set([...(contributionIdsByTaskId[taskId] ?? []), contributionId])],
      })),
    [taskId, setContributionIdsByTaskId]
  );
  const deleteContributionId = useCallback(
    (contributionId: string) => {
      setContributionIdsByTaskId((contributionIdsByTaskId) => {
        if (!Array.isArray(contributionIdsByTaskId[taskId])) {
          return contributionIdsByTaskId;
        }

        return {
          ...contributionIdsByTaskId,
          [taskId]: contributionIdsByTaskId[taskId].filter((id) => id !== contributionId),
        };
      });
    },
    [taskId, setContributionIdsByTaskId]
  );

  return [contributionIds, addContributionId, deleteContributionId] as const;
};
