import type { FC, PropsWithChildren } from 'react';

import { GridContainer, GridItem, Typography, styled, useI18n } from '@cofenster/web-components';

import { useCaptureAssetLifecycleFlow } from '../CaptureAssetLifecycleFlow';
import { recordingPageComponentsZIndexes } from '../recordingPageComponentsZIndexes';

import { CaptureInstruction } from './CaptureInstruction';
import { getTaskTitle } from './getTaskTitle';

const MobileCaptureTitleAndInstructionsContainer = styled('div')(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: recordingPageComponentsZIndexes.mobileSceneTitleAndInstructions,
  maxHeight: '80vh',
  backgroundColor: theme.palette.brand.white,
  borderTopRightRadius: theme.spacing(2),
  borderTopLeftRadius: theme.spacing(2),
  padding: theme.spacing(2),
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  overflow: 'auto',
}));

export const MobileCaptureTitleAndInstructions: FC<PropsWithChildren> = ({ children }) => {
  const { title, instruction } = useCaptureAssetLifecycleFlow();
  const { translate } = useI18n();

  return (
    <MobileCaptureTitleAndInstructionsContainer>
      <GridContainer spacing={1} direction="row">
        <GridItem xs={12}>
          <Typography variant="h4">{getTaskTitle({ title, instruction }, translate)}</Typography>
        </GridItem>
        <GridItem xs={12}>
          <CaptureInstruction instruction={instruction} />
        </GridItem>
        {children}
      </GridContainer>
    </MobileCaptureTitleAndInstructionsContainer>
  );
};
