import { styled } from '@mui/material';
import { type FC, type PropsWithChildren, memo, useCallback, useEffect, useMemo, useState } from 'react';

import { useAudioAnalysisStream } from '../../../../../contexts/audioAnalysisStream/useAudioAnalysisStream';
import { useAudioVolume } from '../../../../../hooks/media/useAudioVolume';
import { IconButton } from '../../../../controls/Button/IconButton';
import { Tooltip } from '../../../../feedback/Tooltip';
import { Typography } from '../../../../typography/Typography';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(1),
}));

const useVisited = (value: boolean) => {
  const [isVisited, setIsVisited] = useState(!value);

  const markVisited = useCallback(() => setIsVisited(true), []);

  useEffect(() => {
    if (value) setIsVisited(false);
  }, [value]);

  return { markVisited, isVisited };
};

export const NoAudioTooltip: FC<
  PropsWithChildren<{
    isMuted: boolean;
  }>
> = memo(({ children, isMuted }) => {
  const { mediaStream } = useAudioAnalysisStream();
  const { isSpeaking } = useAudioVolume(mediaStream, 200, 16, isMuted);

  const [isOpen, setIsOpen] = useState(false);
  const { markVisited, isVisited } = useVisited(isMuted);

  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  useEffect(() => {
    if (isSpeaking && isMuted && !isVisited) {
      markVisited();
      setIsOpen(true);
    }

    if (!isMuted) {
      setIsOpen(false);
    }
  }, [isSpeaking, isMuted, markVisited, isVisited]);

  // Memoize the title component prevents jumping tooltip from IconButton
  const titleContent = useMemo(
    () => (
      <Container>
        <Typography>i18n.global.noAudioTooltipText</Typography>
        <IconButton
          onClick={close}
          icon="CloseIcon"
          label="i18n.global.close"
          iconColor="white"
          hoverBackgroundColor="carbon_alpha50"
        />
      </Container>
    ),
    [close]
  );

  return (
    <Tooltip open={isOpen} title={titleContent} maxWidth={400}>
      <div>{children}</div>
    </Tooltip>
  );
});
