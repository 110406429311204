import { Button, Typography, UserProfile, styled } from '@cofenster/web-components';
import { useFormikContext } from 'formik';
import { type FC, useEffect, useState } from 'react';
import { BottomFixedContainer } from '../../components';
import { useContributors } from '../../context/contributors';
import { ContributorsDetails, ContributorsForm } from './ContributorsForm';

const SingleColumnLayout = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'stretch',
  gap: theme.spacing(1),
}));

const Footer = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  marginTop: 2,
  [theme.breakpoints.up('md')]: {
    marginTop: 'auto',
  },
}));

const Title: FC<{ projectCreator?: { firstname: string; lastname: string } }> = ({ projectCreator }) => {
  return projectCreator ? (
    <Typography
      variant="h2"
      component="h1"
      i18nParams={{
        firstname: projectCreator.firstname,
        lastname: projectCreator.lastname,
      }}
    >
      i18n.WelcomePage.welcomeMessage.title
    </Typography>
  ) : (
    <Typography variant="h2" component="h1">
      i18n.WelcomePage.welcomeMessage.title.noProjectCreator
    </Typography>
  );
};

const DescriptionFirstLine: FC<{
  projectCreator?: { firstname: string; lastname: string };
  projectName: string;
}> = ({ projectCreator, projectName }) => {
  return projectCreator ? (
    <Typography
      variant="l"
      component="p"
      i18nParams={{
        firstname: projectCreator.firstname,
        lastname: projectCreator.lastname,
        projectName,
      }}
    >
      i18n.WelcomePage.welcomeMessage.descriptionParagraph1
    </Typography>
  ) : (
    <Typography variant="l" component="p" i18nParams={{ projectName }}>
      i18n.WelcomePage.welcomeMessage.descriptionParagraph1.noProjectCreator
    </Typography>
  );
};

const VerifyContributorsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  width: '100%',
  marginTop: theme.spacing(2),
}));

const VerifyContributors: FC<{
  contributors?: { name: string; email: string }[];
  onResetContributors?: () => unknown;
}> = ({ contributors, onResetContributors }) => {
  if (!contributors?.length) {
    return null;
  }

  return (
    <VerifyContributorsContainer data-testid="verify-contributors">
      <Typography variant="l" component="p">
        i18n.WelcomePage.welcomeMessage.verifyContributors.title
      </Typography>

      {contributors.map((contributor) => (
        <UserProfile
          key={`${contributor.name}-${contributor.email}`}
          user={{ name: contributor.name, email: contributor.email }}
        />
      ))}

      {onResetContributors && (
        <Button onClick={onResetContributors} variant={'tertiary'} fullWidth data-testid="reset-contributors-button">
          i18n.WelcomePage.welcomeMessage.verifyContributors.button
        </Button>
      )}
    </VerifyContributorsContainer>
  );
};

export const WelcomeMessage: FC<{
  contributors?: { name: string; email: string }[];
  onContinue: () => unknown;
  onResetContributors?: () => unknown;
  projectCreator?: { firstname: string; lastname: string };
  projectName: string;
}> = ({ contributors, onContinue, onResetContributors, projectCreator, projectName }) => {
  return (
    <SingleColumnLayout>
      <Title projectCreator={projectCreator} />
      <DescriptionFirstLine projectName={projectName} projectCreator={projectCreator} />
      {projectCreator ? (
        <Typography
          variant="l"
          component="p"
          i18nParams={{ firstname: projectCreator.firstname, lastname: projectCreator.lastname }}
        >
          i18n.WelcomePage.welcomeMessage.descriptionParagraph2
        </Typography>
      ) : (
        <Typography variant="l" component="p">
          i18n.WelcomePage.welcomeMessage.descriptionParagraph2.noProjectCreator
        </Typography>
      )}
      <VerifyContributors contributors={contributors} onResetContributors={onResetContributors} />
      <BottomFixedContainer>
        <Button onClick={() => onContinue()} fullWidth data-testid="welcome-button">
          i18n.WelcomePage.button.continue
        </Button>
      </BottomFixedContainer>
      <ScrollToError />
    </SingleColumnLayout>
  );
};

const ScrollToError: FC = () => {
  const formik = useFormikContext();
  const submitting = formik?.isSubmitting;

  useEffect(() => {
    if (submitting) return;
    document.querySelector('.Mui-error, [data-error]')?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }, [submitting]);

  return null;
};

export const WelcomeMessageIdentificationRequired: FC<{
  onContinue: (values: { contributors: { name: string; email: string }[] }) => unknown;
  projectCreator?: { firstname: string; lastname: string };
  projectId: string;
  projectName: string;
}> = ({ onContinue, projectCreator, projectId, projectName }) => {
  const { getByProject, setByProject } = useContributors();
  const contributors = getByProject(projectId);
  const initialValues = contributors.length ? { contributors } : undefined;
  return (
    <ContributorsForm
      initialValues={initialValues}
      onSubmit={({ contributors }) => {
        onContinue({ contributors });
        setByProject(projectId, contributors);
      }}
    >
      <SingleColumnLayout data-testid="welcome-message-identification-required">
        <Title projectCreator={projectCreator} />
        <DescriptionFirstLine projectName={projectName} projectCreator={projectCreator} />
        {projectCreator ? (
          <Typography
            variant="l"
            component="p"
            i18nParams={{ firstname: projectCreator.firstname, lastname: projectCreator.lastname }}
          >
            i18n.WelcomePage.welcomeMessage.identificationRequired
          </Typography>
        ) : (
          <Typography variant="l" component="p">
            i18n.WelcomePage.welcomeMessage.identificationRequired.noProjectCreator
          </Typography>
        )}
        <ContributorsDetails />
        <BottomFixedContainer>
          <Button type="submit" fullWidth data-testid="welcome-button">
            i18n.WelcomePage.button.continue
          </Button>
        </BottomFixedContainer>
      </SingleColumnLayout>
      <ScrollToError />
    </ContributorsForm>
  );
};

const LinkText = styled('a')(({ theme }) => ({
  color: theme.palette.brand.blue,
  textDecoration: 'underline',
  '&:focus-visible': theme.mixins.focusRing,

  '&:hover': {
    color: theme.palette.brand.blueDark,
  },
}));

export const WelcomeMessageConsentRequired: FC<{
  accountName: string;
  documentUrl: string;
  onSignAgreement: (values: { contributors: { name: string; email: string }[] }) => unknown;
  projectCreator?: { firstname: string; lastname: string };
  projectId: string;
  projectName: string;
}> = ({ accountName, documentUrl, onSignAgreement, projectCreator, projectId, projectName }) => {
  const { getByProject, setByProject } = useContributors();
  const contributors = getByProject(projectId);
  const initialValues = contributors.length ? { contributors } : undefined;
  const [error, setError] = useState(false);
  const [signing, setSigning] = useState(false);

  const onSubmit = async ({ contributors }: { contributors: { name: string; email: string }[] }) => {
    try {
      setSigning(true);
      await onSignAgreement({ contributors });
      setByProject(
        projectId,
        contributors.map((contributor) => ({ ...contributor, consent: true }))
      );
    } catch {
      setError(true);
    } finally {
      setSigning(false);
    }
  };

  if (error) {
    return (
      <SingleColumnLayout>
        <Typography variant="h2" component="h1">
          i18n.WelcomePage.welcomeMessage.consentRequired.error.title
        </Typography>
        <Typography variant="l" component="p">
          i18n.WelcomePage.welcomeMessage.consentRequired.error.description
        </Typography>
        <Footer>
          <Button href="mailto:success@cofenster.com">i18n.global.errorPage.contactLink</Button>
        </Footer>
      </SingleColumnLayout>
    );
  }

  return (
    <ContributorsForm initialValues={initialValues} onSubmit={onSubmit}>
      <SingleColumnLayout data-testid="welcome-message-consent-required">
        <Title projectCreator={projectCreator} />
        <DescriptionFirstLine projectName={projectName} projectCreator={projectCreator} />
        <Typography
          variant="l"
          component="p"
          i18nParams={{
            accountName,
            link: (chunks) => (
              <LinkText href={documentUrl} download target="_blank">
                {chunks}
              </LinkText>
            ),
          }}
        >
          i18n.WelcomePage.welcomeMessage.consentRequired.descriptionParagraph2
        </Typography>
        <ContributorsDetails />
        <Footer>
          <Typography color="grey800" variant="m" component="p" i18nParams={{ accountName }}>
            i18n.WelcomePage.welcomeMessage.consentRequired.agreement
          </Typography>
        </Footer>
        <BottomFixedContainer>
          <Button type="submit" fullWidth data-testid="welcome-button" loading={signing} disabled={signing}>
            i18n.WelcomePage.button.signAgreement
          </Button>
        </BottomFixedContainer>
      </SingleColumnLayout>
      <ScrollToError />
    </ContributorsForm>
  );
};
