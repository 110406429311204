import type { BackgroundEffect, useTracking } from '@cofenster/web-components';
import { useCallback } from 'react';

export const useSetBackgroundEffectWithTracking = (
  tracking: ReturnType<typeof useTracking>,
  setBackgroundEffect: (effect: BackgroundEffect) => void
) => {
  return useCallback(
    (effect: BackgroundEffect) => {
      tracking.trackEvent({
        event: 'recordSettingUpdated',
        details: {
          settingName: effect,
          settingType: 'effect',
        },
      });
      setBackgroundEffect(effect);
    },
    [setBackgroundEffect, tracking]
  );
};
