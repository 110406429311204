import type { FC } from 'react';

import { Icon, Text, styled } from '@cofenster/web-components';

const Container = styled('div')(({ theme }) => ({
  position: 'absolute',
  inset: 0,
  paddingTop: theme.spacing(2),
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
}));

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
  background: theme.palette.brand.grey100,
  borderRadius: theme.spacing(1),
  padding: theme.spacing(1, 1.5),
}));

export const CameraHint: FC = () => (
  <Container data-testid="camera-hint">
    <Wrapper>
      <Text>i18n.global.camera.smileHint</Text>
      <Icon type="SmileyIcon" color="inherit" size="s" />
    </Wrapper>
  </Container>
);
