import { type CSSProperties, type FC, useState } from 'react';

import { VIDEO_FORMATS, type VideoFormat } from '@cofenster/constants';
import {
  Button,
  IconButton,
  LoadingSpinner,
  NativeVideoPlayer,
  SpinningIcon,
  Typography,
  preventForwardProps,
  styled,
  useFormatTimeDistance,
} from '@cofenster/web-components';

import { useDeleteAssetFromContributionRequestByActorPredefined } from '../../api/hooks/contributionRequest/useDeleteAssetFromContributionRequestByActor';
import type { ContributionRequestListByActor } from '../../api/hooks/contributionRequestList/useContributionRequestListByActor';
import { useDialogs } from '../../context/dialogs/useDialogs';
import { useCurrentUserTaskContributionIds } from '../../context/tasksProgress';
import { useTracking } from '../../context/tracking';
import { useVideoFormat } from '../../hooks/useVideoFormat';

type ContributionRequest = ContributionRequestListByActor['contributionRequestList']['requests'][number];
type VideoAsset = ContributionRequest['videoAssets'][number];
type ImageAsset = ContributionRequest['imageAssets'][number];

const ContributionsListContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const ContributionsListGrid = styled(
  'ul',
  preventForwardProps(['videoFormat'])
)<{ videoFormat: VideoFormat }>(({ theme, videoFormat }) => ({
  display: 'grid',
  margin: 0,
  paddingLeft: 0,
  listStyle: 'none',

  gridTemplateColumns: videoFormat === 'Vertical' ? 'repeat(2, 1fr)' : 'repeat(1, 1fr)',
  [theme.breakpoints.up('sm')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  gap: theme.spacing(2),
}));

const ContributionsListGridItem = styled('li')({
  position: 'relative',
});

const Video = styled(NativeVideoPlayer)(({ theme }) => ({
  aspectRatio: 'var(--aspect-ratio)',
  backgroundColor: theme.palette.brand.linen50,
  height: 'auto',
}));

const Image = styled('img')(({ theme }) => ({
  width: '100%',
  height: 'auto',
  borderRadius: theme.shape.borderRadius,
  aspectRatio: 'var(--aspect-ratio)',
  objectFit: 'contain',
  backgroundColor: '#000',
}));

const VideoContribution: FC<{ asset: VideoAsset }> = ({ asset }) => {
  const format = VIDEO_FORMATS[useVideoFormat()];

  return (
    <Video
      style={{ '--aspect-ratio': format.width / format.height } as CSSProperties}
      src={asset.videoUrl ?? undefined}
      poster={asset.thumbnailUrl ?? undefined}
      objectFit="contain"
      actions={['FULLSCREEN']}
    />
  );
};

const ImageContribution: FC<{ asset: ImageAsset }> = ({ asset }) => {
  const { aspectRatio } = VIDEO_FORMATS[useVideoFormat()];

  return (
    <Image
      style={{ '--aspect-ratio': String(aspectRatio) } as CSSProperties}
      src={asset.imageUrl ?? undefined}
      alt=""
    />
  );
};

const LoadingAssetContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  inset: 0,
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  backgroundColor: theme.palette.brand.linen50,
  borderRadius: theme.shape['borderRadius-l'],
}));

const DeleteButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
  opacity: 0,
  transition: 'opacity 200ms',
  '*:hover > &, *:focus > &, &:focus': {
    opacity: 1,
  },
}));

const DeleteContributionIfPossibleButton: FC<{
  contributionRequestId: string;
  assetId: string;
  selectedAssetId: string | undefined;
  setSelectedAssetId: (assetId: string | undefined) => void;
}> = ({ contributionRequestId, assetId, setSelectedAssetId, selectedAssetId }) => {
  const { deleteAssetFromContributionRequestByActor, loading } = useDeleteAssetFromContributionRequestByActorPredefined(
    contributionRequestId,
    assetId
  );
  const tracking = useTracking();
  const [currentUserContributionIds, _, deleteContributionId] =
    useCurrentUserTaskContributionIds(contributionRequestId);
  const { openDialog } = useDialogs();

  if (!currentUserContributionIds.includes(assetId)) return null;

  const onDelete = () => {
    openDialog('DeleteContributionDialog', {
      onDelete: async () => {
        await deleteAssetFromContributionRequestByActor();
        deleteContributionId(assetId);
        if (assetId === selectedAssetId) {
          setSelectedAssetId(undefined);
        }
        tracking?.trackEvent({
          event: 'requestAssetDeleted',
        });
      },
    });
  };

  return (
    <DeleteButton
      backgroundColor="blurred"
      loading={loading}
      icon="TrashIcon"
      iconColor="white"
      label="i18n.global.delete"
      onClick={onDelete}
      data-testid="remove-asset"
    />
  );
};

const ContributionLabel: FC<{ asset: VideoAsset | ImageAsset }> = ({ asset }) => {
  const formatTimeDistance = useFormatTimeDistance();

  return (
    <Typography variant="m" component="p">
      {formatTimeDistance(new Date(asset.createdAt))}
    </Typography>
  );
};

const AssetRelativeContainer = styled('div')(() => ({
  position: 'relative',
}));

const isVideoAsset = (asset: VideoAsset | ImageAsset): asset is VideoAsset => {
  return asset.__typename === 'VideoAsset';
};

type ContributionListProps = {
  videoFormat: VideoFormat;
  contributionRequestId: string;
  list?: (VideoAsset | ImageAsset)[];
  selectedAssetId: string | undefined;
  setSelectedAssetId: (assetId: string | undefined) => void;
};

export const ContributionsList: FC<ContributionListProps> = ({
  videoFormat,
  contributionRequestId,
  list,
  selectedAssetId,
  setSelectedAssetId,
}) => {
  const [showItems, setShowItems] = useState(3);

  if (!list) return <LoadingSpinner />;
  if (list.length === 0) return null;

  return (
    <ContributionsListContainer>
      <Typography variant="h3" i18nParams={{ count: list.length }} component="h2">
        i18n.ContributionRequestPage.contributions
      </Typography>
      <ContributionsListGrid id="contributions-gallery" videoFormat={videoFormat}>
        {list.slice(0, showItems).map((asset) => {
          const hasAsset = isVideoAsset(asset) ? !!asset.videoUrl : !!asset.imageUrl;
          return (
            <ContributionsListGridItem key={asset.id}>
              <AssetRelativeContainer>
                {isVideoAsset(asset) ? <VideoContribution asset={asset} /> : <ImageContribution asset={asset} />}
                {!hasAsset && (
                  <LoadingAssetContainer>
                    <SpinningIcon type="GearIcon" color="carbon" />
                  </LoadingAssetContainer>
                )}
                <DeleteContributionIfPossibleButton
                  contributionRequestId={contributionRequestId}
                  assetId={asset.id}
                  selectedAssetId={selectedAssetId}
                  setSelectedAssetId={setSelectedAssetId}
                />
              </AssetRelativeContainer>
              <ContributionLabel asset={asset} />
            </ContributionsListGridItem>
          );
        })}
      </ContributionsListGrid>
      {showItems < list.length && (
        <Button
          variant="secondary"
          onClick={() => setShowItems((value) => value + 3)}
          style={{ alignSelf: 'center' }}
          type="button"
          aria-controls="contributions-gallery"
        >
          i18n.ContributionRequestPage.showMore
        </Button>
      )}
    </ContributionsListContainer>
  );
};
