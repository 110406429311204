import type { FC } from 'react';

import {
  BackgroundEffectButton,
  RecordButton,
  RecordingLayout,
  SelectCameraButton,
  type SelectCameraProps,
} from '../components';

import type { BackgroundEffect } from '../../../../hooks/media/backgroundEffect';

export type ImageCaptureBarProps = SelectCameraProps & {
  onCapture?: VoidFunction;
  isCompact?: boolean;
  backgroundEffect?: BackgroundEffect;
  onBackgroundEffectSelect?: (value: BackgroundEffect) => unknown;
  isBackgroundEffectInitializing?: boolean;
};

export const ImageCaptureBar: FC<ImageCaptureBarProps> = ({
  devices,
  selectedDevice,
  onSelectDevice,
  backgroundEffect,
  onBackgroundEffectSelect,
  isBackgroundEffectInitializing,
  disabled,
  onCapture,
  isCompact,
}) => {
  return (
    <RecordingLayout
      isCompact={isCompact}
      mainButton={<RecordButton onRecord={onCapture} isDisabled={!onCapture} />}
      leftButtons={
        <SelectCameraButton
          devices={devices}
          selectedDevice={selectedDevice}
          onSelectDevice={onSelectDevice}
          disabled={disabled}
        />
      }
      rightButtons={
        onBackgroundEffectSelect && (
          <BackgroundEffectButton
            selectedEffect={backgroundEffect}
            onEffectSelect={onBackgroundEffectSelect}
            isInitializing={isBackgroundEffectInitializing}
          />
        )
      }
    />
  );
};
