import { useEffect } from 'react';

import { useImageAssetLazyQuery } from '../../../api/generated';

export const usePollingImageAsset = (requestedImageAssetId: string | null | undefined) => {
  const [getImageAsset, { data, stopPolling }] = useImageAssetLazyQuery({
    // We are waiting for the image to be ready, that's why we need to hit the server
    fetchPolicy: 'no-cache',
    pollInterval: 5000,
  });

  useEffect(() => {
    return () => stopPolling();
  }, [stopPolling]);

  useEffect(() => {
    if (requestedImageAssetId) getImageAsset({ variables: { id: requestedImageAssetId } });
  }, [requestedImageAssetId, getImageAsset]);

  useEffect(() => {
    if (data?.imageAsset?.status === 'Ready') stopPolling();
  }, [data?.imageAsset?.status, stopPolling]);

  return requestedImageAssetId ? data?.imageAsset : null;
};
