import { type FC, memo } from 'react';

import { useNativeCaptureSupported } from '@cofenster/web-components';

import type { CaptureComponentProps } from '../../types';
import { BrowserCaptureByMobile } from '../BrowserCaptureByMobile';
import { BrowserCaptureByWebcam } from '../BrowserCaptureByWebcam';

export const ImageCapture: FC<CaptureComponentProps> = memo(({ showRecordViaApp }) => {
  const nativeCaptureSupported = useNativeCaptureSupported();

  if (nativeCaptureSupported) return <BrowserCaptureByMobile />;
  return <BrowserCaptureByWebcam showRecordViaApp={showRecordViaApp} />;
});
ImageCapture.displayName = 'ImageCapture';
