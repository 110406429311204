import type { FC } from 'react';
import { Navigate, type NavigateProps } from 'react-router-dom';

import { generatePath } from '@cofenster/web-components';

import { type RouteName, type RouteParams, routes } from './routes';

export type RedirectProps = Omit<NavigateProps, 'to'> & {
  to: RouteName;
  params?: RouteParams;
};

export const Redirect: FC<RedirectProps> = ({ to, params, state }) => (
  <Navigate to={generatePath(routes[to], params)} replace state={state} />
);
