import { useContext } from 'react';

import { ClientContext } from './ClientContext';

export const useClient = () => {
  const context = useContext(ClientContext);

  if (!context) {
    throw new Error('Missing ClientProvider in tree');
  }

  return context;
};
