import { type FC, type MouseEventHandler, useCallback } from 'react';

import { Box, IconButton, styled } from '@cofenster/web-components';

import { FormatAwareContentArea } from '../../../layout';
import { useCaptureAssetLifecycleFlow } from '../../CaptureAssetLifecycleFlow';
import { WithGapContainer } from '../../Container';
import { RetakeActions } from '../../RetakeActions';
import type { AssetReadyComponentProps } from '../../types';

const IconsWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: theme.spacing(1),
}));

export const CaptureImageAsset: FC<AssetReadyComponentProps> = ({ url }) => {
  const { onDelete } = useCaptureAssetLifecycleFlow();

  const onDeleteClick: MouseEventHandler = useCallback(
    (event) => {
      event.stopPropagation();
      onDelete();
    },
    [onDelete]
  );

  if (!url) {
    return null;
  }

  return (
    <WithGapContainer>
      <FormatAwareContentArea data-testid="image-asset-panel">
        <Box fullHeight>
          <IconsWrapper>
            <IconButton
              icon="DownloadIcon"
              target="_blank"
              href={url}
              download
              label="i18n.global.download"
              iconSize="m"
              iconColor="white"
              hoverColor="white"
              backgroundColor="carbon_alpha30"
              hoverBackgroundColor="carbon_alpha50"
            />

            <IconButton
              icon="TrashIcon"
              iconSize="m"
              iconColor="white"
              hoverColor="white"
              backgroundColor="carbon_alpha30"
              hoverBackgroundColor="carbon_alpha50"
              onClick={onDeleteClick}
              label="i18n.global.delete"
              data-testid="remove-asset"
            />
          </IconsWrapper>

          <img src={url} width="auto" height="100%" alt="" />
        </Box>
      </FormatAwareContentArea>
      <RetakeActions />
    </WithGapContainer>
  );
};
