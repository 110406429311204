import { useEffect, useState } from 'react';

export const useDocumentVisible = (document = window.document) => {
  const [isVisible, setIsVisible] = useState(document.visibilityState === 'visible');
  useEffect(() => {
    const onVisibilityChange = () => setIsVisible(document.visibilityState === 'visible');
    document.addEventListener('visibilitychange', onVisibilityChange);
    return () => document.removeEventListener('visibilitychange', onVisibilityChange);
  }, [document]);

  return isVisible;
};
