import { type FC, useEffect } from 'react';

import { NativeVideoPlayer, useI18n } from '@cofenster/web-components';

import { useDialogs } from '../../context/dialogs/useDialogs';

import { useCaptureAssetLifecycleFlow } from './CaptureAssetLifecycleFlow';

type RetrieveFileCacheProps = {
  onAction: VoidFunction;
  uploadId: string;
  cachedFile: File;
  assetType: 'video' | 'image';
};

const CachePreview: FC<{
  assetType: RetrieveFileCacheProps['assetType'];
  cachedFile: RetrieveFileCacheProps['cachedFile'];
}> = ({ assetType, cachedFile }) => {
  const { translate } = useI18n();

  return assetType === 'image' ? (
    <img
      src={URL.createObjectURL(cachedFile)}
      width="100%"
      height="100%"
      alt={translate('global.dialog.UploadFailureDialog.previewImageAlt')}
      data-testid="image-preview"
    />
  ) : (
    <NativeVideoPlayer
      src={URL.createObjectURL(cachedFile)}
      data-testid="video-preview"
      objectFit="contain"
      actions={['FULLSCREEN']}
    />
  );
};

export const RetrieveFileCache: FC<RetrieveFileCacheProps> = ({ onAction, uploadId, cachedFile, assetType }) => {
  const { openDialog, closeDialog } = useDialogs();
  const { format } = useCaptureAssetLifecycleFlow();

  useEffect(() => {
    openDialog('UploadFailureDialog', {
      format,
      onAction,
      uploadId,
      uploadType: assetType,
      content: <CachePreview assetType={assetType} cachedFile={cachedFile} />,
    });

    return () => {
      closeDialog('UploadFailureDialog');
    };
  }, [onAction, uploadId, cachedFile, format, openDialog, closeDialog, assetType]);

  return null;
};
