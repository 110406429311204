import { useI18n } from '@cofenster/web-components';
import type { FC, PropsWithChildren } from 'react';
import { ErrorPage } from '../pages/ErrorPage';

export const EnsureAcceptResponses: FC<PropsWithChildren<{ acceptResponses?: boolean; projectName: string }>> = ({
  acceptResponses = true,
  projectName,
  children,
}) => {
  const { translate } = useI18n();
  if (acceptResponses === false) {
    return (
      <ErrorPage
        messages={{
          headline: 'i18n.ErrorPage.notAcceptingResponses.headline',
          subHeadline: translate('ErrorPage.notAcceptingResponses.subHeadline', { projectName: projectName }),
        }}
        data-testid="not-accepting-responses-error-page"
      />
    );
  }
  return children;
};
