import { useCallback, useMemo, useState } from 'react';

import { useInterval } from './useInterval';

export const useTimer = () => {
  const [time, setTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);

  const start = useCallback(() => {
    setIsRunning(true);
  }, []);

  const stop = useCallback(() => {
    setIsRunning(false);
  }, []);

  const set = useCallback((time: number) => setTime(time), []);

  const reset = useCallback(() => setTime(0), []);

  const update = useMemo(() => {
    if (!isRunning) return null;
    let prev = Date.now();
    return () => {
      const now = Date.now();
      const diff = now - prev;
      prev = now;
      setTime((t) => t + diff);
    };
  }, [isRunning]);

  useInterval(update, 100);

  return useMemo(
    () => ({
      time,
      isRunning,
      start,
      stop,
      set,
      reset,
    }),
    [time, isRunning, start, stop, set, reset]
  );
};
