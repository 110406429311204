import type { FC, MouseEventHandler } from 'react';

import { IconButton } from '../../../../controls/Button/IconButton';

export const RetakeButton: FC<{ onRetake?: MouseEventHandler }> = ({ onRetake }) => (
  <IconButton
    onClick={onRetake}
    data-testid="retake-button"
    icon="ArrowClockwiseIcon"
    iconColor="white"
    backgroundColor="blurred"
    label="i18n.global.retake"
  />
);
