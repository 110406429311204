import { styled } from '@mui/material';
import { type FC, type ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { useAudioAnalysisStream } from '../../../../../contexts/audioAnalysisStream/useAudioAnalysisStream';
import { useIsSystemMuted } from '../../../../../hooks/media/useIsSystemMuted';
import { type OS, detectOS } from '../../../../../utilities/detectOS';
import { ICON_SIZES, Icon } from '../../../../assets/icons/Icon';
import { IconButton } from '../../../../controls/Button/IconButton';
import { TextLink } from '../../../../controls/TextLink';
import { Tooltip } from '../../../../feedback/Tooltip';
import { Typography } from '../../../../typography/Typography';

const AbsoluteIconContainer = styled('div')(() => ({
  position: 'absolute',
  top: -ICON_SIZES.m / 2,
  right: -ICON_SIZES.m / 2,
  zIndex: 1,
}));

const links: Record<OS, string> = {
  Windows: 'ms-settings:privacy-microphone',
  MacOS: 'x-apple.systempreferences:com.apple.Sound-Settings.extension',
  Linux: '',
  Unknown: '',
};

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(1),
}));

const RelativeContainer = styled('div')(() => ({
  position: 'relative',
}));

type ChildrenRenderArgs = {
  isSystemMuted: boolean;
  isOpen: boolean;
  open: VoidFunction;
  close: VoidFunction;
};

export const SystemAudioMutedTooltip: FC<{
  openOnHover?: boolean;
  children: (args: ChildrenRenderArgs) => ReactNode;
}> = ({ openOnHover, children }) => {
  const { mediaStream } = useAudioAnalysisStream();
  const { isSystemMuted } = useIsSystemMuted(mediaStream?.getAudioTracks()?.[0]);

  const [isOpen, setIsOpen] = useState(false);
  const close = useCallback(() => setIsOpen(false), []);
  const open = useCallback(() => setIsOpen(true), []);

  const systemSettingsHref = useMemo(() => links[detectOS()], []);

  useEffect(() => {
    setIsOpen(isSystemMuted);
  }, [isSystemMuted]);

  // Memoize the title component prevents jumping tooltip from IconButton
  const titleContent = useMemo(
    () => (
      <Container>
        <Typography
          i18nParams={{
            link: (chunks) => {
              if (systemSettingsHref) {
                return (
                  <TextLink underline href={systemSettingsHref} target="_blank">
                    {chunks}
                  </TextLink>
                );
              }
              return chunks;
            },
          }}
        >
          i18n.global.systemAudioMutedTooltipText
        </Typography>
        <IconButton
          onClick={close}
          icon="CloseIcon"
          label="i18n.global.close"
          iconColor="white"
          hoverBackgroundColor="carbon_alpha50"
        />
      </Container>
    ),
    [systemSettingsHref, close]
  );

  return (
    <Tooltip open={isOpen} title={titleContent} maxWidth={400}>
      <RelativeContainer>
        {isSystemMuted && (
          <AbsoluteIconContainer>
            <Icon
              type="WarningCircleIcon"
              color="carbon"
              size="s"
              background={{ size: 'm', color: 'warningDark' }}
              onMouseEnter={openOnHover ? open : undefined}
            />
          </AbsoluteIconContainer>
        )}
        {children({ isSystemMuted, isOpen, close, open })}
      </RelativeContainer>
    </Tooltip>
  );
};
