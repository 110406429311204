import type { Params } from 'react-router-dom';

export type TokenRouteParams = { token: string };

export const routes = {
  home: '/',

  signinByCode: '/signin',
  welcome: '/access/:token',

  contributionRequestList: '/tasks/:token',
  contributionRequest: '/task/:requestId',
} as const;

export type Routes = typeof routes;
export type RouteName = keyof Routes;
export type RouteParams = Params;
