import { type ComponentProps, type FC, type ReactNode, useCallback, useRef } from 'react';

import { AcceptTypes } from '@cofenster/constants';
import { styled } from '@cofenster/web-components';

const Input = styled('input')(() => ({
  display: 'none',
}));

export type NativeCaptureInputProps = {
  children: (startCapture: () => unknown) => ReactNode;
  onFile: (file: File) => unknown;
  type: 'video' | 'image';
  capture?: ComponentProps<'input'>['capture'];
};

export const NativeCaptureInput: FC<NativeCaptureInputProps> = ({
  onFile,
  type,
  capture = true,
  children,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const startCapture = useCallback(() => {
    inputRef.current?.click();
  }, []);
  return (
    <>
      {children(startCapture)}
      <Input
        multiple={false}
        type="file"
        accept={AcceptTypes[type]}
        capture={capture}
        ref={inputRef}
        onChange={(e) => {
          const file = e.target.files?.item(0);
          file && onFile(file);
        }}
        {...rest}
      />
    </>
  );
};
