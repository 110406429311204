import type { VideoFormat } from '@cofenster/constants';

export const BACKGROUND_REPLACE_IMAGES_OFFICE = Object.seal([
  'office.calm-flex-work-area',
  'office.cozy-new-work-office',
  'office.elegant-conference-room',
  'office.loft-office',
  'office.modern-office',
  'office.multidesk-office',
  'office.office-cafeteria',
  'office.office-hallway',
] as const);

export const BACKGROUND_REPLACE_IMAGES_HOMEOFFICE = Object.seal([
  'homeoffice.bright-white-living-room',
  'homeoffice.cozy-houseboat',
  'homeoffice.elegant-a-frame',
  'homeoffice.living-room-wooden',
  'homeoffice.luxurious-houseboat',
  'homeoffice.minimal-desk-with-clock',
  'homeoffice.open-kitchen-and-living-space',
  'homeoffice.open-living-room-with-couch',
] as const);

export type BackgroundReplaceImage = (
  | typeof BACKGROUND_REPLACE_IMAGES_OFFICE
  | typeof BACKGROUND_REPLACE_IMAGES_HOMEOFFICE
)[number];

export type BackgroundEffect = 'NONE' | 'BLUR' | 'PRIMARY' | 'SECONDARY' | BackgroundReplaceImage;

const parseColor = (maybeColor: string) =>
  /#([A-F0-9]{2})([A-F0-9]{2})([A-F0-9]{2})/
    .exec(maybeColor)
    ?.slice(1)
    .map((component) => Number.parseInt(component, 16) ?? 0)
    .concat(255) ?? [255, 0, 0, 255];

export const createColor = (hopefullyColor = '#FF0000'): TexImageSource =>
  new ImageData(new Uint8ClampedArray(parseColor(hopefullyColor)), 1, 1);

export const createImage = (name: BackgroundReplaceImage, format: VideoFormat): Promise<TexImageSource> => {
  const image = new Image();
  image.src = `/assets/images/background-effects/${name}.${format.toLowerCase()}.avif`;
  return new Promise((resolve, reject) => {
    image.onload = () => resolve(image);
    image.onerror = (error) => reject(error);
  });
};

export const getBackgroundReplaceImageThumbnailUrl = (name: BackgroundReplaceImage) =>
  `/assets/images/background-effects/${name}.thumb.avif`;
