import type { FC, PropsWithChildren, ReactNode } from 'react';

import {
  GridContainer,
  GridItem,
  Headline,
  type HeadlineProps,
  Text,
  responsiveStyle,
  styled,
} from '@cofenster/web-components';

const Container = styled('div')(({ theme }) => ({
  margin: theme.spacing(0.5, 0, 3, 0),
  [theme.breakpoints.up('sm')]: {
    margin: theme.spacing(1, 0, 5, 0),
  },
  [theme.breakpoints.up('md')]: {
    margin: theme.spacing(3, 0, 4, 0),
  },
}));

const StyledHeadline = styled(Headline)(({ theme }) => ({
  padding: 0,
  whiteSpace: 'pre-line',
  ...responsiveStyle(
    theme,
    { xs: 'h4', sm: 'h3', md: 'h2' },
    (value: HeadlineProps['variant']) => theme.typography[value]
  ),
}));

type PageHeadlineProps = {
  variant?: HeadlineProps['variant'];
  subline?: ReactNode;
  i18nParams?: HeadlineProps['i18nParams'];
};

export const PageHeadline: FC<PropsWithChildren<PageHeadlineProps>> = ({
  children,
  subline,
  variant = 'h1',
  i18nParams,
}) => {
  return (
    <Container>
      <GridContainer justifyContent="center">
        <GridItem xs={12} textAlign="center">
          <StyledHeadline variant={variant} i18nParams={i18nParams}>
            {children}
          </StyledHeadline>
        </GridItem>
      </GridContainer>

      {!!subline && (
        <GridContainer justifyContent="center" marginTop={0}>
          {typeof subline === 'string' ? (
            <GridItem xs={12} md={7} textAlign="center" pt={1}>
              <Text variant="xl" component="p">
                {subline}
              </Text>
            </GridItem>
          ) : (
            subline
          )}
        </GridContainer>
      )}
    </Container>
  );
};
