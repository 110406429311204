import { type ComponentProps, type FC, type ReactNode, useCallback, useRef } from 'react';

export type NativeCaptureInputProps = {
  children: (startCapture: () => unknown) => ReactNode;
  onFile: (file: File) => unknown;
  accept?: 'video/*' | 'image/*';
  capture?: ComponentProps<'input'>['capture'];
};

export const NativeCaptureInput: FC<NativeCaptureInputProps> = ({
  onFile,
  accept,
  capture = true,
  children,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const startCapture = useCallback(() => {
    inputRef.current?.click();
  }, []);
  return (
    <>
      {children(startCapture)}
      <input
        hidden
        multiple={false}
        type="file"
        accept={accept}
        capture={capture}
        ref={inputRef}
        onChange={(e) => {
          const file = e.target.files?.item(0);
          file && onFile(file);
        }}
        {...rest}
      />
    </>
  );
};
