import { type FC, type MouseEvent, type MouseEventHandler, useCallback, useRef } from 'react';

import { Box, IconButton, NativeVideoPlayer, styled, useSeekOnLoadedMetadata } from '@cofenster/web-components';

import { FormatAwareContentArea } from '../../../layout';
import { useCaptureAssetLifecycleFlow } from '../../CaptureAssetLifecycleFlow';
import { WithGapContainer } from '../../Container';
import { RetakeActions } from '../../RetakeActions';
import type { AssetReadyComponentProps } from '../../types';

const IconsWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: theme.spacing(1),
  zIndex: theme.zIndex.above,
}));

export const CaptureVideoAsset: FC<AssetReadyComponentProps> = ({ url, thumbnailUrl }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  useSeekOnLoadedMetadata(videoRef);

  const { onDelete, captureType } = useCaptureAssetLifecycleFlow();

  const openDeleteCaptureAssetDialog: MouseEventHandler = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      onDelete();
    },
    [onDelete]
  );

  if (!url) {
    return null;
  }

  return (
    <WithGapContainer>
      <FormatAwareContentArea>
        {(format) => {
          let objectFit: 'contain' | 'cover' = 'cover';
          if (format === 'Horizontal' || format === 'Vertical' || captureType === 'screenRecording') {
            objectFit = 'contain';
          }

          return (
            <Box fullHeight>
              <IconsWrapper>
                <IconButton
                  icon="DownloadIcon"
                  iconSize="m"
                  iconColor="white"
                  hoverColor="white"
                  backgroundColor="carbon_alpha30"
                  hoverBackgroundColor="carbon_alpha50"
                  target="_blank"
                  href={url}
                  download
                  label="i18n.global.download"
                />

                <IconButton
                  icon="TrashIcon"
                  iconSize="m"
                  iconColor="white"
                  hoverColor="white"
                  backgroundColor="carbon_alpha30"
                  hoverBackgroundColor="carbon_alpha50"
                  onClick={openDeleteCaptureAssetDialog}
                  label="i18n.global.delete"
                  data-testid="remove-asset"
                />
              </IconsWrapper>

              <NativeVideoPlayer
                src={url}
                poster={thumbnailUrl ?? undefined}
                playsInline
                objectFit={objectFit}
                data-testid="video-asset-preview"
                ref={videoRef}
                actions={['FULLSCREEN']}
              />
            </Box>
          );
        }}
      </FormatAwareContentArea>
      <RetakeActions />
    </WithGapContainer>
  );
};
