import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useTimer } from '../utils/useTimer';

export type MediaStreamRecorder = {
  start(): void;
  cancel(): void;
  stop(): void;
  pause(): void;
  resume(): void;
  readonly state: RecordingState;
  readonly timeRecorded: number;
  /**
   * bytes that are ready to be used
   * tip: read when recorder is stopped
   */
  readonly bytesAvailable: number;
  asBlob(): Blob;
  asFile(filename?: string): File;
  asURL(): string;
};

export const useMediaStreamRecorder = (
  stream: MediaStream | null,
  options?: Omit<MediaRecorderOptions, 'mimeType'>
): MediaStreamRecorder | null => {
  const mediaRecorder = useMemo(() => {
    if (stream) {
      return new MediaRecorder(stream, {
        mimeType: getMimeType(stream),
        ...options,
      });
    }
    return null;
  }, [stream, options]);

  const [state, setState] = useState<RecordingState>('inactive');
  const [data, setData] = useState<Blob[]>([]);
  const { time: timeRecorded, start: timerStart, stop: timerStop, reset: timerReset } = useTimer();
  const discardRef = useRef(false);

  const controls = useMemo(() => {
    if (!mediaRecorder)
      return {
        start: noop,
        cancel: noop,
        stop: noop,
        pause: noop,
        resume: noop,
      };

    const start = () => {
      mediaRecorder.state === 'inactive' && mediaRecorder.start();
      setState(mediaRecorder.state);
    };

    const stop = () => {
      mediaRecorder.state !== 'inactive' && mediaRecorder.stop();
      setState(mediaRecorder.state);
    };

    const cancel = () => {
      discard();
      discardRef.current = true;
      stop();
    };

    const pause = () => {
      mediaRecorder.state !== 'inactive' && mediaRecorder.pause();
      setState(mediaRecorder.state);
    };

    const resume = () => {
      mediaRecorder.state !== 'inactive' && mediaRecorder.resume();
      setState(mediaRecorder.state);
    };

    return { start, stop, pause, resume, cancel };
  }, [mediaRecorder]);

  const discard = useCallback(() => {
    setData([]);
    timerReset();
  }, [timerReset]);

  const bytesAvailable = useMemo(() => data.map((b) => b.size).reduce((a, b) => a + b, 0), [data]);

  const asBlob = useCallback(() => new Blob(data, { type: data[0]?.type }), [data]);

  const asFile = useCallback(
    (filename = 'video') => {
      const blob = asBlob();
      return new File([blob], filename, { type: blob.type });
    },
    [asBlob]
  );

  const asURL = useCallback(() => {
    return URL.createObjectURL(asBlob());
  }, [asBlob]);

  useEffect(() => {
    if (mediaRecorder) {
      const onStart = () => {
        discardRef.current = false;
        discard();
        timerStart();
      };
      const onStop = () => {
        timerStop();
        setState(mediaRecorder.state);
      };
      const onPause = timerStop;
      const onResume = timerStart;
      const onError = (e: Event) => {
        console.error(e);
        timerStop();
      };
      const onData = (e: BlobEvent) => {
        if (discardRef.current) return;
        setData((data) => [...data, e.data]);
      };
      setState(mediaRecorder.state);
      setData([]);

      mediaRecorder.addEventListener('start', onStart);
      mediaRecorder.addEventListener('stop', onStop);
      mediaRecorder.addEventListener('pause', onPause);
      mediaRecorder.addEventListener('resume', onResume);
      mediaRecorder.addEventListener('error', onError);
      mediaRecorder.addEventListener('dataavailable', onData);
      return () => {
        mediaRecorder.removeEventListener('start', onStart);
        mediaRecorder.removeEventListener('stop', onStop);
        mediaRecorder.removeEventListener('pause', onPause);
        mediaRecorder.removeEventListener('resume', onResume);
        mediaRecorder.removeEventListener('error', onError);
        mediaRecorder.removeEventListener('dataavailable', onData);
        mediaRecorder.state !== 'inactive' && mediaRecorder.stop();
      };
    }
    setState('inactive');
    timerStop();
  }, [mediaRecorder, timerStart, timerStop, discard]);

  return useMemo(
    () =>
      mediaRecorder
        ? {
            ...controls,
            state,
            bytesAvailable,
            timeRecorded,
            asBlob,
            asFile,
            asURL,
          }
        : null,
    [mediaRecorder, controls, state, timeRecorded, bytesAvailable, asBlob, asFile, asURL]
  );
};

const noop = () => undefined;

const getMimeType = (stream: MediaStream): MediaRecorderOptions['mimeType'] => {
  const hasVideo = stream.getVideoTracks().length > 0;
  const hasAudio = stream.getAudioTracks().length > 0;

  if (hasVideo && hasAudio) {
    return ['video/webm;codecs=vp8,opus', 'video/mp4'].find(MediaRecorder.isTypeSupported);
  }
  if (hasVideo) {
    return ['video/webm;codecs=vp8', 'video/mp4'].find(MediaRecorder.isTypeSupported);
  }
  if (hasAudio) {
    return ['audio/webm;codecs=opus', 'audio/mp3'].find(MediaRecorder.isTypeSupported);
  }
  return undefined;
};
