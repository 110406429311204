import { SIMPLE_VERTEX_SHADER, SimpleProgram, createFragmentShader, createTexture } from '.';

export const createConfidenceMaskPipeline = (
  gl: WebGL2RenderingContext,
  overlayTexture: WebGLTexture = createTexture(gl),
  invertMask = false
) => {
  const composeProgram = new SimpleProgram(gl, SIMPLE_VERTEX_SHADER, MASK_COMPOSE_FRAGMENT_SHADER, {
    defaultTexture: createTexture(gl),
    maskTexture: null,
    overlayTexture,
  });
  composeProgram.setFloats({ invertMask: invertMask ? 1 : 0 });

  const setOverlayTextureImage = (image: TexImageSource) => {
    composeProgram.setTextureImage('overlayTexture', image);
  };

  const paint = (maskTexture: WebGLTexture, mainImage: TexImageSource) => {
    composeProgram.setTexture('maskTexture', maskTexture);
    composeProgram.setTextureImage('defaultTexture', mainImage);
    composeProgram.paint();
  };

  const destroy = () => {
    composeProgram.destroy();
  };

  return {
    setOverlayTextureImage,
    paint,
    destroy,
  };
};

const MASK_COMPOSE_FRAGMENT_SHADER = createFragmentShader(
  `
precision mediump float;
uniform sampler2D defaultTexture;
uniform sampler2D overlayTexture;
uniform sampler2D maskTexture;
uniform float invertMask;
varying vec2 vTex;

void main() {
  float confidence = abs(texture2D(maskTexture, vTex).r - invertMask);
  vec4 defaultColor = texture2D(defaultTexture, vTex);
  vec4 overlayColor  = texture2D(overlayTexture, vTex);
  
  overlayColor = mix(defaultColor, overlayColor, overlayColor.a);
  gl_FragColor = mix(defaultColor, overlayColor, confidence);
}
`,
  {
    textures: ['defaultTexture', 'overlayTexture', 'maskTexture'],
    floats: ['invertMask'],
  }
);
