import type { FC } from 'react';

import { MicrophonePopOver, type MicrophonePopOverProps } from '../MicrophonePopOver';

export type SelectMicrophoneProps = Omit<MicrophonePopOverProps, 'anchorEl'>;

export const SelectMicrophone: FC<SelectMicrophoneProps> = ({
  devices,
  selectedDevice,
  onSelectDevice,
  disabled,
  disableHoverListener,
  onClick,
}) => {
  if (!devices || !onSelectDevice) return null;

  return (
    <MicrophonePopOver
      devices={devices}
      selectedDevice={selectedDevice}
      onSelectDevice={onSelectDevice}
      disabled={disabled}
      disableHoverListener={disableHoverListener}
      onClick={onClick}
    />
  );
};
