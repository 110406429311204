import { useEffect, useMemo } from 'react';

import { type PromiseState, usePromise } from '../utils/usePromise';

export const useUserMediaStream = (options: DisplayMediaStreamOptions | null): PromiseState<MediaStream, Error> => {
  const state = usePromise(useMemo(() => (options ? getUserMedia(options, 3) : null), [options])) as PromiseState<
    MediaStream,
    Error
  >;

  useEffect(() => {
    const stream = state.value;
    if (stream) {
      return () => stopStream(stream);
    }
  }, [state.value]);

  return state;
};

const getUserMedia = (options: DisplayMediaStreamOptions, maxTries: number): Promise<MediaStream> => {
  return navigator.mediaDevices.getUserMedia(options).catch((error) => {
    const wasManuallyCancelled = error.name === 'NotAllowedError';
    if (!wasManuallyCancelled && maxTries > 0) return getUserMedia(options, maxTries - 1);
    return Promise.reject(new Error('UserMediaUnavailable', { cause: error }));
  });
};

const stopStream = (stream: MediaStream) => stream.getTracks().forEach((track) => track.stop());
