import { styled } from '@mui/material';
import type { FC } from 'react';

import { Translatable } from '../../../../../../services/i18n';
import { Icon, type IconType } from '../../../../../assets/icons/Icon';
import { Typography } from '../../../../../typography/Typography';

const StyledOption = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

type CameraOptionProps = {
  iconType: IconType;
  title: string;
};

export const CameraOption: FC<CameraOptionProps> = ({ iconType, title }) => {
  return (
    <StyledOption variant="l">
      <Icon type={iconType} />
      <Translatable>{title}</Translatable>
    </StyledOption>
  );
};
