import type { FC } from 'react';

import { styled } from '@cofenster/web-components';

import type { ContributionRequestListByActor } from '../../../api/hooks/contributionRequestList/useContributionRequestListByActor';

import { Task } from './Task';

type ContributionRequest = ContributionRequestListByActor['contributionRequestList']['requests'][number];

const TaskListContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

export const TaskList: FC<{ requests: ContributionRequest[] }> = ({ requests }) => {
  return (
    <TaskListContainer>
      {requests.map((request, index) => (
        <Task key={request.id} request={request} index={index} />
      ))}
    </TaskListContainer>
  );
};
