export const createCanvasIdeallyOffscreen = (width = 640, height = 480) => {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  try {
    return canvas.transferControlToOffscreen();
  } catch {
    // Safari < 16.4 doesn't support OffscreenCanvas
    console.error('Failed to transfer control to offscreen');
    return canvas;
  }
};

const multipleOfTwo = (value: number) => Math.ceil(value / 2) * 2;

export const coverClipRect = (width: number, height: number, outputRatio: number) => {
  const inputRatio = width / height;
  if (inputRatio === outputRatio) return new DOMRectReadOnly(0, 0, width, height);
  const clipWidth = Math.min(width, multipleOfTwo(inputRatio < outputRatio ? width : height * outputRatio));
  const clipHeight = Math.min(height, multipleOfTwo(inputRatio < outputRatio ? width / outputRatio : height));
  const clipX = (width - clipWidth) / 2;
  const clipY = (height - clipHeight) / 2;
  return new DOMRectReadOnly(clipX, clipY, clipWidth, clipHeight);
};

let clippingViaBufferFailed = false;

export const safeClipVideoFrame = async (videoFrame: VideoFrame, clipRect: DOMRectReadOnly) => {
  if (clippingViaBufferFailed) return clipVideoFrameUsingCanvas(videoFrame, clipRect);

  try {
    return await clipVideoFrameUsingBuffer(videoFrame, clipRect);
  } catch (error) {
    console.error('Failed to clip using buffer', error);
    clippingViaBufferFailed = true;
    return clipVideoFrameUsingCanvas(videoFrame, clipRect);
  }
};

let canvasForClipping: OffscreenCanvas | HTMLCanvasElement;

export const clipVideoFrameUsingCanvas = async (videoFrame: VideoFrame, clipRect: DOMRectReadOnly) => {
  if (!canvasForClipping) canvasForClipping = createCanvasIdeallyOffscreen();
  canvasForClipping.width = clipRect.width;
  canvasForClipping.height = clipRect.height;

  const ctx = canvasForClipping.getContext('2d');
  if (!ctx) throw new Error('Failed to get 2d context');
  ctx.clearRect(0, 0, canvasForClipping.width, canvasForClipping.height);
  ctx.drawImage(
    videoFrame,
    clipRect.x,
    clipRect.y,
    clipRect.width,
    clipRect.height,
    0,
    0,
    clipRect.width,
    clipRect.height
  );
  videoFrame.close();

  return new VideoFrame(canvasForClipping, {
    timestamp: videoFrame.timestamp,
  });
};

const clipVideoFrameUsingBuffer = async (videoFrame: VideoFrame, clipRect: DOMRectReadOnly) => {
  // this check could be done earlier probably
  if (videoFrame.displayWidth === clipRect.width && videoFrame.displayHeight === clipRect.height) return videoFrame;
  const buffer = new Uint8Array(videoFrame.allocationSize({ rect: clipRect }));
  const layout = await videoFrame.copyTo(buffer, { rect: clipRect });
  const clipFrame = new VideoFrame(buffer, {
    timestamp: videoFrame.timestamp,
    displayWidth: clipRect.width,
    displayHeight: clipRect.height,
    codedWidth: clipRect.width,
    codedHeight: clipRect.height,
    format: videoFrame.format as VideoPixelFormat,
    colorSpace: videoFrame.colorSpace,
    layout,
  });
  videoFrame.close();
  return clipFrame;
};
