import type { FC } from 'react';

import { Markdown, Typography, styled } from '@cofenster/web-components';

// 1. Make sure that long words and URLs do not break the layout and cause
//    horizontal scrolling.
const StyledTypography = styled(Typography)(() => ({
  wordWrap: 'break-word', // 1
}));

export const ContributionRequestInstruction: FC<{ instruction?: string | null }> = ({ instruction }) => {
  return (
    <StyledTypography variant="l" component="div">
      {instruction ? (
        <Markdown>{instruction}</Markdown>
      ) : (
        <Typography variant="m" component="p">
          i18n.ContributionRequestPage.noInstructions
        </Typography>
      )}
    </StyledTypography>
  );
};
