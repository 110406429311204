import { useCallback } from 'react';

import { useContributionRequestListDetails } from '../../../../../context/contributionRequestListDetails';
import { useDialogs } from '../../../../../context/dialogs/useDialogs';
import { useTracking } from '../../../../../context/tracking';
import { InfoBanner } from '../../../InfoBanner';

export const RecordViaAppMessage = () => {
  const { openDialog } = useDialogs();
  const contributionRequestListDetails = useContributionRequestListDetails();
  const tracking = useTracking();

  const onClick = useCallback(() => {
    const qrcode = contributionRequestListDetails?.contributionRequestList?.qrCode;
    const code = contributionRequestListDetails?.contributionRequestList?.code;

    if (!qrcode || !code) return;

    openDialog('OpenInAppDialog', { code, qrcode });
    tracking?.trackEvent({ event: 'openInMobileOpened' });
  }, [openDialog, tracking, contributionRequestListDetails]);

  return (
    <InfoBanner
      title="i18n.Recording.VideoCapture.RecordViaMobile"
      isUpload={false}
      onClick={onClick}
      icon="MobileIcon"
    />
  );
};
