import { GridItem, styled } from '@cofenster/web-components';

const Divider = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.brand.grey50,
  height: 1,
  width: '100%',
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

export const InstructionsDivider = () => {
  return (
    <GridItem width="100%">
      <Divider />
    </GridItem>
  );
};
