import { useCallback, useMemo } from 'react';

import { useCaptureAssetCandidateFileContext } from '../../../../../context/captureAssetFile';
import { useDialogs } from '../../../../../context/dialogs/useDialogs';
import { useTrackRejectRecording } from '../../../../../hooks/useTrackRejectRecording';
import { useCaptureBackButtonBehavior } from '../../../BackButtonBehavior';
import { useCaptureAssetLifecycleFlow } from '../../../CaptureAssetLifecycleFlow';

export const useReviewRecording = (blob: Blob, clearRecording: () => unknown) => {
  const { metadata } = useCaptureAssetCandidateFileContext();
  const { upload } = useCaptureAssetLifecycleFlow();
  const { openDialog } = useDialogs();
  const track = useTrackRejectRecording(metadata, blob, 'video');

  const uploadRecording = useCallback(async () => {
    const file = new File([blob], blob instanceof File ? blob.name : 'screen-recording', {
      lastModified: new Date().getTime(),
      type: blob.type,
    });
    await upload(file, metadata);
  }, [blob, upload, metadata]);

  const removeRecording = useCallback(() => {
    openDialog('DeleteRecordingDialog', {
      onAction: () => {
        track();
        clearRecording();
      },
    });
  }, [clearRecording, openDialog, track]);

  useCaptureBackButtonBehavior(removeRecording);
  return useMemo(() => ({ uploadRecording, removeRecording }), [uploadRecording, removeRecording]);
};
