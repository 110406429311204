import { useMemo } from 'react';

const STAGE = process.env.STAGE as string;

type ElementFactory = Pick<Document, 'createElement'>;

type Position = { x: number; y: number };
type Size = { width: number; height: number };
type Rectangle = Position & Size;

export type ObjectFit = 'cover' | 'contain' | 'fill' | 'none';
export type PiPSize = 'small' | 'medium' | 'large';
export type PiPAnchor = 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
export type PiPShape = 'circle' | 'square';

export type CanvasPainter = {
  readonly width: number;
  readonly height: number;
  clear: VoidFunction;
  paint: (source: HTMLVideoElement | HTMLCanvasElement | ImageBitmap, objectFit?: ObjectFit) => void;
  pip: (
    source: HTMLVideoElement | HTMLCanvasElement | ImageBitmap,
    size?: PiPSize,
    anchor?: PiPAnchor,
    shape?: PiPShape
  ) => void;
  mediaStream: () => MediaStream;
  asBlob: () => Promise<Blob>;
  asFile: (filename?: string) => Promise<File>;
  asUrl: () => string;
};
export const useCanvasPainter = (
  size: Size | null,
  backgroundColor = 'transparent',
  elementFactory: ElementFactory = document
) => {
  return useMemo(
    () => createCanvasPainter(size, backgroundColor, elementFactory),
    [size, backgroundColor, elementFactory]
  );
};

export function createCanvasPainter(
  size: Size | null,
  backgroundColor = 'transparent',
  elementFactory: ElementFactory = document
): CanvasPainter | null {
  if (!size) return null;
  const { width, height } = size;
  if (!width || !height) return null;
  const canvas = elementFactory.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  if (!ctx) return null;

  ctx.imageSmoothingEnabled = true;
  ctx.imageSmoothingQuality = 'high';

  const clear =
    backgroundColor === 'transparent'
      ? () => ctx.clearRect(0, 0, width, height)
      : () => {
          ctx.fillStyle = backgroundColor;
          ctx.fillRect(0, 0, width, height);
        };

  const paint = (source: HTMLVideoElement | HTMLCanvasElement | ImageBitmap, objectFit: ObjectFit = 'cover') => {
    paintOnCanvas(ctx, source, objectFit);
  };
  const pip = (
    source: HTMLVideoElement | HTMLCanvasElement | ImageBitmap,
    size?: PiPSize,
    anchor?: PiPAnchor,
    shape?: PiPShape
  ) => {
    pipOnCanvas(ctx, source, size, anchor, shape);
  };
  const mediaStream = () => canvas.captureStream();

  const asUrl = () => canvas.toDataURL('image/jpeg', 0.9);
  const asBlob = () =>
    new Promise<Blob>((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) return reject(new Error('Failed to create blob'));
          return resolve(blob);
        },
        'image/jpeg',
        0.9
      );
    });
  const asFile = (filename = 'image.jpg') => {
    return asBlob().then((blob) => new File([blob], filename, { type: 'image/jpeg' }));
  };

  return { width, height, clear, paint, pip, mediaStream, asBlob, asFile, asUrl } as const;
}

function pipOnCanvas(
  target: CanvasRenderingContext2D,
  source: HTMLVideoElement | HTMLCanvasElement | ImageBitmap,
  size: PiPSize = 'medium',
  anchor: PiPAnchor = 'bottom-right',
  shape: PiPShape = 'circle'
) {
  const margin = 16;
  const s = { small: 184, medium: 248, large: 360 }[size];
  const x = {
    'top-left': margin,
    'top-right': target.canvas.width - s - margin,
    'bottom-left': margin,
    'bottom-right': target.canvas.width - s - margin,
  }[anchor];
  const y = {
    'top-left': margin,
    'top-right': margin,
    'bottom-left': target.canvas.height - s - margin,
    'bottom-right': target.canvas.height - s - margin,
  }[anchor];

  const targetRect = { x, y, width: s, height: s };
  target.save();
  target.beginPath();
  if (shape === 'circle') {
    target.arc(
      targetRect.x + targetRect.width / 2,
      targetRect.y + targetRect.height / 2,
      Math.min(targetRect.width, targetRect.height) / 2,
      0,
      Math.PI * 2,
      true
    );
  } else {
    target.rect(targetRect.x, targetRect.y, targetRect.width, targetRect.height);
  }
  target.clip();
  paintOnCanvas(target, source, 'cover', targetRect);
  target.restore();
}

function paintOnCanvas(
  target: CanvasRenderingContext2D,
  source: HTMLVideoElement | HTMLCanvasElement | ImageBitmap,
  objectFit: ObjectFit = 'cover',
  maybeTargetRect?: Rectangle,
  maybeSourceRect?: Rectangle
) {
  const targetRect = maybeTargetRect ?? { x: 0, y: 0, width: target.canvas.width, height: target.canvas.height };
  const sourceRect = maybeSourceRect ?? { x: 0, y: 0, width: source.width, height: source.height };

  if (sourceRect.width === 0 && sourceRect.height === 0 && 'getBoundingClientRect' in source) {
    const { width, height } = source.getBoundingClientRect();
    sourceRect.width = width;
    sourceRect.height = height;
  }

  if (targetRect.width === 0 || targetRect.height === 0) {
    if (STAGE !== 'production') console.warn('targetRect is empty');
    return;
  }
  if (sourceRect.width === 0 || sourceRect.height === 0) {
    if (STAGE !== 'production') console.warn('sourceRect is empty');
    return;
  }

  switch (objectFit) {
    case 'cover':
      {
        const ratio = Math.max(targetRect.width / sourceRect.width, targetRect.height / sourceRect.height);
        const w = sourceRect.width * ratio;
        const h = sourceRect.height * ratio;
        const x = (targetRect.width - w) / 2 + targetRect.x;
        const y = (targetRect.height - h) / 2 + targetRect.y;
        target.drawImage(source, x, y, w, h);
      }
      break;
    case 'contain':
      {
        const ratio = Math.min(targetRect.width / sourceRect.width, targetRect.height / sourceRect.height);
        const w = sourceRect.width * ratio;
        const h = sourceRect.height * ratio;
        const x = (targetRect.width - w) / 2 + targetRect.x;
        const y = (targetRect.height - h) / 2 + targetRect.y;
        target.drawImage(source, x, y, w, h);
      }
      break;
    case 'fill':
      target.drawImage(
        source,
        sourceRect.x,
        sourceRect.y,
        sourceRect.width,
        sourceRect.height,
        targetRect.x,
        targetRect.y,
        targetRect.width,
        targetRect.height
      );
      break;
    case 'none':
      target.drawImage(
        source,
        sourceRect.x,
        sourceRect.y,
        sourceRect.width,
        sourceRect.height,
        targetRect.x + (targetRect.width - sourceRect.width) / 2,
        targetRect.y + (targetRect.height - sourceRect.height) / 2,
        sourceRect.width,
        sourceRect.height
      );
      break;
  }
}
