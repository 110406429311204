import { type FC, useCallback, useEffect, useMemo, useState } from 'react';

import { VIDEO_FORMATS } from '@cofenster/constants';
import {
  AspectRatioBox,
  type BackgroundEffect,
  Button,
  GridItem,
  Icon,
  ImageCaptureBarMobile,
  SUPPORTS_TRANSFORMING_STREAMS,
  preventForwardProps,
  styled,
  useBackgroundEffect,
  useFeatureFlags,
  usePersistedState,
  useUserMediaStream,
} from '@cofenster/web-components';

import { useCaptureAssetCandidateFileContext } from '../../../../context/captureAssetFile';
import { useDialogs } from '../../../../context/dialogs/useDialogs';
import { useTracking } from '../../../../context/tracking';
import { useNavigateBack } from '../../../../hooks/useNavigateBack';
import { useOrientation } from '../../../../hooks/useOrientation';
import { ORIENTATION_REQUIREMENTS, OrientationRestriction } from '../../../display';
import { useCaptureAssetLifecycleFlow } from '../../CaptureAssetLifecycleFlow';
import { MobileCaptureTitleAndInstructions } from '../../CaptureTitleAndInstructions/MobileCaptureTitleAndInstructions';
import { ContributionRequestTitleAndInstructions } from '../../CaptureVideo/CaptureRecording/WebcamRecorderMobile/ContributionRequestTitleAndInstructions';
import { TopBar } from '../../CaptureVideo/CaptureRecording/WebcamRecorderMobile/TopBar';
import { PreviewStream } from '../../CaptureVideo/CoverVideo';
import { InstructionsDivider } from '../../InstructionsDivider';
import { useSetBackgroundEffectWithTracking } from '../../useSetBackgroundEffectWithTracking';
import { useCaptureImage } from '../useCaptureImage';

// 1. Very very fickle: basically above the `PageHeader` (which does not have
//    z-index), but below things like `TopBar` and controls on the side.
const CameraPreviewBox = styled(
  'div',
  preventForwardProps(['orientation'])
)(({ orientation }: { orientation: 'landscape' | 'portrait' }) => ({
  display: 'flex',
  justifyContent: 'center',
  position: 'fixed',
  inset: 0,
  height: '100vh',
  alignItems: orientation === 'portrait' ? 'center' : undefined,
  backgroundColor: 'black',
  zIndex: 2, // 1

  'body &': {
    touchAction: 'pan-x pan-y',
  },
}));

export type RecordingFlowStep = 'contribution_request_instructions' | 'capture';

export const BrowserCaptureByMobile: FC = () => {
  const tracking = useTracking();
  const [currentStep, setCurrentStep] = useState<RecordingFlowStep>('contribution_request_instructions');
  const orientation = useOrientation();
  const { format: videoFormat } = useCaptureAssetLifecycleFlow();
  const [requestedFacingMode, setRequestedFacingMode] = useState<'user' | 'environment'>('environment');
  const [currentFacingMode, setCurrentFacingMode] = useState<null | 'user' | 'environment'>(null);
  const flipCamera = useCallback(
    () => setRequestedFacingMode((prev) => (prev === 'environment' ? 'user' : 'environment')),
    []
  );
  const navigateBack = useNavigateBack();
  const { onCaptureAssetReadyForReview } = useCaptureAssetCandidateFileContext();
  const { openDialog } = useDialogs();
  const [areInstructionsShown, setAreInstructionsShown] = useState<boolean>(false);
  const { hasFeature } = useFeatureFlags();
  const [backgroundEffect, setBackgroundEffect] = usePersistedState<BackgroundEffect>(
    'background-effect',
    'NONE',
    true
  );
  const setBackgroundEffectWithTracking = useSetBackgroundEffectWithTracking(tracking, setBackgroundEffect);

  const openHowToLookGoodOnCamera = () => {
    openDialog('FilmingTipsDialog', {});
    tracking.trackEvent({ event: 'recordingTipsOpened' });
  };

  const { output: mediaStream, isInitializing: isBackgroundEffectInitializing } = useBackgroundEffect(
    useUserMediaStream(
      useMemo(() => {
        return {
          video: {
            facingMode: {
              ideal: requestedFacingMode,
            },
          },
        };
      }, [requestedFacingMode])
    ).value,
    backgroundEffect,
    videoFormat
  );

  const onCapture = useCaptureImage({
    mediaStream,
    videoFormat,
    save: onCaptureAssetReadyForReview,
    uploadSource: 'mobile-webcam-recording',
    backgroundEffect,
  });

  const onOpenInstructions = () => {
    setAreInstructionsShown(true);
  };

  useEffect(() => {
    if (mediaStream) setCurrentFacingMode(requestedFacingMode);
  }, [mediaStream, requestedFacingMode]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: we want to reset the current facing mode, when the requested facing mode changes
  useEffect(() => {
    setCurrentFacingMode(null);
  }, [requestedFacingMode]);

  if (currentStep === 'contribution_request_instructions') {
    return <ContributionRequestTitleAndInstructions goToNextStep={() => setCurrentStep('capture')} fileType="image" />;
  }

  return (
    <OrientationRestriction needs={ORIENTATION_REQUIREMENTS[videoFormat]}>
      <TopBar orientation={orientation} onClose={navigateBack} onOpenInstructions={onOpenInstructions} />
      <CameraPreviewBox orientation={orientation}>
        {orientation === 'landscape' ? (
          <AspectRatioBox ratio={VIDEO_FORMATS[videoFormat].aspectRatio} height={window.innerHeight}>
            {mediaStream && <PreviewStream stream={mediaStream} mirror={currentFacingMode === 'user'} />}
          </AspectRatioBox>
        ) : (
          <AspectRatioBox ratio={VIDEO_FORMATS[videoFormat].aspectRatio} width="100vw">
            {mediaStream && <PreviewStream stream={mediaStream} mirror={currentFacingMode === 'user'} />}
          </AspectRatioBox>
        )}
      </CameraPreviewBox>

      {areInstructionsShown && (
        <MobileCaptureTitleAndInstructions>
          <InstructionsDivider />
          <GridItem xs={12} sm={6}>
            <Button fullWidth variant="primary" onClick={() => setAreInstructionsShown(false)}>
              i18n.Recording.ImageCapture.CloseInstructions
            </Button>
          </GridItem>
          <GridItem xs={12} sm={6}>
            <Button
              fullWidth
              variant="secondary"
              onClick={openHowToLookGoodOnCamera}
              startIcon={<Icon type="GraduationCapIcon" size="s" />}
            >
              i18n.ScenePage.InstructionsScreen.mobile.howToLookGoodOnCameraButton
            </Button>
          </GridItem>
        </MobileCaptureTitleAndInstructions>
      )}

      <ImageCaptureBarMobile
        orientation={orientation}
        flipCamera={flipCamera}
        handleLibraryUpload={(file) =>
          onCaptureAssetReadyForReview(
            {
              blob: file,
              url: URL.createObjectURL(file),
            },
            { uploadSource: 'mobile-library' }
          )
        }
        onCapture={onCapture}
        isRecordButtonDisabled={areInstructionsShown}
        isFlipButtonDisabled={!currentFacingMode}
        backgroundEffect={backgroundEffect}
        onBackgroundEffectSelect={
          SUPPORTS_TRANSFORMING_STREAMS && hasFeature('VIRTUAL_BACKGROUNDS_MOBILE')
            ? setBackgroundEffectWithTracking
            : undefined
        }
        isBackgroundEffectInitializing={isBackgroundEffectInitializing}
      />
    </OrientationRestriction>
  );
};
