import { useEffect, useState } from 'react';

export type InternalPermissionState = PermissionState | 'error' | 'not_supported' | 'loading' | undefined;
export type SupportedDevices = 'microphone' | 'camera' | 'both' | 'none';

const STAGE = process.env.STAGE as string;

const requireCamera = (type: SupportedDevices) => ['camera', 'both'].includes(type);
const requireMicrophone = (type: SupportedDevices) => ['microphone', 'both'].includes(type);

export const useCapturePermissions = (
  type: SupportedDevices = 'both'
): { status: InternalPermissionState; request: VoidFunction } => {
  const [cameraPermission, setCameraPermission] = useMediaDevicePermissions('camera', !requireCamera(type));
  const [microphonePermission, setMicrophonePermission] = useMediaDevicePermissions(
    'microphone',
    !requireMicrophone(type)
  );

  const request = async () => {
    const askForCamera = requireCamera(type);
    const askForMicrophone = requireMicrophone(type);

    if (!askForCamera && !askForMicrophone) return;
    if (askForCamera) setCameraPermission('loading');
    if (askForMicrophone) setMicrophonePermission('loading');
    const newStatus = await checkPermissionsWithMediaDevices({
      video: requireCamera(type),
      audio: requireMicrophone(type),
    });
    if (askForCamera) setCameraPermission(newStatus);
    if (askForMicrophone) setMicrophonePermission(newStatus);
  };

  let status: InternalPermissionState = undefined;
  if (microphonePermission === 'granted' && cameraPermission === 'granted') {
    status = 'granted';
  } else if (microphonePermission === 'denied' || cameraPermission === 'denied') {
    status = 'denied';
  } else if (microphonePermission === 'prompt' || cameraPermission === 'prompt') {
    status = 'prompt';
  } else if (microphonePermission === 'loading' || cameraPermission === 'loading') {
    status = 'loading';
  }

  return {
    status,
    request,
  };
};

const useMediaDevicePermissions = (
  name: SupportedDevices,
  alwaysGranted = false
): [InternalPermissionState, (status: InternalPermissionState) => void] => {
  const [status, setStatus] = useState<InternalPermissionState>(alwaysGranted ? 'granted' : 'loading');

  useEffect(() => {
    if (alwaysGranted) return;
    queryAsync(setStatus, name).then((state) => setStatus(state));
  }, [name, alwaysGranted]);

  return [status, setStatus];
};

const queryAsync = async (callback: (status: PermissionState) => void, name: SupportedDevices) => {
  const checkWithMediaDevices = () =>
    checkPermissionsWithMediaDevices({
      audio: name === 'microphone',
      video: name === 'camera',
    });

  try {
    if (!navigator.permissions) {
      return checkWithMediaDevices();
    }

    const permissionStatus = await navigator.permissions.query({ name: name as never });
    permissionStatus.onchange = () => {
      if (STAGE !== 'production') {
        console.log('Permission status changed to: ', permissionStatus.state);
      }
      callback(permissionStatus.state);
    };
    return permissionStatus.state;
  } catch (error) {
    console.error(`Failed to query permissions for name ${name}.`, error);
    console.info('Falling back to media devices.');
    return checkWithMediaDevices();
  }
};

const checkPermissionsWithMediaDevices = async (constraints: {
  video?: boolean;
  audio?: boolean;
}): Promise<InternalPermissionState> => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia(constraints);
    stream.getTracks().forEach((track) => track.stop());
    return 'granted';
  } catch (error) {
    if (STAGE !== 'production') {
      console.log('Camera and microphone access not granted.', error);
    }

    return 'denied';
  }
};
