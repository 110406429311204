import { useMemo } from 'react';

export const useMergedMediaStream = (
  videoStream: MediaStream | null,
  audioStream: MediaStream | null,
  allowOnlyAudio = false
) =>
  useMemo(() => {
    if (videoStream === audioStream) return videoStream;
    if (videoStream && audioStream) {
      return new MediaStream([...videoStream.getVideoTracks(), ...audioStream.getAudioTracks()]);
    }
    if (videoStream) return videoStream;
    if (audioStream && allowOnlyAudio) return audioStream;
    return null;
  }, [videoStream, audioStream, allowOnlyAudio]);
