export type ShaderAttributes = {
  aTex: 'vec2';
  aVertex: 'vec2';
};

const verifyAttributes = (source: string): ShaderAttributes => {
  if (!source.includes('attribute vec2 aVertex') || !source.includes('attribute vec2 aTex')) {
    console.error(source);
    throw new Error('Vertex shader source does not contain all attributes');
  }
  return { aVertex: 'vec2', aTex: 'vec2' };
};

/**
 * we're forcing shader to have aTex and aVertex attributes
 * even though they could be named differently
 */
export const createVertexShader = (source: string) => {
  return {
    init: (gl: WebGL2RenderingContext) => {
      const vShader = gl.createShader(gl.VERTEX_SHADER);
      if (!vShader) throw new Error('Failed to create vertex shader');
      gl.shaderSource(vShader, source);
      gl.compileShader(vShader);
      if (!gl.getShaderParameter(vShader, gl.COMPILE_STATUS)) {
        console.error(gl.getShaderInfoLog(vShader));
        throw new Error('Failed to compile vertex shader');
      }
      return vShader;
    },
    attributes: verifyAttributes(source),
  };
};

export type VertexShader = ReturnType<typeof createVertexShader>;

export const SIMPLE_VERTEX_SHADER = createVertexShader(
  `
attribute vec2 aVertex;
attribute vec2 aTex;
varying vec2 vTex;

void main(void) {
  gl_Position = vec4(aVertex, 0.0, 1.0);
  vTex = aTex;
}
`
);
