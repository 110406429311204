import { type FC, type PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { SelectedAssetIdContext } from './SelectedAssetIdContext';

export const SelectedAssetIdProvider: FC<PropsWithChildren> = ({ children }) => {
  const [selectedAssetIdByTaskId, setSelectedAssetIdByTaskId] = useState<Record<string, string | undefined>>({});
  const getSelectedAssetId = useCallback(
    (taskId: string) => {
      return selectedAssetIdByTaskId[taskId];
    },
    [selectedAssetIdByTaskId]
  );
  const setSelectedAssetId = useCallback((taskId: string, assetId: string | undefined) => {
    setSelectedAssetIdByTaskId((prev) => ({
      ...prev,
      [taskId]: assetId,
    }));
  }, []);

  const context = useMemo(() => ({ setSelectedAssetId, getSelectedAssetId }), [setSelectedAssetId, getSelectedAssetId]);

  return <SelectedAssetIdContext.Provider value={context}>{children}</SelectedAssetIdContext.Provider>;
};
