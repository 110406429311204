import { GlobalEN } from '@cofenster/web-components';

import { SceneAssetLifecycleTranslationsEN } from '../../components/capture/translations/EN';

export const EN = {
  ...GlobalEN,
  ...SceneAssetLifecycleTranslationsEN,
  'AppStoreCard.availableFor': 'cofenster capture app available for',
  'BrowserNotSupportedBanner.close': 'Close Banner',
  'ContributionRequestListPage.header.finished.title': 'Thank you!',
  'ContributionRequestListPage.header.finished.subline': 'All your contributions are uploaded to “{projectName}” by {projectOwner}',
  'ContributionRequestListPage.header.headline': 'Your video tasks',
  'ContributionRequestListPage.header.subline': 'Contribute to the Video {projectName} by {firstname} {lastname}',
  'ContributionRequestListPage.nextTask': 'Next task',
  'ContributionRequestListPage.PreviewBriefingVideoDialog.title': 'Preview briefing video',
  'ContributionRequestListPage.task.noTitle': 'Task {n}',
  'ContributionRequestListPage.task.status.done': 'Done',
  'ContributionRequestListPage.task.status.error': 'Error',
  'ContributionRequestListPage.task.status.processing': 'Processing',
  'ContributionRequestListPage.task.status.uploading': 'Uploading ({progress}%)',
  'ContributionRequestListPage.tasksProgress.allDone': 'Great job!',
  'ContributionRequestListPage.tasksProgress.progress': '{done} of {total}',
  'ContributionRequestPage.backButton': 'Video tasks',
  'ContributionRequestPage.contributions': '{count, plural, =0 {No contributions} one {# contribution} other {# contributions}}',
  'ContributionRequestPage.noInstructions': 'No instructions',
  'ContributionRequestPage.noTitle': 'Task {n}',
  'ContributionRequestPage.showMore': 'Show more',
  'CopyDataToClipBoard.codeCopied': 'Code copied',
  'DeleteContributionDialog.button': 'Delete contribution',
  'DeleteContributionDialog.description': 'Are you sure? You are about to delete this contribution permanently.',
  'DeleteContributionDialog.headline': 'Delete contribution?',
  'ErrorPage.button': 'Back to CoCapture',
  'ErrorPage.emptyRequestList.headline': 'This request list is empty',
  'ErrorPage.emptyRequestList.subHeadline': 'Oops! It looks like there are no recording requests associated with this link. Please contact the request list creator to resolve this issue.',
  'ErrorPage.headline': 'Oh no, your link does not match any projects',
  'ErrorPage.notAcceptingResponses.headline': 'This video is no longer accepting contributions',
  'ErrorPage.notAcceptingResponses.subHeadline': 'Filming for “{projectName}” has concluded. Contact the creator of this video if you think this is a mistake.',
  'ErrorPage.subHeadline': 'Maybe the project was archived or deleted. Get in contact with your project admin to clarify your access.',
  'Form.error.email.format': 'Please enter a valid email address',
  'header.openInApp': 'Open in app',
  'OpenInAppDialog.description.withoutQrCode': 'Open the cofenster app and type in the code below.',
  'OpenInAppDialog.description.withQrCode': 'Open the cofenster app or your phone camera and scan the QR code. You can also copy the code below.',
  'OpenInAppDialog.headline.withoutQrCode': 'Copy the code',
  'OpenInAppDialog.headline.withQrCode': 'Scan the QR code',
  'OrientationRestriction.descriptionHorizontal': 'The scene needs to be filmed in horizontal orientation',
  'OrientationRestriction.descriptionVertical': 'The scene needs to be filmed in vertical orientation',
  'OrientationRestriction.title': 'Rotate your phone',
  'PageFooter.dataProtection': 'Data protection',
  'PageFooter.imprint': 'Imprint',
  'PageFooter.terms': 'Terms of use',
  'ScenePage.MobileWebcamRecorder.instructionsButton': 'Instructions',
  'SigninByCodePage.headline': 'Enter a project code',
  'SigninByCodePage.hint': 'You don’t have a code? Ask your colleague to share it with you or create your own video project at',
  'SigninByCodePage.SigninByCodeForm.code.invalid': 'No project found for the given code',
  'SigninByCodePage.SigninByCodeForm.code.label': 'Code',
  'SigninByCodePage.SigninByCodeForm.code.required': 'Please enter a project code',
  'SigninByCodePage.SigninByCodeForm.error': 'Something went wrong',
  'SigninByCodePage.SigninByCodeForm.submit': 'Open video project',
  'SigninByCodePage.subline': 'Please enter a code to contribute to your cofenster video project',

  'WelcomePage.button.continue': 'Continue',
  'WelcomePage.button.signAgreement': 'Sign agreement',
  'WelcomePage.contributors.form.add': 'More than one person recording? <link>Add another contributor</link>.',
  'WelcomePage.contributors.form.email.required': 'Please enter your email',
  'WelcomePage.contributors.form.email': 'Email address',
  'WelcomePage.contributors.form.name.required': 'Please enter your name',
  'WelcomePage.contributors.form.name': 'Full name',
  'WelcomePage.contributors.form.remove': 'Remove contributor',
  'WelcomePage.welcomeMessage.consentRequired.agreement': 'By clicking on the “Sign agreement” button below, I agree to the video usage agreement of {accountName}.',
  'WelcomePage.welcomeMessage.consentRequired.descriptionParagraph2': 'Before you start filming, please review the <link>video usage agreement</link> provided by {accountName}. You can revoke your consent to appearing in their video at any time.',
  'WelcomePage.welcomeMessage.consentRequired.error.description': 'We weren’t able to generate the signed consent file. Please try to refresh the page or contact us.',
  'WelcomePage.welcomeMessage.consentRequired.error.title': 'Oops. Something went wrong.',
  'WelcomePage.welcomeMessage.descriptionParagraph1.noProjectCreator': 'Your participation is needed in the project “{projectName}”!',
  'WelcomePage.welcomeMessage.descriptionParagraph1': '{firstname} needs your participation in the project “{projectName}”!',
  'WelcomePage.welcomeMessage.descriptionParagraph2.noProjectCreator': 'Cofenster helps you in recording your scenes and sending it back to the project creator.',
  'WelcomePage.welcomeMessage.descriptionParagraph2': 'Cofenster helps you in recording your scenes and sending it back to {firstname}.',
  'WelcomePage.welcomeMessage.identificationRequired.noProjectCreator': 'Before you start, please tell us a bit about yourself so the project creator can get back to you if necessary',
  'WelcomePage.welcomeMessage.identificationRequired': 'Before you start, please tell us a bit about yourself so {firstname} can get back to you if necessary',
  'WelcomePage.welcomeMessage.title.noProjectCreator': 'Hey! You’re invited to a video',
  'WelcomePage.welcomeMessage.title': 'Hey! {firstname} {lastname} invited you to a video',
  'WelcomePage.welcomeMessage.verifyContributors.button': 'Change contact details',
  'WelcomePage.welcomeMessage.verifyContributors.title': 'Your contact details',
};
