import { FilesetResolver, ImageSegmenter } from '@mediapipe/tasks-vision';
import { useEffect, useMemo } from 'react';

import { ASSET_URL } from '@cofenster/constants';

import { usePromise } from '../../utils/usePromise';

const SEGMENTATION_MODELS = {
  // superfast
  SELFIE_LANDSCAPE: {
    source: `${ASSET_URL}/mediapipe-models/image_segmenter/selfie_segmenter_landscape/float16/latest/selfie_segmenter_landscape.tflite`,
    confidenceMask: {
      invert: true,
      name: 'selfie',
    },
  },
  // superfast
  SELFIE: {
    source: `${ASSET_URL}/mediapipe-models/image_segmenter/selfie_segmenter/float16/latest/selfie_segmenter.tflite`,
    confidenceMask: {
      invert: true,
      name: 'selfie',
    },
  },
  // better quality
  SELFIE_MULTICLASS: {
    source: `${ASSET_URL}/mediapipe-models/image_segmenter/selfie_multiclass_256x256/float32/latest/selfie_multiclass_256x256.tflite`,
    confidenceMask: {
      invert: false,
      name: 'background',
    },
  },
} as const;

type SegmentationModel = keyof typeof SEGMENTATION_MODELS;

const pickSegmentationModel = (fast: boolean, aspectRatio = 16 / 9): SegmentationModel => {
  if (!fast) return 'SELFIE_MULTICLASS';
  if (aspectRatio >= 4 / 3) return 'SELFIE_LANDSCAPE';
  return 'SELFIE';
};

export const useImageSegmenterWithBackgroundConfidenceMask = (
  canvas: HTMLCanvasElement | OffscreenCanvas | null,
  fast = true,
  runningMode: 'IMAGE' | 'VIDEO' = 'VIDEO',
  delegate: 'CPU' | 'GPU' = 'GPU'
) => {
  const { value: imageSegmenter, pending: initializing } = usePromise(
    useMemo(async () => {
      if (!canvas) return null;
      const model = pickSegmentationModel(fast, canvas.width / canvas.height);
      const vision = await FilesetResolver.forVisionTasks(`${ASSET_URL}/@mediapipe/tasks-vision/wasm`);
      const imageSegmenter = await ImageSegmenter.createFromOptions(vision, {
        runningMode,
        outputCategoryMask: false,
        outputConfidenceMasks: true,
        canvas,
        baseOptions: {
          delegate,
          modelAssetPath: SEGMENTATION_MODELS[model].source,
        },
      });

      return Object.assign(imageSegmenter, SEGMENTATION_MODELS[model]);
    }, [canvas, fast, runningMode, delegate])
  );

  useEffect(() => {
    if (imageSegmenter)
      return () => {
        imageSegmenter.close();
      };
  }, [imageSegmenter]);

  return [imageSegmenter, initializing] as const;
};
