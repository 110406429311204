import { useEffect, useMemo, useState } from 'react';

export const useAudioVolume = (
  mediaStream: MediaStream | undefined | null,
  interval = 200,
  maxSamples = 16,
  enabled = true
) => {
  const [volumes, setVolumes] = useState<number[]>([]);

  useEffect(() => {
    if (!mediaStream || !enabled) {
      setVolumes([]);
      return;
    }

    const audioContext = new AudioContext();
    const analyser = audioContext.createAnalyser();
    const source = audioContext.createMediaStreamSource(mediaStream);
    source.connect(analyser);

    analyser.minDecibels = -100;
    analyser.maxDecibels = -40;
    analyser.fftSize = 32;

    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    const calculateVolume = () => {
      analyser.getByteFrequencyData(dataArray);

      let sum = 0;
      for (const amplitude of dataArray) {
        sum += amplitude * amplitude;
      }

      const currentVolume = Math.sqrt(sum / dataArray.length);
      const currentVolumeNormalized = currentVolume / 255;
      if (maxSamples > 1) {
        setVolumes((volumes) => [
          ...(volumes.length >= maxSamples ? volumes.slice(-maxSamples + 1) : volumes),
          currentVolumeNormalized,
        ]);
      } else {
        setVolumes([currentVolumeNormalized]);
      }
    };

    calculateVolume();
    const intervalHandler = setInterval(calculateVolume, interval);

    return () => {
      clearInterval(intervalHandler);
      source.disconnect();
      audioContext.close();
      setVolumes([]);
    };
  }, [mediaStream, maxSamples, interval, enabled]);

  const normalizedMovingRms = useMemo(() => volumes.reduce((a, b) => a + b, 0) / volumes.length, [volumes]);

  return {
    volume: volumes[volumes.length - 1],
    volumes,
    isSpeaking: enabled && volumes.length >= maxSamples && normalizedMovingRms > 0.45,
  };
};
