import type { FC } from 'react';

import { GlobalStyles as BaseGlobalStyles, useTheme } from '@cofenster/web-components';

export const GlobalStyles: FC = () => {
  const theme = useTheme();
  return (
    <>
      <BaseGlobalStyles
        styles={{
          body: {
            backgroundColor: theme.palette.brand.linen,
          },
        }}
      />
    </>
  );
};
