import { useEffect, useState } from 'react';

export const useIsHovered = (element: HTMLElement | null) => {
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (!element) return;

    const onMouseEnter = () => setIsHovered(true);
    const onMouseLeave = () => setIsHovered(false);

    element.addEventListener('mouseenter', onMouseEnter);
    element.addEventListener('mouseleave', onMouseLeave);

    return () => {
      element.removeEventListener('mouseenter', onMouseEnter);
      element.removeEventListener('mouseleave', onMouseLeave);
    };
  }, [element]);

  return isHovered;
};
