import type { FC, MouseEventHandler } from 'react';

import { IconButton } from '../../../../controls/Button/IconButton';

export const SelectDesktopButton: FC<{ onSelectDesktop: MouseEventHandler }> = ({ onSelectDesktop }) => (
  <IconButton
    onClick={onSelectDesktop}
    data-testid="select-desktop-button"
    icon="ScreenShareIcon"
    iconColor="white"
    backgroundColor="blurred"
    label="i18n.global.desktop.select"
  />
);
