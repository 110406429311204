import { type FC, type PropsWithChildren, useCallback, useMemo } from 'react';
import { useBrowserStorageState } from '../../hooks/useBrowserStorageState';
import { type Contributor, ContributorsContext } from './ContributorsContext';

type ContributorsByProjectId = Record<string, Contributor[]>;

export const ContributorsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [contributorsByProject, setContributorsByProject] = useBrowserStorageState<ContributorsByProjectId>(
    'contributorsByProjectId',
    {},
    false
  );

  const getByProject = useCallback(
    (projectId: string) => contributorsByProject[projectId] ?? [],
    [contributorsByProject]
  );

  const setByProject = useCallback(
    (projectId: string, contributors: Contributor[]) => {
      setContributorsByProject((local) => ({ ...local, [projectId]: contributors }));
    },
    [setContributorsByProject]
  );

  const ctx = useMemo(() => ({ getByProject, setByProject }), [setByProject, getByProject]);

  return <ContributorsContext.Provider value={ctx}>{children}</ContributorsContext.Provider>;
};
