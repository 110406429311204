import { styled } from '@cofenster/web-components';

export const Page = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  '@supports (-webkit-touch-callout: none)': {
    minHeight: '-webkit-fill-available',
  },
  backgroundColor: theme.palette.brand.linen,
}));
