import { type FC, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { LoadingSpinner } from '@cofenster/web-components';

import { useSigninActorByToken } from '../../api/hooks/actor/useSigninActorByToken';
import { Layout } from '../../components';
import { EnsureAcceptResponses } from '../../components/EnsureAcceptResponses';
import { useClient } from '../../context/client';
import { ContributionRequestListDetailProvider } from '../../context/contributionRequestListDetails';
import { useNavigateTo } from '../../hooks/useNavigateTo';
import type { TokenRouteParams } from '../../router/routes';
import { ErrorPage } from '../ErrorPage';
import { WelcomePageContent } from './WelcomePageContent';

const useSigninResult = () => {
  const { token: urlToken } = useParams() as TokenRouteParams;
  const { state, authenticate, fail } = useClient();
  const verifyToken = useSigninActorByToken();

  useEffect(() => {
    verifyToken(urlToken)
      .then(() => {
        authenticate(urlToken);
      })
      .catch((error) => fail(error.message, urlToken));
  }, [verifyToken, urlToken, authenticate, fail]);

  return state;
};

const ensureCreatorOrUndefined = (creator?: { firstname?: string | null; lastname?: string | null } | null) =>
  creator?.firstname && creator?.lastname ? { firstname: creator.firstname, lastname: creator.lastname } : undefined;

export const WelcomePage: FC = () => {
  const { status, token } = useSigninResult();
  const navigateTo = useNavigateTo();
  const { search } = useLocation();

  if (status === 'ERROR') {
    return <ErrorPage />;
  }

  if (status === 'UNAUTHENTICATED') {
    return (
      <Layout>
        <LoadingSpinner />
      </Layout>
    );
  }

  // This bypass is used to circumvent test parallelization issues where test A
  // enables the `CONSENT` feature flag, and runs in parallel to test B which
  // does not expect the `CONSENT` feature flag to be enabled.
  const consentBypass = process.env.STAGE !== 'production' && search.includes('consent_bypass=1');

  return (
    <ContributionRequestListDetailProvider>
      {(contributionRequestListDetail) =>
        contributionRequestListDetail ? (
          <EnsureAcceptResponses
            projectName={contributionRequestListDetail.project.name}
            acceptResponses={contributionRequestListDetail.contributionSettings.acceptResponses}
          >
            <WelcomePageContent
              code={contributionRequestListDetail.contributionRequestList.code}
              qrcode={contributionRequestListDetail.contributionRequestList.qrCode}
              briefingVideo={
                contributionRequestListDetail.contributionRequestList.briefing.enabled
                  ? contributionRequestListDetail.contributionRequestList.briefing.video
                  : undefined
              }
              accountName={contributionRequestListDetail.account.name}
              identificationRequired={
                !consentBypass &&
                contributionRequestListDetail.consentSettings.isConsentRequired &&
                contributionRequestListDetail.consentSettings.documentUrl
                  ? { type: 'consent', documentUrl: contributionRequestListDetail.consentSettings.documentUrl }
                  : contributionRequestListDetail.contributionSettings.collectDetails
                    ? { type: 'identification' }
                    : null
              }
              projectId={contributionRequestListDetail.project.id}
              projectName={contributionRequestListDetail.project.name}
              projectCreator={ensureCreatorOrUndefined(contributionRequestListDetail.project.creator)}
              onContinue={() => navigateTo('contributionRequestList', { params: { token } })}
            />
          </EnsureAcceptResponses>
        ) : (
          <Layout>
            <LoadingSpinner />
          </Layout>
        )
      }
    </ContributionRequestListDetailProvider>
  );
};
