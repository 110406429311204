import { styled } from '@mui/material';
import { default as LinearProgress, type LinearProgressProps } from '@mui/material/LinearProgress';
import type { FC } from 'react';

const StyledLinearProgress = styled(LinearProgress)(() => ({
  width: '100%',
}));

export type LinearProgressBarProps = LinearProgressProps;

export const LinearProgressBar: FC<LinearProgressBarProps> = (props) => (
  <StyledLinearProgress color="primary" {...props} />
);
