import { Circle, Icon, Spacing, Typography, styled } from '@cofenster/web-components';
import { type FC, useCallback } from 'react';
import Confetti from 'react-canvas-confetti/dist/presets/realistic';
import type { TDecorateOptionsFn } from 'react-canvas-confetti/dist/types';

const AllDoneContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(1),
  padding: theme.spacing(5, 2, 2, 2),
}));

const StyledConfetti = styled(Confetti)(() => ({
  height: '100%',
  width: '100%',
  inset: 0,
  position: 'absolute',
}));

export const AllDone: FC<{ projectName: string; projectOwner: string }> = ({ projectName, projectOwner }) => {
  const decorateOptions: TDecorateOptionsFn = useCallback((options) => {
    options.colors = ['#AA82FF', '#FFFD5D', '#00F6CA'];
    return options;
  }, []);

  return (
    <AllDoneContainer>
      <StyledConfetti
        autorun={{
          speed: 0.1,
          delay: 300,
          duration: 1000,
        }}
        decorateOptions={decorateOptions}
      />
      <Spacing bottom={3}>
        <Circle color="blue100" size="xxl">
          <Icon type="ConfettiIcon" color="blue" size="l" />
        </Circle>
      </Spacing>
      <Typography variant="h2">i18n.ContributionRequestListPage.header.finished.title</Typography>
      <Typography
        i18nParams={{
          projectName,
          projectOwner,
        }}
        align="center"
        variant="l"
      >
        i18n.ContributionRequestListPage.header.finished.subline
      </Typography>
    </AllDoneContainer>
  );
};
