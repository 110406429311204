import { type FC, type PropsWithChildren, useCallback, useMemo, useReducer, useState } from 'react';

import { ApolloProvider, createApiClient } from '@cofenster/api-client-apollo';
import { usePersistedState } from '@cofenster/web-components';

import { CAPTURE_API_URL } from '@cofenster/constants';
import { useTracking } from '../tracking';
import { type ClientAction, ClientContext, type ClientState } from './ClientContext';

const reducer = (state: ClientState, action: ClientAction) => {
  switch (action.type) {
    case 'AUTHENTICATE': {
      return {
        status: 'AUTHENTICATED',
        token: action.token,
      } as ClientState;
    }
    case 'FAIL': {
      return {
        status: 'ERROR',
        token: undefined,
      } as ClientState;
    }
    default: {
      return state;
    }
  }
};

// Locally, the backend API is proxied through the /graphql route via the
// webpack dev server configuration
export const captureApiUrl = process.env.STAGE === 'local' ? '/graphql' : CAPTURE_API_URL;

export const ClientProvider: FC<PropsWithChildren<Record<string, unknown>>> = ({ children }) => {
  const tracking = useTracking();
  const [storedToken, storeToken] = usePersistedState<string | undefined>('accessToken', undefined, true);
  const [client, setClient] = useState(() => createApiClient({ url: captureApiUrl, authBearer: storedToken }));
  const initialState: ClientState = useMemo(
    () =>
      storedToken ? { status: 'AUTHENTICATED', token: storedToken } : { status: 'UNAUTHENTICATED', token: undefined },
    [storedToken]
  );
  const [state, dispatch] = useReducer(reducer, initialState);

  const authenticate = useCallback(
    (token: string) => {
      dispatch({ type: 'AUTHENTICATE', token });
      storeToken(token);
      setClient(createApiClient({ url: captureApiUrl, authBearer: token }));
    },
    [storeToken]
  );

  const fail = useCallback(
    (reason: string, token?: string) => {
      dispatch({ type: 'FAIL' });
      tracking.trackEvent({ event: 'actorSignInFailed', details: { token, reason } });
    },
    [tracking]
  );

  const context = useMemo<ClientContext>(() => ({ state, authenticate, fail }), [state, authenticate, fail]);

  return (
    <ClientContext.Provider value={context}>
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </ClientContext.Provider>
  );
};
