import { useCallback } from 'react';
import { type NavigateOptions, useNavigate } from 'react-router-dom';

import { generatePath } from '@cofenster/web-components';

import { type RouteName, type RouteParams, routes } from '../router/routes';

type NavigateTo = (
  route: RouteName,
  options?: {
    params?: RouteParams;
    state?: NavigateOptions['state'];
    replace?: boolean;
  }
) => void;

export const useNavigateTo = (): NavigateTo => {
  const navigate = useNavigate();
  return useCallback(
    (route, options) => {
      const path = generatePath(routes[route], options?.params);
      options?.replace
        ? navigate(path, {
            replace: true,
            state: options?.state,
          })
        : navigate(path, {
            state: options?.state,
          });
    },
    [navigate]
  );
};
