export const recordingPageComponentsZIndexes = {
  recordingBarControls: 10,
  // Scene title and instructions have to be on top of the
  // recording bar controls (it is a bottom sheet)
  mobileSceneTitleAndInstructions: 20,
  // Full screen loading has to be above the mobile scene title and instructions
  // We want to hide all UI when loading on mobile to avoid flakiness.
  fullScreenLoadingContainer: 30,
  bottomFixedActions: 40,
};
