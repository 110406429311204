import { styled } from '@mui/material';
import type { FC, ReactNode } from 'react';

import type { Theme } from '../../../../../theming/theme';

type RecordingLayoutProps = {
  isCompact?: boolean;
  mainButton?: ReactNode;
  leftButtons?: ReactNode;
  rightButtons?: ReactNode;
  top?: ReactNode;
  topOverlay?: ReactNode;
};

export const RecordingLayout: FC<RecordingLayoutProps> = ({
  isCompact = false,
  mainButton,
  leftButtons,
  rightButtons,
  top,
  topOverlay,
}) => {
  return (
    <GridLayout className={isCompact ? 'compact' : undefined}>
      {leftButtons && <LeftButtonsContainer>{leftButtons}</LeftButtonsContainer>}
      {mainButton && <MainButtonContainer>{mainButton}</MainButtonContainer>}
      {rightButtons && <RightButtonsContainer>{rightButtons}</RightButtonsContainer>}
      {top && <TopContainer>{top}</TopContainer>}
      {topOverlay && <TopOverlayContainer>{topOverlay}</TopOverlayContainer>}
    </GridLayout>
  );
};

const GridLayout = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'grid',
  gridTemplateColumns: `${theme.spacing(2)} 1fr ${theme.spacing(2)} 80px ${theme.spacing(2)} 1fr ${theme.spacing(2)}`,
  gridTemplateRows: `${theme.spacing(2)} 72px 1fr 80px ${theme.spacing(2)}`,
  position: 'absolute',
}));

const MainButtonContainer = styled('div')(() => ({
  gridArea: '4 / 4 / 5 / 5',
  alignItems: 'center',
  justifyContent: 'center',
}));

const leftButtonsCompactStyle = (theme: Theme) => ({
  gap: theme.spacing(2),
  gridRowStart: 3,
  flexDirection: 'column',
  alignItems: 'flex-start',
  '.MuiButton-blurred': {
    span: { margin: 0 },
    fontSize: 0,
  },
});

const LeftButtonsContainer = styled('div')(({ theme }) => ({
  gridArea: '4 / 2 / 5 / 3',
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
  justifyContent: 'flex-end',

  [theme.breakpoints.down('md')]: leftButtonsCompactStyle(theme),
  '.compact > &': leftButtonsCompactStyle(theme),
}));

const rightButtonsCompactStyle = (theme: Theme) => ({
  gap: theme.spacing(2),
  gridRowStart: 3,
  flexDirection: 'column',
  alignItems: 'flex-end',
  justifyContent: 'flex-end',
  '.MuiButton-blurred': {
    span: { margin: 0 },
    fontSize: 0,
  },
});

const RightButtonsContainer = styled('div')(({ theme }) => ({
  gridArea: '4 / 6 / 5 / 7',
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
  justifyContent: 'flex-start',

  [theme.breakpoints.down('md')]: rightButtonsCompactStyle(theme),
  '.compact > &': rightButtonsCompactStyle(theme),
}));

const TopContainer = styled('div')(() => ({
  gridArea: '2 / 4 / 3 / 5',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
}));

const TopOverlayContainer = styled('div')(() => ({
  gridArea: '2 / 2 / 3 / 7',
  display: 'flex',
  alignItems: 'stretch',
  justifyContent: 'stretch',
}));
