import { type FC, useCallback, useEffect } from 'react';

import {
  BaseLink,
  Button,
  type ButtonProps,
  Icon,
  IconButton,
  type IconButtonProps,
  LogoWide,
  PopoverMenuItem,
  PopoverMenuTriggerIcon,
  Responsive,
  Typography,
  VisuallyHidden,
  styled,
  withPopoverMenu,
} from '@cofenster/web-components';

import { useDialogs } from '../../../context/dialogs/useDialogs';
import { useTracking } from '../../../context/tracking';
import { useI18n } from '../../../i18n';
import { RouterLink } from '../../controls';

// 1. Separate the left-side inner-container from the right-side one
const Wrapper = styled('header')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between', // 1
  backgroundColor: theme.palette.brand.carbon,
  color: theme.palette.brand.white,
  padding: theme.spacing(1, 2),

  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(1, 4),
  },
}));

// 1. Restore the flex containment for children of these inner-containers
const SubWrapper = styled('div')(() => ({
  display: 'flex', // 1
}));

const SkipLink = styled(BaseLink)(({ theme }) => ({
  display: 'block',
  padding: theme.spacing(1.5),

  borderRadius: theme.shape.borderRadius,
  '&:focus': theme.mixins.focusRing,

  '&:not(:focus)': {
    opacity: 0,
    pointerEvents: 'none',
  },
}));

const LanguageButtonMobile: FC<Partial<IconButtonProps>> = (props) => (
  <PopoverMenuTriggerIcon icon="TranslateIcon" iconColor="white" data-testid="language-dropdown-button" {...props} />
);

const LanguageButtonDesktop: FC<ButtonProps> = (props) => {
  const { locale } = useI18n();

  return (
    <Button
      variant="tertiary"
      startIcon={<Icon size="s" type="TranslateIcon" />}
      data-testid="language-dropdown-button"
      {...props}
    >
      {locale === 'EN' ? 'English' : locale === 'DE' ? 'Deutsch' : 'i18n.global.popover.toggle'}
    </Button>
  );
};

export type PageHeaderProps = {
  code?: string | undefined;
  qrcode?: string | undefined;
  trackingDetails?: Record<string, unknown>;
};

export const PageHeader: FC<PageHeaderProps> = ({ code, qrcode, trackingDetails }) => {
  const { openDialog } = useDialogs();
  const tracking = useTracking();
  const openInApp = useCallback(() => {
    if (code && qrcode) openDialog('OpenInAppDialog', { code, qrcode });
    tracking.trackEvent({ event: 'openInMobileOpened', details: trackingDetails });
  }, [openDialog, code, qrcode, trackingDetails, tracking]);
  const { locale, setLocale } = useI18n();

  useEffect(() => {
    document.documentElement.setAttribute('lang', locale.toLowerCase());
  }, [locale]);

  const languageItems = (
    <>
      <PopoverMenuItem
        onClick={() => setLocale('EN')}
        aria-pressed={locale === 'EN'}
        color={locale === 'EN' ? 'blue' : undefined}
        lang="en"
        data-testid="language-button"
      >
        English
      </PopoverMenuItem>
      <PopoverMenuItem
        onClick={() => setLocale('DE')}
        aria-pressed={locale === 'DE'}
        color={locale === 'DE' ? 'blue' : undefined}
        lang="de"
        data-testid="language-button"
      >
        Deutsch
      </PopoverMenuItem>
    </>
  );

  const LanguagePickerMobile = withPopoverMenu(LanguageButtonMobile, { children: languageItems });
  const LanguagePickerDesktop = withPopoverMenu(LanguageButtonDesktop, { children: languageItems });

  return (
    <Wrapper>
      <SubWrapper>
        <RouterLink to="home">
          <LogoWide color="white" size={40} />
          <VisuallyHidden>Cofenster</VisuallyHidden>
        </RouterLink>
        <Responsive up="md">
          <SkipLink href="#main">
            <Typography variant="l">i18n.global.skipNavigation</Typography>
          </SkipLink>
        </Responsive>
      </SubWrapper>
      <SubWrapper>
        <Responsive up="md">
          <LanguagePickerDesktop />
          {code && qrcode && (
            <Button variant="tertiary" startIcon={<Icon size="s" type="MobileIcon" />} onClick={openInApp}>
              i18n.header.openInApp
            </Button>
          )}
        </Responsive>
        <Responsive down="md">
          <LanguagePickerMobile />
          {code && qrcode && (
            <IconButton onClick={openInApp} icon="MobileIcon" iconColor="white" label="i18n.header.openInApp" />
          )}
        </Responsive>
      </SubWrapper>
    </Wrapper>
  );
};
