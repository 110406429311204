import { type Reducer, useEffect, useReducer } from 'react';

export type PromiseState<T, E = unknown> = Readonly<{ value: T | null; pending: boolean; error: E | null }>;
type PromiseNew = { type: 'PROMISE_NEW' };
type PromiseResolve<T> = { type: 'PROMISE_SUCCESS'; value: T };
type PromiseReject<E = unknown> = { type: 'PROMISE_ERROR'; error: E };
type PromiseClear = { type: 'PROMISE_CLEAR' };
type PromiseAction<T, E = unknown> = PromiseNew | PromiseResolve<T> | PromiseReject<E> | PromiseClear;

const INITIAL_STATE = { value: null, pending: false, error: null };

function reducer<T>(_state: PromiseState<T>, action: PromiseAction<T>): PromiseState<T> {
  switch (action.type) {
    case 'PROMISE_NEW':
      return { value: null, pending: true, error: null };
    case 'PROMISE_SUCCESS':
      return { value: action.value, pending: false, error: null };
    case 'PROMISE_ERROR':
      return { value: null, pending: false, error: action.error };
    case 'PROMISE_CLEAR':
      return INITIAL_STATE;
  }
}
export const usePromise = <T>(promise: Promise<T> | null) => {
  const [state, dispatch] = useReducer<Reducer<PromiseState<T>, PromiseAction<T>>>(reducer, INITIAL_STATE);

  useEffect(() => {
    if (promise) {
      let cancelled = false;
      dispatch({ type: 'PROMISE_NEW' });
      promise
        .then((value) => !cancelled && dispatch({ type: 'PROMISE_SUCCESS', value }))
        .catch((error) => !cancelled && dispatch({ type: 'PROMISE_ERROR', error }));
      return () => {
        cancelled = true;
      };
    }
    dispatch({ type: 'PROMISE_CLEAR' });
  }, [promise]);

  return state;
};
