import { isNativeCaptureSupported } from '@cofenster/web-components';
import type { CaptureType } from './CaptureAssetLifecycleFlow';

export const getRequiredPermissionsType = (type: CaptureType) => {
  const nativeCaptureSupported = isNativeCaptureSupported();

  // The `camera` permission is sufficient for an image, but we want to avoid asking for permissions
  // twice when there is both an `image` and a `video` request in the same list, and the `image`
  // was contributed first.
  if (type === 'image') return 'both';
  if (type === 'camera') return 'both';
  if (type === 'screenRecording' && nativeCaptureSupported) return 'none';
  if (type === 'screenRecording' && !nativeCaptureSupported) return 'microphone';
  return 'both';
};
