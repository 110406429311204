import type { FC, PropsWithChildren } from 'react';

import { I18nProvider as BaseI18nProvider } from '@cofenster/web-components';

import { TRANSLATIONS } from './translations';

export const I18nProvider: FC<PropsWithChildren<Record<string, unknown>>> = ({ children }) => {
  const initialLocale = navigator.language.split('-')[0]?.toUpperCase();

  return (
    <BaseI18nProvider translations={TRANSLATIONS} userLocale={initialLocale} defaultLocale="EN">
      {children}
    </BaseI18nProvider>
  );
};
