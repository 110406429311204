import { type FC, type PropsWithChildren, type ReactElement, useCallback, useMemo } from 'react';

import {
  GridContainer,
  GridItem,
  Icon,
  type IconType,
  LoadingSpinner,
  getUploadHint,
  styled,
  useNativeCaptureSupported,
} from '@cofenster/web-components';

import { useCaptureAssetCandidateFileContext } from '../../../context/captureAssetFile';
import type { InternalPermissionState } from '../../../hooks/usePermissions';
import { useI18n } from '../../../i18n';
import { AssetDeviceNoPermissions } from '../../controls';
import type { VideoCaptureType } from '../CaptureVideo/VideoCaptureType';
import { recordingPageComponentsZIndexes } from '../recordingPageComponentsZIndexes';

const FullScreenLoadingContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  inset: 0,
  zIndex: recordingPageComponentsZIndexes.fullScreenLoadingContainer,
  backgroundColor: theme.palette.brand.linen,
}));

const InTextIcon = styled(Icon)(() => ({
  verticalAlign: 'middle',
  marginRight: '0.5ch',
}));

const Link = styled('a')(({ theme }) => ({
  position: 'relative',
  zIndex: theme.zIndex.above,
  color: theme.palette.brand.blue,
  textDecoration: 'underline',
}));

const sniffBrowserFamily = () => {
  if (navigator.userAgent.includes('Opera') || navigator.userAgent.includes('OPR')) return 'Opera';
  if (navigator.userAgent.includes('Edg')) return 'Edge';
  if (navigator.userAgent.includes('Chrome')) return 'Chrome';
  if (navigator.userAgent.includes('Safari')) return 'Safari';
  if (navigator.userAgent.includes('Firefox')) return 'Firefox';
  return undefined;
};

const BrowserPermissionsLink: FC<PropsWithChildren> = ({ children }) => {
  const links = {
    Edge: 'https://support.microsoft.com/en-us/microsoft-edge/location-and-privacy-in-microsoft-edge-31b5d154-0b1b-90ef-e389-7c7d4ffe7b04',
    Chrome: 'https://support.google.com/chrome/answer/114662?hl=en&co=GENIE.Platform%3DDesktop',
    Firefox: 'https://support.mozilla.org/en-US/kb/how-manage-your-camera-and-microphone-permissions',
    Safari: 'https://support.apple.com/en-gb/guide/safari/ibrwe2159f50/mac',
    Opera: 'https://help.opera.com/en/latest/web-preferences/',
  };
  const browser = sniffBrowserFamily();

  if (!browser || !(browser in links)) {
    return <>{children}</>;
  }

  return (
    <>
      <InTextIcon size="s" type="LockIcon" />
      <Link href={links[browser]} target="_blank" onClick={(event) => event.stopPropagation()}>
        {children}
      </Link>
    </>
  );
};

export const CallToActionRequiredWithDropzone: FC<{
  callToAction?: () => unknown;
  type: VideoCaptureType | 'screenRecordingStart' | 'image';
  permissionStatus?: InternalPermissionState;
}> = ({ callToAction, permissionStatus, type }) => {
  const isMobileDevice = useNativeCaptureSupported();
  const { onCaptureAssetReadyForReview } = useCaptureAssetCandidateFileContext();
  const onFile = useCallback(
    (file: File) => {
      onCaptureAssetReadyForReview(
        {
          blob: file,
          url: URL.createObjectURL(file),
        },
        { uploadSource: isMobileDevice ? 'mobile-library' : 'desktop-library' }
      );
    },
    [onCaptureAssetReadyForReview, isMobileDevice]
  );

  const { translate } = useI18n();

  const options: Record<
    VideoCaptureType | 'screenRecordingStart' | 'image',
    {
      title: string;
      icon?: IconType;
      description: ReactElement | string;
      callToActionText: string;
      callToActionIcon?: IconType;
    }
  > = useMemo(
    () => ({
      camera: {
        title: 'i18n.ScenePage.InitialScreen.camera.title',
        icon: callToAction ? 'CameraSlashIcon' : 'VideoCameraWithLocker',
        description: callToAction
          ? 'i18n.ScenePage.InitialScreen.camera.description.callToAction.title'
          : translate('ScenePage.InitialScreen.camera.description.callToAction.description', {
              link: (chunks) => <BrowserPermissionsLink>{chunks}</BrowserPermissionsLink>,
            }),
        callToActionText: 'i18n.ScenePage.InitialScreen.camera.description.callToAction.button',
      },
      screenRecording: {
        title: 'i18n.ScenePage.InitialScreen.screenRecording.title',
        icon: 'MonitorIcon',
        description: callToAction
          ? 'i18n.ScenePage.InitialScreen.screenRecording.description.callToAction.title'
          : translate('ScenePage.InitialScreen.screenRecording.description.callToAction.description', {
              link: (chunks) => <BrowserPermissionsLink>{chunks}</BrowserPermissionsLink>,
            }),
        callToActionText: 'i18n.ScenePage.InitialScreen.screenRecording.description.callToAction.button',
        callToActionIcon: 'ScreenShareIcon',
      },
      screenRecordingStart: {
        icon: 'MonitorIcon',
        title: 'i18n.ScenePage.InitialScreen.screenRecordingStart.title',
        description: 'i18n.ScenePage.InitialScreen.screenRecordingStart.description',
        callToActionText: 'i18n.ScenePage.InitialScreen.screenRecordingStart.callToAction.title',
        callToActionIcon: 'ScreenShareIcon',
      },
      image: {
        icon: callToAction ? 'CameraSlashIcon' : 'ImageIcon',
        title: callToAction ? 'i18n.Recording.ImageCapture.NoPermission.Title' : 'i18n.Recording.UI.ChooseImage',
        description: callToAction
          ? 'i18n.Recording.ImageCapture.NoPermission.Subtitle'
          : getUploadHint(translate, 'image', 'Horizontal'),
        callToActionText: 'i18n.ScenePage.InitialScreen.image.description.callToAction.button',
      },
    }),
    [callToAction, translate]
  );

  return (
    <GridContainer spacing={2} justifyContent="center">
      <GridItem xs={12}>
        {permissionStatus === 'loading' && isMobileDevice ? (
          <FullScreenLoadingContainer>
            <LoadingSpinner />
          </FullScreenLoadingContainer>
        ) : (
          <AssetDeviceNoPermissions
            permissionStatus={permissionStatus}
            startUpload={onFile}
            callToAction={callToAction}
            fileType={type === 'image' ? 'image' : 'video'}
            {...options[type]}
          />
        )}
      </GridItem>
    </GridContainer>
  );
};
