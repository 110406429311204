import type { FragmentShader, ShaderAttributes, VertexShader } from '.';
import { createTexture } from './createTexture';

/**
 * SimpleProgram is a wrapper around WebGLProgram
 * aimed at drawing a texture(s) in 2d to the screen
 */
export class SimpleProgram<
  V extends VertexShader,
  F extends FragmentShader,
  UT extends F['uniforms']['textures'][number],
  UF extends F['uniforms']['floats'][number],
> {
  readonly program: WebGLProgram;
  readonly vertexShader: WebGLShader;
  readonly fragmentShader: WebGLShader;
  readonly vertexArray: WebGLVertexArrayObject;
  readonly attributes: Record<keyof ShaderAttributes, GLint>;
  readonly floatUniforms: Record<UF, WebGLUniformLocation>;
  readonly textureUniforms: Record<UT, WebGLUniformLocation>;
  readonly textures: Record<UT, WebGLTexture | null>;

  constructor(
    public readonly gl: WebGL2RenderingContext,
    vertexShader: V,
    fragmentShader: F,
    textures: Partial<Record<UT, WebGLTexture | null>> = {},
    floats: Partial<Record<UF, number>> = {}
  ) {
    const program = gl.createProgram();
    if (!program) throw new Error('Failed to create shader program');
    this.program = program;

    const vShader = vertexShader.init(gl);
    this.vertexShader = vShader;
    const fShader = fragmentShader.init(gl);
    this.fragmentShader = fShader;

    gl.attachShader(program, vShader);
    gl.attachShader(program, fShader);

    gl.linkProgram(program);
    if (!gl.getProgramParameter(program, gl.LINK_STATUS)) {
      console.error(gl.getProgramInfoLog(program));
      throw new Error('Failed to link program');
    }

    this.attributes = this._setupAttributes();
    this.floatUniforms = this._setupFloatUniforms(fragmentShader.uniforms);
    this.textureUniforms = this._setupTextureUniforms(fragmentShader.uniforms);
    this.textures = Object.fromEntries(Object.keys(this.textureUniforms).map((name) => [name, null])) as Record<
      UT,
      null
    >;

    this.vertexArray = this._setupVertexArray();

    Object.entries(textures).forEach(([name, value]) => this.setTexture(name as UT, value as WebGLTexture | null));
    this.setFloats(floats);
  }

  public paint() {
    this._prepaint();
    this._paint();
    this._postpaint();
  }

  public setFloats(values: Partial<Record<UF, number>>) {
    // biome-ignore lint/correctness/useHookAtTopLevel: it's not a hook
    this.gl.useProgram(this.program);
    Object.entries(values).forEach(([name, value]) => {
      this.gl.uniform1f(this.floatUniforms[name as UF], value as number);
    });
    // biome-ignore lint/correctness/useHookAtTopLevel: it's not a hook
    this.gl.useProgram(null);
  }

  public setTexture(name: UT, value: WebGLTexture | null) {
    this.textures[name] = value;
  }

  public setTextureImage(texture: UT, source: TexImageSource) {
    if (!this.textures[texture]) this.textures[texture] = createTexture(this.gl, source);
    else {
      const gl = this.gl;
      gl.bindTexture(gl.TEXTURE_2D, this.textures[texture]);
      gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, source);
      gl.bindTexture(gl.TEXTURE_2D, null);
    }
  }

  public destroy() {
    const gl = this.gl;
    gl.deleteProgram(this.program);
    gl.deleteShader(this.vertexShader);
    gl.deleteShader(this.fragmentShader);
    gl.deleteVertexArray(this.vertexArray);
    Object.values(this.textures).forEach((texture) => {
      if (texture) gl.deleteTexture(texture);
    });
  }

  /**
   * attributes are values that differ per vertex
   * we use only position and texture coordinates
   */
  protected _setupAttributes() {
    const attributes = {
      aTex: this.gl.getAttribLocation(this.program, 'aTex'),
      aVertex: this.gl.getAttribLocation(this.program, 'aVertex'),
    };

    if (Object.values(attributes).some((location) => location === -1))
      throw new Error('Failed to get attribute location');
    return attributes;
  }

  /**
   * uniforms are values that are the same for all vertices
   * we use only textures and floats
   */
  protected _setupFloatUniforms(uniforms: F['uniforms']) {
    return Object.fromEntries(
      uniforms.floats.map((name) => {
        const location = this.gl.getUniformLocation(this.program, name);
        if (!location) throw new Error('Failed to get uniform location');
        return [name, location];
      })
    ) as Record<UF, WebGLUniformLocation>;
  }

  /**
   * uniforms are values that are the same for all vertices
   * we need uniforms to map texture names to their locations
   */
  protected _setupTextureUniforms(uniforms: F['uniforms']) {
    const gl = this.gl;

    const textureUniforms = Object.fromEntries(
      uniforms.textures.map((name) => {
        const location = gl.getUniformLocation(this.program, name);
        if (!location) throw new Error('Failed to get uniform location');
        return [name, location];
      })
    ) as Record<UT, WebGLUniformLocation>;

    // biome-ignore lint/correctness/useHookAtTopLevel: it's not a hook
    gl.useProgram(this.program);

    Object.values(textureUniforms).forEach((location, index) => {
      gl.uniform1i(location as WebGLUniformLocation, index);
    });
    // biome-ignore lint/correctness/useHookAtTopLevel: it's not a hook
    gl.useProgram(null);

    return textureUniforms;
  }

  /**
   * this setups geometry for the program
   * it's very basic, just fills the screen/framebuffer with a texture
   */
  protected _setupVertexArray() {
    const gl = this.gl;
    const vertexArray = gl.createVertexArray();
    if (!vertexArray) throw new Error('Failed to create vertex array');
    gl.bindVertexArray(vertexArray);

    const aVertexLocation = this.attributes.aVertex;
    const vertexBuffer = gl.createBuffer();
    if (!vertexBuffer) throw new Error('Failed to create vertex buffer');
    gl.bindBuffer(gl.ARRAY_BUFFER, vertexBuffer);
    gl.enableVertexAttribArray(aVertexLocation);
    gl.vertexAttribPointer(aVertexLocation, 2, gl.FLOAT, false, 0, 0);
    gl.bufferData(gl.ARRAY_BUFFER, new Float32Array([-1, -1, -1, 1, 1, 1, 1, -1]), gl.STATIC_DRAW);

    const aTexLocation = this.attributes.aTex;
    const textureBuffer = gl.createBuffer();
    if (!textureBuffer) throw new Error('Failed to create texture buffer');
    gl.bindBuffer(gl.ARRAY_BUFFER, textureBuffer);
    gl.enableVertexAttribArray(aTexLocation);
    gl.vertexAttribPointer(aTexLocation, 2, gl.FLOAT, false, 0, 0);
    gl.bufferData(gl.ARRAY_BUFFER, new Float32Array([0, 1, 0, 0, 1, 0, 1, 1]), gl.STATIC_DRAW);
    gl.bindBuffer(gl.ARRAY_BUFFER, null);

    gl.bindVertexArray(null);

    return vertexArray;
  }

  protected _prepaint() {
    const gl = this.gl;
    // biome-ignore lint/correctness/useHookAtTopLevel: it's not a hook
    gl.useProgram(this.program);
    gl.bindVertexArray(this.vertexArray);

    Object.values(this.textures).forEach((texture, index) => {
      gl.activeTexture(gl.TEXTURE0 + index);
      gl.bindTexture(gl.TEXTURE_2D, texture as WebGLTexture);
    });
  }

  protected _paint() {
    const gl = this.gl;
    gl.clearColor(0, 0, 0, 0);
    gl.clear(gl.COLOR_BUFFER_BIT);
    gl.drawArrays(gl.TRIANGLE_FAN, 0, 4);
  }

  protected _postpaint() {
    const gl = this.gl;
    Object.values(this.textures).forEach((_, index) => {
      gl.activeTexture(gl.TEXTURE0 + index);
      gl.bindTexture(gl.TEXTURE_2D, null);
    });

    gl.bindVertexArray(null);
    // biome-ignore lint/correctness/useHookAtTopLevel: it's not a hook
    gl.useProgram(null);
  }
}
