import type { ComponentProps, FC } from 'react';

import { MediaProviderVideo, preventForwardProps, styled } from '@cofenster/web-components';

const CoverVideo = styled(
  MediaProviderVideo,
  preventForwardProps(['mirror'])
)<{ mirror?: boolean }>(({ mirror = false }) => ({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  transform: mirror ? 'scale(-1, 1)' : undefined,
}));

type PreviewStreamProps = {
  stream: MediaStream | null;
} & Omit<ComponentProps<typeof CoverVideo>, 'srcObject' | 'autoPlay' | 'controls' | 'muted' | 'playsInline'>;

export const PreviewStream: FC<PreviewStreamProps> = ({ stream, ...props }) => {
  return <CoverVideo srcObject={stream} autoPlay muted playsInline controls={false} {...props} />;
};
