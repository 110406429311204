import { useCallback } from 'react';

import { type SigninActorByTokenMutationVariables, useSigninActorByTokenMutation } from '../../generated';

export const useSigninActorByToken = () => {
  const [signinActorByToken] = useSigninActorByTokenMutation();
  return useCallback(
    (token: SigninActorByTokenMutationVariables['token']) =>
      signinActorByToken({ variables: { token } }).then((res) => res.data?.signinActorByToken),
    [signinActorByToken]
  );
};
