import { useContext } from 'react';
import { SelectedAssetIdContext } from './SelectedAssetIdContext';

export const useSelectedAssetId = () => {
  const context = useContext(SelectedAssetIdContext);

  if (!context) throw new Error('useSelectedAssetId must be used within a SelectedAssetIdProvider');

  return context;
};
