import { styled } from '@mui/material';
import type { FC } from 'react';

import type { PiPAnchor, PiPSize } from '../../../../hooks/media/useCanvasPainter';
import type { UseMediaDevicesDevice } from '../../../../hooks/media/useMediaDevices';
import { Icon } from '../../../assets/icons';
import { Typography } from '../../../typography/Typography';
import {
  BlurredContainer,
  PauseResumeButton,
  RecordButton,
  RecordingLayout,
  RetakeButton,
  SelectDesktopButton,
  SelectMicrophone,
  TimeRecordingIndicator,
} from '../components';
import type { SelectedMediaDevice } from '../components/MicrophonePopOver';
import { PiPCameraDialog } from '../components/PiPCameraDialog';
import { DeleteButton } from '../components/ui/DeleteButton';
import { MuteUnmuteButton } from '../components/ui/MuteUnmuteButton';
import { NoAudioTooltip } from '../components/ui/NoAudioTooltip';
import { SystemAudioMutedTooltip } from '../components/ui/SystemAudioMutedTooltip';

export const ScreenRecordingBar: FC<{
  isCompact?: boolean;
  status: 'inactive' | 'recording' | 'paused';

  recordingDuration?: number;

  onStart?: () => unknown;
  onStop?: () => unknown;
  onPause?: () => unknown;
  onResume?: () => unknown;

  availableMicrophones?: UseMediaDevicesDevice[];
  selectedMicrophone?: SelectedMediaDevice;
  selectMicrophone?: (device: SelectedMediaDevice) => unknown;

  muted?: boolean;
  setMuted?: (muted: boolean) => unknown;

  availableCameras?: UseMediaDevicesDevice[];
  selectedCamera?: SelectedMediaDevice;
  selectCamera?: (device: SelectedMediaDevice) => unknown;

  selectedDesktop?: 'browser' | 'monitor' | 'window' | null;
  selectDesktop: () => unknown;

  onDelete?: () => unknown;
  onRetake?: () => unknown;

  pipAnchor?: PiPAnchor;
  pipSize?: PiPSize;
  setPipAnchor?: (anchor: PiPAnchor) => void;
  setPipSize?: (size: PiPSize) => void;
}> = ({
  isCompact = false,
  status,
  recordingDuration,
  onStart,
  onStop,
  onPause,
  onResume,
  availableMicrophones,
  selectedMicrophone,
  selectMicrophone,
  muted = false,
  setMuted,
  availableCameras,
  selectedCamera,
  selectCamera,
  selectedDesktop = null,
  selectDesktop,
  onDelete,
  onRetake,
  pipAnchor,
  pipSize,
  setPipAnchor,
  setPipSize,
}) => {
  const isRecording = status === 'recording';
  const isPaused = status === 'paused';
  const isInactive = status === 'inactive';

  // If the stream is paused, it means it was started to begin with. Therefore,
  // pressing the record button should end the recording to allow ending from a
  // paused state. If the stream is not paused, whether the record button should
  // start recording or stop recording depends on whether it is recording yet.
  const onRecord = isPaused ? onStop : isRecording ? onStop : onStart;
  const onPauseResume = isPaused ? onResume : onPause;

  return (
    <RecordingLayout
      isCompact={isCompact}
      mainButton={onRecord && <RecordButton onRecord={onRecord} isRecording={isRecording} />}
      leftButtons={
        <>
          {isInactive && selectMicrophone && (
            <SystemAudioMutedTooltip openOnHover>
              {({ isOpen, close }) => (
                <SelectMicrophone
                  devices={availableMicrophones}
                  onSelectDevice={selectMicrophone}
                  selectedDevice={selectedMicrophone}
                  disabled={false}
                  disableHoverListener={isOpen}
                  onClick={close}
                />
              )}
            </SystemAudioMutedTooltip>
          )}
          {isInactive && selectCamera && (
            <PiPCameraDialog
              devices={availableCameras}
              onSelectDevice={selectCamera}
              selectedDevice={selectedCamera}
              allowSelectNone={true}
              disabled={false}
              pipAnchor={pipAnchor}
              pipSize={pipSize}
              setPipAnchor={setPipAnchor}
              setPipSize={setPipSize}
            />
          )}
          {isInactive && <SelectDesktopButton onSelectDesktop={selectDesktop} />}
          {!isInactive && setMuted && (
            <NoAudioTooltip isMuted={muted}>
              <SystemAudioMutedTooltip openOnHover>
                {({ isOpen, isSystemMuted }) => (
                  <MuteUnmuteButton
                    muted={muted || isSystemMuted}
                    onMuteUnmute={setMuted}
                    disabled={isSystemMuted}
                    disableHoverListener={isOpen}
                  />
                )}
              </SystemAudioMutedTooltip>
            </NoAudioTooltip>
          )}
          {!isInactive && onPauseResume && <PauseResumeButton onPauseResume={onPauseResume} status={status} />}
        </>
      }
      rightButtons={
        <>
          {!isInactive && onRetake && <RetakeButton onRetake={onRetake} />}
          {!isInactive && onDelete && <DeleteButton onDelete={onDelete} />}
        </>
      }
      top={
        typeof recordingDuration !== 'undefined' &&
        recordingDuration > 0 && (
          <TimeRecordingIndicator recordingDuration={recordingDuration} isRecording={isRecording} />
        )
      }
      topOverlay={isInactive && selectedDesktop && <PickedDesktop desktop={selectedDesktop} />}
    />
  );
};

const PickedDesktopContainer = styled(BlurredContainer)(({ theme }) => ({
  flex: 1,
  gap: theme.spacing(1),
  color: theme.palette.brand.white,
  display: 'grid',
  gridTemplateColumns: '32px 1fr',
  gridTemplateRows: '1fr 1fr',
  gridTemplateAreas: '"Icon Title" "Icon Subtitle"',
  '& > :nth-child(1)': { gridArea: 'Icon' },
  '& > :nth-child(2)': { gridArea: 'Title' },
  '& > :nth-child(3)': { gridArea: 'Subtitle' },
}));

const PickedDesktop: FC<{ desktop: 'browser' | 'monitor' | 'window' }> = ({ desktop }) => (
  <PickedDesktopContainer data-testid="picked-desktop-message">
    <Icon type="ScreenShareIcon" />
    <Typography variant="h6" i18nParams={{ desktop }}>
      i18n.Recording.ScreenCapture.Title
    </Typography>
    <Typography variant="m">i18n.Recording.ScreenCapture.Subtitle</Typography>
  </PickedDesktopContainer>
);
