import { styled } from '@cofenster/web-components';
import type { FC } from 'react';
import { BottomFixedContainer } from '../controls';
import { useCaptureAssetLifecycleFlow } from './CaptureAssetLifecycleFlow';
import { FullWidthOnSmallScreenButton } from './FullWidthOnSmallScreenButton';

const ButtonsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  gap: theme.spacing(2),
  flexWrap: 'wrap',
}));

export const RetakeActions: FC = () => {
  const { onRetake } = useCaptureAssetLifecycleFlow();

  if (!onRetake) return null;

  return (
    <BottomFixedContainer>
      <ButtonsContainer>
        <FullWidthOnSmallScreenButton onClick={onRetake} variant="secondary">
          i18n.Recording.Capture.recordAnotherTake
        </FullWidthOnSmallScreenButton>
      </ButtonsContainer>
    </BottomFixedContainer>
  );
};
