import type { FC } from 'react';

import { GridContainer, GridItem, Text, TextLink, styled, useLocalizedWebsiteUrl } from '@cofenster/web-components';

// 1. 60 px - max height of Button with two lines of text
//    theme.spacing(4) - top and bottom padding of BottomFixedContainer
const Container = styled('footer')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(3, 5),
  flexShrink: 0,
  [theme.breakpoints.up('sm')]: {
    justifyContent: 'space-between',
  },
  [theme.breakpoints.down('md')]: {
    marginBottom: `calc(60px + ${theme.spacing(4)})`, // 1
  },
}));

const StyledTextLink = styled(TextLink)({
  display: 'inline-flex',
});

const DataProtectionLink: FC = () => {
  const url = useLocalizedWebsiteUrl('PRIVACY_POLICY');

  return (
    <StyledTextLink target="_blank" href={url} variant="s">
      i18n.PageFooter.dataProtection
    </StyledTextLink>
  );
};

const ImprintLink: FC = () => {
  const url = useLocalizedWebsiteUrl('IMPRINT');

  return (
    <StyledTextLink target="_blank" href={url} variant="s">
      i18n.PageFooter.imprint
    </StyledTextLink>
  );
};

const TermsAndConditionsLink: FC = () => {
  const url = useLocalizedWebsiteUrl('TERMS_OF_USE');

  return (
    <StyledTextLink target="_blank" href={url} variant="s">
      i18n.PageFooter.terms
    </StyledTextLink>
  );
};

const Copyright = () => (
  <Text variant="s" component="p">
    © cofenster GmbH {new Date().getFullYear()}.
  </Text>
);

export const PageFooter: FC = (props) => (
  <Container {...props}>
    <GridContainer spacing={1}>
      <GridItem order={{ xs: 0, md: 1 }} xs={12} md={6}>
        <GridContainer justifyContent="flex-end">
          <GridItem
            xs={12}
            md="auto"
            display="flex"
            alignItems="center"
            justifyContent={{ xs: 'center', md: 'flex-end' }}
            gap={3}
          >
            <DataProtectionLink />
            <ImprintLink />
            <TermsAndConditionsLink />
          </GridItem>
        </GridContainer>
      </GridItem>
      <GridItem
        display="flex"
        justifyContent={{ xs: 'center', md: 'flex-start' }}
        order={{ xs: 1, md: 0 }}
        xs={12}
        md={6}
      >
        <Copyright />
      </GridItem>
    </GridContainer>
  </Container>
);
