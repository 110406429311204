import type { I18nParams } from '@cofenster/web-components';

export const getTaskTitle = (
  task: { title?: string | null | undefined; instruction?: string | null | undefined } | undefined,
  translate: (key: string, values?: I18nParams) => string | undefined
) => {
  if (!task) return null;
  const { title, instruction } = task;
  if (title) return title;
  if (!title && instruction) return translate('ScenePage.emptyTitle');
  return translate('ScenePage.NoTitleAndInstructionsTitle');
};
