import { useEffect } from 'react';

import { Sentry } from '../../sentry';

export const useIsBlobEmpty = (blob: Blob) => {
  const isBlobEmpty = blob.size === 0;
  useEffect(() => {
    if (isBlobEmpty) {
      Sentry.captureException(new Error('Attempted to upload an empty blob'), { level: 'error' });
    }
  }, [isBlobEmpty]);
  return { isBlobEmpty };
};
