import { type FC, type PropsWithChildren, useMemo } from 'react';

import { useUserMediaStream } from '../../hooks/media/useUserMediaStream';
import { AudioAnalysisStreamContext } from './AudioAnalysisStreamContext';

export const AudioAnalysisStreamProvider: FC<
  PropsWithChildren<{ microphoneConstraints: DisplayMediaStreamOptions | null }>
> = ({ microphoneConstraints, children }) => {
  // Because we allow muting audio while recording by setting the enabled property
  // on audio tracks to false, we lose the ability to analyze the audio when it is muted.
  // Therefore, we need an additional stream solely for analysis in the browser.
  const { value: mediaStream } = useUserMediaStream(microphoneConstraints);
  const context = useMemo(() => ({ mediaStream }), [mediaStream]);

  return <AudioAnalysisStreamContext.Provider value={context}>{children}</AudioAnalysisStreamContext.Provider>;
};
