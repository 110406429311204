import { useMemo } from 'react';

import {
  type AddAssetToContributionRequestInput,
  useAddAssetToContributionRequestByActorMutation,
} from '../../generated';

export const useAddAssetToContributionRequestByActor = () => {
  const [addAssetToContributionRequestByActor, { loading }] = useAddAssetToContributionRequestByActorMutation({
    refetchQueries: ['ContributionRequestListByActor'],
  });

  return useMemo(
    () => ({
      addAssetToContributionRequestByActor: (
        contributionRequestId: string,
        input: AddAssetToContributionRequestInput
      ) =>
        addAssetToContributionRequestByActor({
          variables: {
            contributionRequestId,
            input,
          },
        }),
      loading,
    }),
    [addAssetToContributionRequestByActor, loading]
  );
};
