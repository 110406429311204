import type { FC } from 'react';

import { GridContainer, GridItem, Icon, styled } from '@cofenster/web-components';

import { BottomFixedContainer, UploadRetakeVideoMessage } from '../../../../controls';
import { FullWidthOnSmallScreenButton } from '../../../FullWidthOnSmallScreenButton';

const ButtonsContainer = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

export const ReviewRecordingInfoAndActions: FC<{
  clearRecording: VoidFunction;
  onSubmit: VoidFunction | undefined;
  showUploadRetakeMessage?: boolean;
}> = ({ clearRecording, onSubmit, showUploadRetakeMessage }) => {
  return (
    <>
      <BottomFixedContainer>
        <ButtonsContainer>
          <FullWidthOnSmallScreenButton
            variant="secondary"
            startIcon={<Icon size="s" type="ArrowClockwiseIcon" />}
            onClick={clearRecording}
            data-testid="retake-button"
          >
            i18n.ScenePage.ReviewRecording.cancelButton
          </FullWidthOnSmallScreenButton>

          {onSubmit && (
            <FullWidthOnSmallScreenButton onClick={onSubmit} data-testid="submit-button">
              i18n.ScenePage.ReviewRecording.saveButton
            </FullWidthOnSmallScreenButton>
          )}
        </ButtonsContainer>
      </BottomFixedContainer>
      {showUploadRetakeMessage && (
        <GridContainer>
          <GridItem xs={12}>
            <UploadRetakeVideoMessage />
          </GridItem>
        </GridContainer>
      )}
    </>
  );
};
