import type { FC } from 'react';

import { Button, Icon, IconButton, preventForwardProps, styled } from '@cofenster/web-components';

import { recordingPageComponentsZIndexes } from '../../../../recordingPageComponentsZIndexes';

const HeaderContainer = styled(
  'div',
  preventForwardProps(['orientation'])
)<{ orientation: 'portrait' | 'landscape' }>(({ theme, orientation }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,

  padding: theme.spacing(2),

  display: 'flex',
  flexDirection: orientation === 'landscape' ? 'row-reverse' : undefined,
  justifyContent: 'space-between',

  zIndex: recordingPageComponentsZIndexes.recordingBarControls,
}));

const EmptyIconPlaceholder = styled('div')(() => ({
  width: 48,
  height: 48,
}));

export type TopBarProps = {
  onClose?: () => unknown;
  onOpenInstructions?: () => unknown;
  orientation: 'portrait' | 'landscape';
};

const CloseIconButton = styled(IconButton)(() => ({
  filter: 'drop-shadow(0px 0px 3px rgb(0 0 0 / 0.5))',
}));

const InstructionsButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.brand.carbon_alpha30,
  backdropFilter: 'blur(6.5px)',
  color: theme.palette.brand.white,
  height: 40,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),

  '&:focus-visible': theme.mixins.focusRing,

  '&.Mui-disabled': {
    cursor: 'disabled',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.brand.grey300,
  },
}));

export const TopBar: FC<TopBarProps> = ({ onClose, onOpenInstructions, orientation }) => (
  <HeaderContainer orientation={orientation}>
    <EmptyIconPlaceholder />
    {onOpenInstructions && (
      <InstructionsButton
        startIcon={<Icon type="ClipboardIcon" color="white" size="s" />}
        variant="blurred"
        onClick={onOpenInstructions}
      >
        i18n.ScenePage.MobileWebcamRecorder.instructionsButton
      </InstructionsButton>
    )}
    {onClose && (
      <CloseIconButton
        icon="CloseIcon"
        iconSize="l"
        onClick={onClose}
        iconColor="white"
        label="i18n.global.cancel"
        hoverColor="white"
        backgroundColor="blurred"
      />
    )}
  </HeaderContainer>
);
