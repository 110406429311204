import { createContext } from 'react';

export type ClientState =
  | { status: 'UNAUTHENTICATED'; token: undefined }
  | { status: 'ERROR'; token: undefined; contributionType: null }
  | {
      status: 'AUTHENTICATED';
      token: string;
    };
export type ClientAction = { type: 'AUTHENTICATE'; token: string } | { type: 'FAIL' };

export type ClientContext = {
  state: ClientState;
  authenticate: (token: string) => void;
  fail: (reason: string, token?: string) => void;
};

export const ClientContext = createContext<ClientContext | undefined>(undefined);
