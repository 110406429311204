import type { FC } from 'react';
import { ContributionRequestLayout } from '../../components/layout/ContributionRequestLayout';
import { ContributionRequestListView } from './ContributionRequestListView';

export const ContributionRequestListPage: FC = () => {
  return (
    <ContributionRequestLayout>
      {(details) => <ContributionRequestListView project={details.project} list={details.contributionRequestList} />}
    </ContributionRequestLayout>
  );
};
