import type { FC } from 'react';

import { CircleProgressIcon, Headline, Icon, Spacing, Typography, styled } from '@cofenster/web-components';

import type { ContributionRequestListByActor } from '../../api/hooks/contributionRequestList/useContributionRequestListByActor';
import { useTasksProgress } from '../../context/tasksProgress';

import { NextTaskButton } from '../../components/capture/NextTaskButton';
import { AllDone } from './AllDone';
import { TaskList } from './TaskList';
import { VideoBriefing } from './VideoBriefing';

type Project = ContributionRequestListByActor['project'];
type ContributionRequestList = ContributionRequestListByActor['contributionRequestList'];

const Header = styled('div')(({ theme }) => ({
  margin: theme.spacing(2, 0),
  display: 'grid',
  gridTemplateColumns: 'auto 1fr auto',
  gridTemplateRows: 'auto auto',
  columnGap: theme.spacing(2),
  gridTemplateAreas: `'briefing title progress' 'briefing description progress'`,

  [theme.breakpoints.down('md')]: {
    margin: theme.spacing(2, 0, 0, 0),
  },

  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: 'auto',
    gridTemplateAreas: "'title title' 'description description' 'briefing progress'",
    rowGap: theme.spacing(1),
  },
}));

const TaskProgressContainer = styled('div')(({ theme }) => ({
  gridArea: 'progress',
  alignSelf: 'center',
  justifySelf: 'end',
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const TasksProgress: FC = () => {
  const { doneCount, totalCount } = useTasksProgress();

  if (totalCount === 0) return null;
  if (doneCount === totalCount) {
    return (
      <TaskProgressContainer>
        <Icon type="CheckIcon" background={{ size: 'm', color: 'blue' }} color="white" size="s" />
        <Typography component="p" variant="m">
          i18n.ContributionRequestListPage.tasksProgress.allDone
        </Typography>
      </TaskProgressContainer>
    );
  }
  return (
    <TaskProgressContainer>
      <CircleProgressIcon value={doneCount} max={totalCount} trackColor="linen50" size="m" />
      <Typography component="p" variant="m" i18nParams={{ done: doneCount, total: totalCount }}>
        i18n.ContributionRequestListPage.tasksProgress.progress
      </Typography>

      <Spacing left={4} property="padding">
        <NextTaskButton />
      </Spacing>
    </TaskProgressContainer>
  );
};

const Title = styled(Headline)(() => ({
  gridArea: 'title',
}));

const Description = styled(Typography)(() => ({
  gridArea: 'description',
}));

const StyledHR = styled('hr')(({ theme }) => ({
  height: 1,
  border: 0,
  backgroundColor: theme.palette.brand.linen50,
  width: '100%',
  margin: theme.spacing(0, 0, 2, 0),
  [theme.breakpoints.down('md')]: {
    margin: 0,
  },
}));

export const ContributionRequestListView: FC<{ project: Project; list: ContributionRequestList }> = ({
  project,
  list,
}) => {
  const hasBriefing = list.briefing.enabled && list.briefing.video?.videoUrl;
  const { doneCount, totalCount } = useTasksProgress();
  const isAllDone = doneCount === totalCount;

  return (
    <>
      {isAllDone ? (
        <AllDone projectName={project.name} projectOwner={project.creator?.name ?? ''} />
      ) : (
        <Header>
          {hasBriefing && <VideoBriefing video={list.briefing.video} />}
          <Title component="h1" variant="h3">
            i18n.ContributionRequestListPage.header.headline
          </Title>
          <Description
            data-testid="contribution-request-list-page-description"
            component="p"
            variant="m"
            i18nParams={{
              projectName: project.name,
              firstname: project.creator?.firstname,
              lastname: project.creator?.lastname,
            }}
          >
            i18n.ContributionRequestListPage.header.subline
          </Description>
          <TasksProgress />
        </Header>
      )}
      <StyledHR />
      <TaskList requests={list.requests} />
    </>
  );
};
