import { SimpleProgram } from './SimpleProgram';
import { createFragmentShader } from './createFragmentShader';
import { createTexture } from './createTexture';
import { SIMPLE_VERTEX_SHADER } from './createVertexShader';

export const createTwoPassBlur = (gl: WebGL2RenderingContext, input: WebGLTexture = createTexture(gl)) => {
  const framebuffer = gl.createFramebuffer();
  if (!framebuffer) throw new Error('Failed to create framebuffer');

  const width = gl.canvas.width;
  const height = gl.canvas.height;

  const firstPass = createTexture(gl);
  gl.bindTexture(gl.TEXTURE_2D, firstPass);
  gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, width, height, 0, gl.RGBA, gl.UNSIGNED_BYTE, null);
  const secondPass = createTexture(gl);
  gl.bindTexture(gl.TEXTURE_2D, secondPass);
  gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, width, height, 0, gl.RGBA, gl.UNSIGNED_BYTE, null);

  const blurX = new SimpleProgram(
    gl,
    SIMPLE_VERTEX_SHADER,
    BLURX_FRAGMENT_SHADER,
    {
      texture: input,
    },
    {
      width: width,
    }
  );

  const blurY = new SimpleProgram(
    gl,
    SIMPLE_VERTEX_SHADER,
    BLURY_FRAGMENT_SHADER,
    {
      texture: firstPass,
    },
    { height: height }
  );

  const paintToTexture = (source: TexImageSource) => {
    blurX.setTextureImage('texture', source);
    gl.bindFramebuffer(gl.FRAMEBUFFER, framebuffer);
    gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, firstPass, 0);
    blurX.paint();
    gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, secondPass, 0);
    blurY.paint();
    gl.bindFramebuffer(gl.FRAMEBUFFER, null);
  };

  const destroy = () => {
    gl.deleteFramebuffer(framebuffer);
    blurX.destroy();
    blurY.destroy();
    gl.deleteTexture(firstPass);
    gl.deleteTexture(secondPass);
  };

  return {
    texture: secondPass,
    paintToTexture,
    destroy,
  };
};

const BLURX_FRAGMENT_SHADER = createFragmentShader(
  `
precision mediump float;
uniform sampler2D texture;
uniform float width;
const float scale = 2.0;
const int samples = 21;
varying vec2 vTex;

float weight(float x) {
  float sigma = sqrt(float(samples));
  return exp(-(x * x) / (2.0 * sigma * sigma));
}

void main() {
  vec4 color = vec4(0.0);
  float accum = 0.0;
  for (int i = 0; i < samples; ++i) {
    float w = weight(float(i) - float(samples)/2.0);
    color += texture2D(texture, vTex + vec2((float(i) - float(samples)/2.0) * scale/width, 0.0)) * w;
    accum += w;
  }

  gl_FragColor = color/accum;
}
`,
  {
    textures: ['texture'],
    floats: ['width'],
  }
);

const BLURY_FRAGMENT_SHADER = createFragmentShader(
  `
precision mediump float;
uniform sampler2D texture;
uniform float height;
const float scale = 2.0;
const int samples = 21;
varying vec2 vTex;

float weight(float x) {
  float sigma = sqrt(float(samples));
  return exp(-(x * x) / (2.0 * sigma * sigma));
}

void main() {
  vec4 color = vec4(0.0);
  float accum = 0.0;
  for (int i = 0; i < samples; ++i) {
    float w = weight(float(i) - float(samples)/2.0);
    color += texture2D(texture, vTex + vec2(0.0, (float(i) - float(samples)/2.0) * scale/height)) * w;
    accum += w;
  }

  gl_FragColor = color/accum;
}
`,
  {
    textures: ['texture'],
    floats: ['height'],
  }
);
