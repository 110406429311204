import type { ComponentProps, FC, PropsWithChildren } from 'react';

import { useAutomaticTracking, useTitle } from '@cofenster/web-components';

import { useEventTracking } from '../../../api/hooks/tracking/useEventTracking';
import { Page } from '../Page';
import { PageContent } from '../PageContent';
import { PageFooter } from '../PageFooter';
import { PageHeader, type PageHeaderProps } from '../PageHeader';

export const Layout: FC<
  PropsWithChildren<
    {
      verticallyCentered?: boolean;
      title?: string;
    } & PageHeaderProps &
      ComponentProps<'div'>
  >
> = ({ children, verticallyCentered = true, trackingDetails, code, qrcode, title, ...rest }) => {
  useTitle(title ?? 'CoCapture');
  const trackEvent = useEventTracking();
  useAutomaticTracking(trackEvent);

  return (
    <Page {...rest}>
      <PageHeader trackingDetails={trackingDetails} code={code} qrcode={qrcode} />
      <PageContent verticallyCentered={verticallyCentered}>{children}</PageContent>
      <PageFooter />
    </Page>
  );
};
