import type { FC, MouseEventHandler } from 'react';

import { IconButton } from '../../../../controls/Button/IconButton';

export const DeleteButton: FC<{ onDelete: MouseEventHandler }> = ({ onDelete }) => (
  <IconButton
    onClick={onDelete}
    data-testid="delete-button"
    icon="TrashIcon"
    iconColor="white"
    backgroundColor="blurred"
    label="i18n.global.delete"
  />
);
