import { LoadingSpinner } from '@cofenster/web-components';
import type { FC, ReactNode } from 'react';
import {
  type ContributionRequestListDetail,
  ContributionRequestListDetailProvider,
} from '../../../context/contributionRequestListDetails';
import { TaskProgressProvider } from '../../../context/tasksProgress';
import { ErrorPage } from '../../../pages/ErrorPage';
import { EnsureAcceptResponses } from '../../EnsureAcceptResponses';
import { EnsureHasToken } from '../../EnsureHasToken';
import { Layout } from '../Layout';

export const ContributionRequestLayout: FC<{
  children: ReactNode | ((contributionRequestListDetails: ContributionRequestListDetail) => ReactNode);
}> = ({ children }) => {
  return (
    <EnsureHasToken>
      <ContributionRequestListDetailProvider>
        {(contributionRequestListDetails) =>
          contributionRequestListDetails?.contributionRequestList ? (
            <EnsureAcceptResponses
              projectName={contributionRequestListDetails.project.name}
              acceptResponses={contributionRequestListDetails.contributionSettings.acceptResponses}
            >
              {contributionRequestListDetails.contributionRequestList.requests.length > 0 ? (
                <Layout
                  code={contributionRequestListDetails?.contributionRequestList?.code}
                  qrcode={contributionRequestListDetails?.contributionRequestList?.qrCode}
                  verticallyCentered={!contributionRequestListDetails?.contributionRequestList}
                  title={contributionRequestListDetails?.project.name}
                >
                  <TaskProgressProvider
                    list={contributionRequestListDetails.contributionRequestList}
                    taskIds={contributionRequestListDetails.contributionRequestList.requests.map(
                      (request) => request.id
                    )}
                  >
                    {typeof children === 'function' ? children(contributionRequestListDetails) : children}
                  </TaskProgressProvider>
                </Layout>
              ) : (
                <ErrorPage
                  messages={{
                    headline: 'i18n.ErrorPage.emptyRequestList.headline',
                    subHeadline: 'i18n.ErrorPage.emptyRequestList.subHeadline',
                  }}
                />
              )}
            </EnsureAcceptResponses>
          ) : (
            <Layout>
              <LoadingSpinner />
            </Layout>
          )
        }
      </ContributionRequestListDetailProvider>
    </EnsureHasToken>
  );
};
