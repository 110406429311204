import { styled } from '@mui/material';
import type { FC, PropsWithChildren } from 'react';

import { Icon, type IconType } from '../../assets/icons/Icon';
import { BlankButton } from '../../controls/Button/BlankButton';
import { Box } from '../../layout/Box';
import { GridContainer, GridItem } from '../../layout/Grid';
import { Typography } from '../../typography/Typography';

const StyledButton = styled(BlankButton)(({ theme }) => ({
  width: '100%',
  borderRadius: theme.shape['borderRadius-l'],

  '&:hover, &:focus': {
    backgroundColor: 'rgb(10 36 44 / 0.06)',
  },

  '&:focus-visible': theme.mixins.focusRing,
}));

const MaybeButton: FC<PropsWithChildren<{ onClick?: MessageProps['onClick'] }>> = (props) => {
  if (props.onClick) {
    return <StyledButton {...props} type="button" />;
  }

  return <>{props.children}</>;
};

const WrappingBox = styled(Box)(() => ({
  overflow: 'visible',
}));

type MessageProps = {
  title: string;
  description?: string;
  actionText?: string;
  icon: IconType;
  onClick?(): void;
};

export const Message: FC<MessageProps> = ({ title, description, actionText, icon, onClick }) => {
  return (
    <WrappingBox backgroundColor="white">
      <MaybeButton onClick={onClick}>
        <GridContainer padding={2} borderRadius={2} direction="row">
          <GridItem alignSelf="center">
            <Icon size="l" type={icon} />
          </GridItem>
          <GridItem flex={1}>
            <GridContainer direction="column" spacing={1 / 4} textAlign="left">
              <GridItem>
                <Typography variant="h6" component="p">
                  {title}
                </Typography>
              </GridItem>
              {!!description && (
                <GridItem>
                  <Typography variant="m" component="p">
                    {description}
                  </Typography>
                </GridItem>
              )}
            </GridContainer>
          </GridItem>
          {!!actionText && (
            <GridItem alignSelf="center" flexBasis={{ xs: '100%', md: 'auto' }}>
              <Typography color="blue" variant="l">
                {actionText}
              </Typography>
            </GridItem>
          )}
        </GridContainer>
      </MaybeButton>
    </WrappingBox>
  );
};
