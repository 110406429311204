import type { FC } from 'react';

import { Message } from '@cofenster/web-components';

export const UploadRetakeVideoMessage: FC = () => {
  return (
    <Message
      title="i18n.UploadRetakeVideoMessage.title"
      icon="ScissorsIcon"
      description="i18n.UploadRetakeVideoMessage.description"
    />
  );
};
