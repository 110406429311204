import type { FC } from 'react';

import { GridContainer, GridItem, Text, TextLink, useLocalizedWebsiteUrl } from '@cofenster/web-components';

import { Layout, PageHeadline } from '../../components/layout';

import { SigninByCodeForm } from './SigninByCodeForm';

export const SigninByCodePage: FC = () => {
  const url = useLocalizedWebsiteUrl('HOME');

  return (
    <Layout title="i18n.SigninByCodePage.subline">
      <PageHeadline subline="i18n.SigninByCodePage.subline">i18n.SigninByCodePage.headline</PageHeadline>

      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={6} md={4}>
          <SigninByCodeForm />
        </GridItem>
      </GridContainer>

      <GridContainer justifyContent="center" marginTop={4}>
        <GridItem xs={12} sm={8} textAlign="center" component="p">
          <Text>i18n.SigninByCodePage.hint</Text>&nbsp;
          <TextLink href={url}>cofenster.com</TextLink>
        </GridItem>
      </GridContainer>
    </Layout>
  );
};
