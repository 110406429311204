import { styled } from '@mui/material';
import type { FC } from 'react';

import type { PiPSize } from '../../../../../../hooks/media/useCanvasPainter';
import { Icon } from '../../../../../assets/icons/Icon';
import { SecondaryButton } from '../../../../../controls/Button/components/SecondaryButton';
import { GridContainer, GridItem } from '../../../../../layout/Grid';
import { Typography } from '../../../../../typography/Typography';

const SectionHeader: FC<{ title: string }> = ({ title }) => <Typography variant="h6">{title}</Typography>;

const StyledButton = styled(SecondaryButton)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '10vh',
  height: '100%',
  width: '100%',
  backgroundColor: theme.palette.brand.white,

  '&[aria-pressed="true"]': {
    color: theme.palette.brand.blue,
  },

  '&, &:hover, &:focus': {
    borderWidth: 1,
  },
}));

type SizeSelectProps = {
  setPipSize: (size: PiPSize) => void;
  pipSize: PiPSize;
};

type ButtonContentProps = {
  size: 'm' | 'l' | 'xl';
  title: string;
};

const ButtonContent: FC<ButtonContentProps> = ({ size, title }) => {
  return (
    <GridContainer spacing={0} height="100%" marginBottom={1}>
      <GridItem xs={12} alignSelf="end">
        <Icon type="CircleIcon" size={size} />
      </GridItem>
      <GridItem xs={12} alignSelf="end">
        <Typography variant="l">{title}</Typography>
      </GridItem>
    </GridContainer>
  );
};

export const SizeSelect: FC<SizeSelectProps> = ({ setPipSize, pipSize }) => {
  return (
    <GridContainer spacing={1} display="flex" flexDirection="column">
      <GridItem>
        <SectionHeader title="i18n.global.pip.dialog.size" />
      </GridItem>

      <GridItem>
        <GridContainer spacing={1}>
          <GridItem xs={4} sm={4} md={4} lg={4}>
            <StyledButton onClick={() => setPipSize?.('small')} aria-pressed={pipSize === 'small'}>
              <ButtonContent size="m" title="i18n.global.pip.dialog.camera.size.small" />
            </StyledButton>
          </GridItem>

          <GridItem xs={4} sm={4} md={4} lg={4}>
            <StyledButton onClick={() => setPipSize?.('medium')} aria-pressed={pipSize === 'medium'}>
              <ButtonContent size="l" title="i18n.global.pip.dialog.camera.size.medium" />
            </StyledButton>
          </GridItem>

          <GridItem xs={4} sm={4} md={4} lg={4}>
            <StyledButton onClick={() => setPipSize?.('large')} aria-pressed={pipSize === 'large'}>
              <ButtonContent size="xl" title="i18n.global.pip.dialog.camera.size.big" />
            </StyledButton>
          </GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  );
};
