import {
  type ChangeEventHandler,
  type FC,
  type MouseEventHandler,
  type ReactNode,
  memo,
  useCallback,
  useMemo,
  useState,
} from 'react';

import { AcceptTypes, MimeTypes } from '@cofenster/constants';
import {
  Button,
  Dropzone,
  GridContainer,
  GridItem,
  Icon,
  IconButton,
  type IconType,
  Text,
  Typography,
  UploadFileButton,
  formatFileSize,
  styled,
  useNativeCaptureSupported,
} from '@cofenster/web-components';

import { useCaptureBackButtonBehavior } from '../../../components/capture/BackButtonBehavior';
import type { InternalPermissionState } from '../../../hooks/usePermissions';
import { useCaptureAssetLifecycleFlow } from '../../capture/CaptureAssetLifecycleFlow';
import { FormatAwareContentArea } from '../../layout/FormatAwareContentArea';
import { BottomFixedContainer } from '../BottomFixedContainer';

const MobileContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.brand.linen50,
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  width: '100%',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(3),
}));

export type AssetDeviceNoPermissionsProps = {
  startUpload: (file: File) => unknown;
  title: string;
  icon?: IconType;
  description: ReactNode | string;
  callToAction?: VoidFunction;
  callToActionText: string;
  callToActionIcon?: IconType;
  permissionStatus: InternalPermissionState;
  fileType: 'video' | 'image';
};

const TopRightIcon = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
  padding: theme.spacing(3),
  zIndex: theme.zIndex.overlay,
  color: theme.palette.brand.carbon,
}));

const GoBackOnMobile: FC = memo(() => {
  const nativeCaptureSupported = useNativeCaptureSupported();
  const { onClick } = useCaptureBackButtonBehavior();

  return nativeCaptureSupported ? (
    <TopRightIcon icon="CloseIcon" label="i18n.global.close" onClick={onClick} data-testid="foo" />
  ) : null;
});

GoBackOnMobile.displayName = 'GoBackOnMobile';

const ButtonsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  gap: theme.spacing(1),
}));

export const AssetDeviceNoPermissions: FC<AssetDeviceNoPermissionsProps> = ({
  startUpload,
  callToAction,
  title,
  description,
  callToActionIcon,
  callToActionText,
  icon,
  permissionStatus,
  fileType,
}) => {
  const { format: videoFormat } = useCaptureAssetLifecycleFlow();

  const [error, setError] = useState<string | undefined>(undefined);
  const handleError = useCallback((error: string) => setError(error), []);
  const handleFile = useCallback(
    (file: File) => {
      setError(undefined);
      startUpload(file);
    },
    [startUpload]
  );

  const callToActionInternal: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      callToAction?.();
    },
    [callToAction]
  );

  const dropZoneInputProps = useMemo(
    () => ({
      'aria-describedby': 'upload-scene-video-error',
    }),
    []
  );

  const handleFilePick: ChangeEventHandler<HTMLInputElement> = (event) => {
    const file = event.target.files?.[0];
    if (!file) return;
    startUpload(file);
  };

  return (
    <>
      {permissionStatus === 'granted' && <GoBackOnMobile />}
      <FormatAwareContentArea
        display={{
          md: 'flex',
          xs: 'none',
        }}
        data-testid="no-permissions-panel"
      >
        <Dropzone
          onFile={handleFile}
          mime={MimeTypes[fileType]}
          backgroundColor="linen50"
          onError={handleError}
          inputProps={dropZoneInputProps}
          data-testid="dropzone"
        >
          {({ isDragActive, maxSize }) => {
            const hasError = !isDragActive && !!error;
            return (
              <GridContainer maxWidth={470} alignItems="center" justifyContent="center" direction="column" padding={3}>
                <InfoComponent icon={icon} title={title} description={description} />
                <GridItem>
                  <GridContainer spacing={1} justifyContent="center">
                    <GridItem xs={videoFormat === 'Vertical' ? 12 : undefined}>
                      <Button
                        fullWidth={videoFormat === 'Vertical'}
                        variant="secondary"
                        startIcon={<Icon size="s" type="UploadIcon" />}
                        data-testid="upload-button"
                      >
                        {fileType === 'video'
                          ? 'i18n.ScenePage.InitialScreen.uploadButtonVideo'
                          : 'i18n.ScenePage.InitialScreen.uploadButtonImage'}
                      </Button>
                    </GridItem>
                    {!!callToAction && (
                      <GridItem xs={videoFormat === 'Vertical' ? 12 : undefined}>
                        <Button
                          fullWidth={videoFormat === 'Vertical'}
                          startIcon={callToActionIcon ? <Icon type={callToActionIcon} size="s" /> : undefined}
                          variant="primary"
                          onClick={callToActionInternal}
                          data-testid="call-to-action-button"
                        >
                          {callToActionText}
                        </Button>
                      </GridItem>
                    )}
                  </GridContainer>
                </GridItem>
                <GridItem textAlign="center" style={hasError ? undefined : { display: 'none' }}>
                  <Text
                    variant="l"
                    color="negative"
                    id="upload-scene-video-error"
                    i18nParams={{ maxSize: formatFileSize(maxSize) }}
                  >
                    {error}
                  </Text>
                </GridItem>
              </GridContainer>
            );
          }}
        </Dropzone>
      </FormatAwareContentArea>
      <GridContainer spacing={1} justifyContent="center" display={{ md: 'none' }}>
        <GridItem xs={12} mb={1}>
          <FormatAwareContentArea>
            <MobileContainer>
              <InfoComponent title={title} description={description} icon={icon} />
            </MobileContainer>
          </FormatAwareContentArea>
        </GridItem>
        <GridItem xs={12}>
          <BottomFixedContainer>
            <ButtonsContainer>
              <UploadFileButton
                fullWidth
                handleFilePick={handleFilePick}
                buttonText="i18n.ScenePage.InitialScreen.uploadButton"
                id="scene-asset-upload"
                inputProps={{ accept: AcceptTypes[fileType] }}
                variant={callToAction ? 'secondary' : 'primary'}
                data-testid="upload-button"
              />
              {!!callToAction && (
                <Button
                  fullWidth
                  startIcon={callToActionIcon ? <Icon type={callToActionIcon} size="s" /> : undefined}
                  variant="primary"
                  onClick={callToActionInternal}
                  data-testid="call-to-action-button"
                >
                  {callToActionText}
                </Button>
              )}
            </ButtonsContainer>
          </BottomFixedContainer>
        </GridItem>
      </GridContainer>
    </>
  );
};

const InfoComponent: FC<{
  title: string;
  icon?: IconType;
  description: ReactNode | string;
}> = ({ title, icon, description }) => {
  return (
    <>
      {!!icon && (
        <GridItem>
          <Icon size="xl" type={icon} />
        </GridItem>
      )}
      <GridItem textAlign="center">
        <GridContainer spacing={0.5} alignItems="center" justifyContent="center" direction="column">
          <GridItem textAlign="center">
            <Typography variant="h4" align="center">
              {title}
            </Typography>
          </GridItem>
          <GridItem textAlign="center">
            <Typography variant="l" align="center">
              {description}
            </Typography>
          </GridItem>
        </GridContainer>
      </GridItem>
    </>
  );
};
