import { type FC, type PropsWithChildren, useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import {
  BlankButton,
  Headline,
  Icon,
  Typography,
  styled,
  useNativeCaptureSupported,
  useUpload,
} from '@cofenster/web-components';

import { useAddAssetToContributionRequestByActor } from '../../api/hooks/contributionRequest/useAddAssetToContributionRequestByActor';
import { useDeleteAssetFromContributionRequestByActor } from '../../api/hooks/contributionRequest/useDeleteAssetFromContributionRequestByActor';
import type { ContributionRequestListByActor } from '../../api/hooks/contributionRequestList/useContributionRequestListByActor';
import { FilmingTipsMessage } from '../../components';
import { BackButtonBehaviorProvider, useCaptureBackButtonBehavior } from '../../components/capture/BackButtonBehavior';
import { CaptureAsset } from '../../components/capture/CaptureAsset';
import { CaptureAssetLifecycleFlowProvider } from '../../components/capture/CaptureAssetLifecycleFlow';
import { CaptureImageAsset } from '../../components/capture/CaptureImage/CaptureImageAsset';
import { ImageCapture } from '../../components/capture/CaptureImage/ImageCapture';
import { ReviewImage } from '../../components/capture/CaptureImage/ReviewImage';
import { VideoCapture } from '../../components/capture/CaptureVideo/CaptureRecording';
import { ReviewRecording } from '../../components/capture/CaptureVideo/CaptureRecording/ReviewRecording';

import type { VideoFormat } from '@cofenster/constants';
import { CaptureVideoAsset } from '../../components/capture/CaptureVideo/CaptureVideoAsset';
import { getRequiredPermissionsType } from '../../components/capture/getRequiredPermissionsType';
import { ContributionRequestLayout } from '../../components/layout/ContributionRequestLayout';
import { CaptureAssetCandidateFileProvider, type CaptureAssetMetadata } from '../../context/captureAssetFile';
import { useContributors } from '../../context/contributors';
import { useSelectedAssetId } from '../../context/currentAsset';
import { useCurrentUserTaskContributionIds } from '../../context/tasksProgress';
import { useTracking } from '../../context/tracking';
import { useNavigateBack } from '../../hooks/useNavigateBack';
import { useVideoFormat } from '../../hooks/useVideoFormat';
import { ContributionRequestInstruction } from './ContributionRequestInstruction';
import { ContributionsList } from './ContributionsList';
import { RequestViewedTracker } from './RequestViewedTracker';

export const ContributionRequestPage: FC = () => {
  return (
    <ContributionRequestLayout>
      {(details) => (
        <>
          <ContributionRequestWrapper
            projectId={details.project.id}
            videoFormat={details.project.videoFormat}
            list={details.contributionRequestList}
          />
          <RequestViewedTracker />
        </>
      )}
    </ContributionRequestLayout>
  );
};

const BackIconButton = styled(BlankButton)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,

  '&:focus-visible': theme.mixins.focusRing,
}));

const GoBackButton: FC<PropsWithChildren> = ({ children }) => {
  const { onClick } = useCaptureBackButtonBehavior();

  return (
    <BackIconButton onClick={onClick} data-testid="go-back-button">
      <Icon type="ArrowLeftIcon" />
      <Typography variant="l">{children}</Typography>
    </BackIconButton>
  );
};

const Header = styled('div')(({ theme }) => ({
  margin: theme.spacing(2, 0),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
}));

// 1. Make sure that long words and URLs do not break the layout and cause
//    horizontal scrolling.
const StyledHeadline = styled(Headline)(() => ({
  wordWrap: 'break-word', // 1
}));

const ButtonContainer = styled('div')(({ theme }) => ({
  zIndex: theme.zIndex.base,
  position: 'relative',
}));

type ContributionRequestList = ContributionRequestListByActor['contributionRequestList'];
type ContributionRequest = ContributionRequestListByActor['contributionRequestList']['requests'][number];

const ContributionRequestWrapper: FC<{
  projectId: string;
  videoFormat: VideoFormat;
  list: ContributionRequestList;
}> = ({ projectId, videoFormat, list }) => {
  const { requestId } = useParams() as { requestId: string };
  const index = useMemo(() => list.requests.findIndex((request) => request.id === requestId), [list, requestId]);
  const request = list.requests[index];
  const [currentUserContributionIds] = useCurrentUserTaskContributionIds(requestId);
  const { setSelectedAssetId, getSelectedAssetId } = useSelectedAssetId();

  const setSelectedAssetIdInternal = useCallback(
    (id: string | undefined) => setSelectedAssetId(requestId, id),
    [requestId, setSelectedAssetId]
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: we need to set the assetId only on the first render
  useEffect(() => {
    // This can only occur if the manager removes the request after co-capture has been opened
    // or if the user manually provides the taskId in the request URL.
    if (!request) return;
    setSelectedAssetId(requestId, findLatestCurrentUserContribution(request, currentUserContributionIds));
  }, []);

  const nativeCaptureSupported = useNativeCaptureSupported();

  if (!request) return null;

  const contributions = [...request.videoAssets, ...request.imageAssets]
    .filter((asset) => request.showOthersContributions || currentUserContributionIds.includes(asset.id))
    // Exclude selected asset
    .filter((asset) => asset.id !== getSelectedAssetId(requestId))
    .filter(
      (asset) =>
        // Include user contributions in any status
        currentUserContributionIds.includes(asset.id) ||
        // Or other users contributions only if they are ready
        (asset.status === 'Ready' && !currentUserContributionIds.includes(asset.id))
    )
    .sort((a, b) => b.createdAt.localeCompare(a.createdAt));

  return (
    <CaptureAssetCandidateFileProvider key={requestId} useTracking={useTracking}>
      <ButtonContainer>
        <BackButtonBehaviorProvider>
          <GoBackButton>i18n.ContributionRequestPage.backButton</GoBackButton>
          <Header>
            <StyledHeadline component="h1" variant="h3" i18nParams={{ n: index + 1 }}>
              {request.title ?? 'i18n.ContributionRequestPage.noTitle'}
            </StyledHeadline>
            <ContributionRequestInstruction instruction={request.instruction} />
          </Header>
          <ContributionRequestCaptureWrapper
            projectId={projectId}
            request={request}
            assetId={getSelectedAssetId(requestId)}
            setAssetId={setSelectedAssetIdInternal}
          />
        </BackButtonBehaviorProvider>
      </ButtonContainer>
      <ContributionsList
        videoFormat={videoFormat}
        contributionRequestId={request.id}
        list={contributions}
        selectedAssetId={getSelectedAssetId(requestId)}
        setSelectedAssetId={setSelectedAssetIdInternal}
      />
      {!nativeCaptureSupported && <FilmingTipsMessage />}
    </CaptureAssetCandidateFileProvider>
  );
};

const findLatestCurrentUserContribution = (request: ContributionRequest, currentUserContributionIds: string[]) => {
  const asSet = new Set(currentUserContributionIds);

  return request.contributions
    .map((contribution) => {
      if (contribution.__typename === 'ImageContribution' && asSet.has(contribution.imageAsset.id)) {
        return {
          id: contribution.imageAsset.id,
          createdAt: contribution.imageAsset.createdAt,
        };
      }
      if (contribution.__typename === 'VideoContribution' && asSet.has(contribution.videoAsset.id)) {
        return {
          id: contribution.videoAsset.id,
          createdAt: contribution.videoAsset.createdAt,
        };
      }
    })
    .filter((object) => object !== undefined)
    .sort((a, b) => b.createdAt.localeCompare(a.createdAt))?.[0]?.id;
};

const ContributionRequestCaptureWrapper: FC<{
  projectId: string;
  request: ContributionRequest;
  assetId: string | undefined;
  setAssetId: (assetId: string | undefined) => void;
}> = ({ projectId, request, assetId, setAssetId }) => {
  const isVideo = request.type !== 'IMAGE';
  const { startUpload, cancelAndClearUpload } = useUpload();
  const { addAssetToContributionRequestByActor } = useAddAssetToContributionRequestByActor();
  const { deleteAssetFromContributionRequestByActor } = useDeleteAssetFromContributionRequestByActor(request.id);
  const { getByProject } = useContributors();
  const navigateBack = useNavigateBack();
  const onRetake = useCallback(() => setAssetId(undefined), [setAssetId]);

  const videoFormat = useVideoFormat();
  const captureType = request.type === 'IMAGE' ? 'image' : request.type === 'VIDEO' ? 'camera' : 'screenRecording';

  const [_, addContributionId, deleteContributionId] = useCurrentUserTaskContributionIds(request.id);

  const tracking = useTracking();
  const onDelete = useCallback(async () => {
    if (!assetId) return;

    await deleteAssetFromContributionRequestByActor(assetId);
    deleteContributionId(assetId);
    setAssetId(undefined);
    tracking?.trackEvent({
      event: 'requestAssetDeleted',
      details: {
        assetType: isVideo ? 'video' : 'image',
        assetId,
      },
    });
  }, [assetId, deleteAssetFromContributionRequestByActor, deleteContributionId, tracking, isVideo, setAssetId]);

  const onUpload = useCallback(
    async (file: File, metadata?: CaptureAssetMetadata) => {
      const type = isVideo ? 'video' : 'image';
      const uploadId = request.id;
      try {
        navigateBack();
        const assetId = await startUpload(type, uploadId, file, {
          videoFormat,
          // this param decides whether to crop or fit the video
          // we want to crop videos from webcam
          // and fit videos from screen recording and uploaded files
          videoFit:
            captureType === 'screenRecording' ||
            metadata?.uploadSource === 'desktop-library' ||
            metadata?.uploadSource === 'mobile-library'
              ? 'Fit'
              : 'Crop',
        });
        if (!assetId) return;
        setAssetId(assetId);
        await addAssetToContributionRequestByActor(request.id, {
          assetId,
          type: request.type,
          contributors: getByProject(projectId).map(({ name, email }) => ({ name, email })),
        });
        addContributionId(assetId);
        tracking?.trackEvent({
          event: 'requestAssetUploaded',
          details: {
            assetType: type,
            assetName: file.name,
            assetId,
            ...metadata,
          },
        });
        return assetId;
      } finally {
        cancelAndClearUpload(type, uploadId);
      }
    },
    [
      cancelAndClearUpload,
      startUpload,
      isVideo,
      request,
      addContributionId,
      addAssetToContributionRequestByActor,
      captureType,
      videoFormat,
      tracking,
      projectId,
      getByProject,
      navigateBack,
      setAssetId,
    ]
  );

  return (
    <CaptureAssetLifecycleFlowProvider
      assetType={isVideo ? 'video' : 'image'}
      format={videoFormat}
      requiredPermissions={getRequiredPermissionsType(captureType)}
      captureType={captureType}
      onDelete={onDelete}
      onRetake={onRetake}
      upload={onUpload}
      title={request.title}
      instruction={request.instruction}
    >
      <CaptureAsset
        assetIdForPolling={assetId}
        uploadId={request.id}
        showRecordViaApp={true}
        ReviewComponent={isVideo ? ReviewRecording : ReviewImage}
        AssetComponent={isVideo ? CaptureVideoAsset : CaptureImageAsset}
        CaptureComponent={isVideo ? VideoCapture : ImageCapture}
      />
    </CaptureAssetLifecycleFlowProvider>
  );
};
