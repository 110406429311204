import { type FC, type PropsWithChildren, useMemo } from 'react';

import {
  Card,
  Icon,
  LinearProgressBar,
  Typography,
  preventForwardProps,
  styled,
  useUpload,
} from '@cofenster/web-components';

import type { ContributionRequestListByActor } from '../../../api/hooks/contributionRequestList/useContributionRequestListByActor';
import { ExpandedRouterLink } from '../../../components';
import { useCurrentUserTaskContributionIds } from '../../../context/tasksProgress';

import { TASK_LINK_CLASSNAME, TaskThumbnail } from './TaskThumbnail';

type ContributionRequest = ContributionRequestListByActor['contributionRequestList']['requests'][number];

const TaskWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row-reverse',
  gap: theme.spacing(2),
  minHeight: 80,
  position: 'relative',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

const TaskCard = styled(Card)(({ theme }) => ({
  position: 'static',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  transition: 'background-color 100ms ease-out',
  padding: theme.spacing(2),

  [theme.breakpoints.down('sm')]: {
    flex: '0 0 80px',
  },
}));

const TaskCardContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(2),
}));

const SpanVerticallyCentered = styled(
  'span',
  preventForwardProps(['gap'])
)<{ gap?: number }>(({ theme, gap = 0.5 }) => ({
  display: 'flex',
  alignItems: 'center',
  marginLeft: 'auto',
  marginRight: theme.spacing(2),
  gap: theme.spacing(gap),
}));

const useContributionRequestUpload = (request: ContributionRequest) => {
  const { getUpload } = useUpload();
  return getUpload('video', request.id) ?? getUpload('image', request.id);
};

const useUserContributions = (request: ContributionRequest) => {
  const [currentUserContributionIds] = useCurrentUserTaskContributionIds(request.id);

  return useMemo(
    () =>
      [...request.videoAssets, ...request.imageAssets].filter((asset) => currentUserContributionIds.includes(asset.id)),
    [currentUserContributionIds, request.videoAssets, request.imageAssets]
  );
};

const ErrorStatus: FC<PropsWithChildren> = ({ children }) => {
  return (
    <SpanVerticallyCentered>
      <Icon type="WarningCircleIcon" color="negative" />
      <Typography variant="s">{children}</Typography>
    </SpanVerticallyCentered>
  );
};

// 1. Make sure that long words and URLs do not break the layout and cause
//    horizontal scrolling.
const StyledExpandedRouterLink = styled(ExpandedRouterLink)(() => ({
  wordWrap: 'break-word', // 1
}));

const Title = styled(
  Typography,
  preventForwardProps(['done'])
)<{ done?: boolean }>(({ theme, done }) => ({
  textDecoration: done ? 'line-through' : 'none',
  color: done ? theme.palette.brand.grey700 : undefined,

  display: '-webkit-box',
  WebkitLineClamp: 4,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  whiteSpace: 'break-spaces',
  wordWrap: 'break-word',
}));

const StyledProgressBar = styled(LinearProgressBar)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  '&.MuiLinearProgress-root': {
    backgroundColor: theme.palette.brand.blue_alpha20,
  },
}));

const AnimatableLinearProgressBarContainer = styled(
  'div',
  preventForwardProps(['isVisible'])
)<{ isVisible?: boolean }>(({ isVisible, theme }) => ({
  height: isVisible ? 10 : 0,
  visibility: isVisible ? 'visible' : 'hidden',
  padding: isVisible ? theme.spacing(2, 0, 0, 0) : 0,
  opacity: isVisible ? 1 : 0,
  transition: 'visibility 0.4s ease-in, padding 0.6s ease-in, opacity 0.4s ease-in',
}));

const TaskUploadProgress: FC<{ request: ContributionRequest }> = ({ request }) => {
  const upload = useContributionRequestUpload(request);
  const isUploading = Boolean(upload);
  const userContributions = useUserContributions(request);
  const defaultProgressWhenNotUploading = userContributions.length ? 100 : 0;
  const progressInPercent = (upload?.progress ?? defaultProgressWhenNotUploading) * 100;
  const uploadProgressPercentValue = Math.max(0, Math.min(100, progressInPercent));

  return (
    <AnimatableLinearProgressBarContainer isVisible={isUploading}>
      <StyledProgressBar data-testid="uploading-panel" variant="determinate" value={uploadProgressPercentValue} />
    </AnimatableLinearProgressBarContainer>
  );
};

export const Task: FC<{ request: ContributionRequest; index: number }> = ({ request, index }) => {
  const upload = useContributionRequestUpload(request);
  const didUploadFail = Boolean(upload?.error);

  const userContributions = useUserContributions(request);
  const hasError = userContributions.some((asset) => asset.status === 'Error');

  const thumbnailAsset = userContributions.sort((a, b) => b.createdAt.localeCompare(a.createdAt))[0];

  return (
    <TaskWrapper data-testid="task-actor-card">
      <TaskCard spacing={{ vertical: 1, horizontal: 2 }}>
        <TaskCardContent>
          <TaskThumbnail asset={thumbnailAsset} type={request.type} requestId={request.id} />
          <StyledExpandedRouterLink
            to="contributionRequest"
            params={{ requestId: request.id }}
            className={TASK_LINK_CLASSNAME}
          >
            <Title variant="h6" i18nParams={{ n: index + 1 }} done={Boolean(thumbnailAsset)}>
              {request.title || 'i18n.ContributionRequestListPage.task.noTitle'}
            </Title>
          </StyledExpandedRouterLink>

          <SpanVerticallyCentered gap={2}>
            {(didUploadFail || hasError) && (
              <ErrorStatus>i18n.ContributionRequestListPage.task.status.error</ErrorStatus>
            )}
          </SpanVerticallyCentered>

          {/* Wrapping div prevents icon from being smaller when there is a long text for title */}
          <div>
            <Icon type="CaretRightIcon" />
          </div>
        </TaskCardContent>
        <TaskUploadProgress request={request} />
      </TaskCard>
    </TaskWrapper>
  );
};
