import type { FC, PropsWithChildren } from 'react';
import { useParams } from 'react-router-dom';

import { useClient } from '../context/client';
import { Redirect } from '../router/Redirect';

export const EnsureHasToken: FC<PropsWithChildren> = ({ children }) => {
  const { token: urlToken } = useParams();
  const { state } = useClient();

  if (!state.token) {
    // This is a workaround to people copying and pasting the URL from the
    // CRL to share with their contributors. When opening the CRL without
    // going through the welcome page, we do have a token in the URL,
    // but not yet in the client, so we need to redirect back to the welcome
    // page. This could theoretically also be used to make sure that the person
    // went through the consent form if it is required.
    if (urlToken) return <Redirect to="welcome" params={{ token: urlToken }} />;
    return <Redirect to="home" />;
  }

  return children;
};
