import type { ComponentProps, FC } from 'react';

import { Button, Card, GridContainer, GridItem, Text } from '@cofenster/web-components';

import { Layout, PageHeadline } from '../../components/layout';

const DEFAULT_MESSAGES = {
  headline: 'i18n.ErrorPage.headline',
  subHeadline: 'i18n.ErrorPage.subHeadline',
  button: 'i18n.ErrorPage.button',
};

export const ErrorPage: FC<{ messages?: Partial<typeof DEFAULT_MESSAGES> } & ComponentProps<'div'>> = ({
  messages,
  ...rest
}) => {
  const { headline, subHeadline, button } = {
    ...DEFAULT_MESSAGES,
    ...(messages ?? {}),
  };

  return (
    <Layout title={headline} {...rest}>
      <GridContainer justifyContent="center">
        <GridItem>
          <Card>
            <PageHeadline variant="h1">{headline}</PageHeadline>
            <GridContainer justifyContent="center">
              <GridItem md={8} textAlign="center">
                <Text variant="xl" component="p">
                  {subHeadline}
                </Text>
              </GridItem>
              <GridItem md={8} textAlign="center" mt={8}>
                <Button href="/">{button}</Button>
              </GridItem>
            </GridContainer>
          </Card>
        </GridItem>
      </GridContainer>
    </Layout>
  );
};
