import { type FC, useCallback, useEffect, useRef, useState } from 'react';

import { AspectRatioBox, styled, useIsVideoPlaying } from '@cofenster/web-components';

import type { VideoAsset } from '../../api/hooks/videoAsset/usePollingVideoAsset';
import { PlayIconButton } from '../../components/controls/PlayIconButton';
import { useDialogs } from '../../context/dialogs/useDialogs';
import { useTracking } from '../../context/tracking';
import { useTrackBriefingVideoPlayPause } from '../../hooks/useTrackBriefingVideoPlayPause';

const VideoBriefingContainer = styled('div')(() => ({
  gridArea: 'briefing',
}));

const BriefingImageAsset = styled('img')(({ theme }) => ({
  width: '100%',
  objectFit: 'cover',
  alignSelf: 'stretch',
  justifySelf: 'stretch',
  borderRadius: theme.shape['borderRadius-l'],
}));

export const VideoBriefing: FC<{ video: VideoAsset }> = ({ video }) => {
  const [element, setElement] = useState<HTMLVideoElement | null>(null);
  const isPlaying = useIsVideoPlaying(element);
  const isPlayingRef = useRef(isPlaying);
  useTrackBriefingVideoPlayPause(element);

  useEffect(() => {
    isPlayingRef.current = isPlaying;
  }, [isPlaying]);

  const tracking = useTracking();
  const onClose = useCallback(() => {
    if (!isPlayingRef.current) return;
    tracking.trackEvent({
      event: 'requestBriefingVideoPaused',
      details: {
        source: 'dialogClose',
      },
    });
  }, [tracking]);

  const { openDialog } = useDialogs();
  const onPlayClick = useCallback(() => {
    openDialog('PreviewBriefingVideoDialog', { video, setElement, onClose });
  }, [openDialog, video, onClose]);

  if (!video?.videoUrl) return null;

  return (
    <VideoBriefingContainer>
      <AspectRatioBox ratio={1} width={80}>
        <BriefingImageAsset src={video.thumbnailUrl ?? undefined} />
        <PlayIconButton icon="PlayIcon" label="i18n.global.play" iconSize="s" iconWeight="fill" onClick={onPlayClick} />
      </AspectRatioBox>
    </VideoBriefingContainer>
  );
};
