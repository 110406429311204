import { useEffect, useState } from 'react';

export const useMuteMediaStream = (mediaStream: MediaStream | null) => {
  const [muted, setMuted] = useState(false);

  useEffect(() => {
    if (!mediaStream) return;
    mediaStream.getAudioTracks().forEach((audioTrack) => {
      audioTrack.enabled = !muted;
    });
  }, [mediaStream, muted]);

  return [muted, setMuted] as const;
};
