import { useUpload } from '@cofenster/web-components';
import { useCallback, useContext, useMemo } from 'react';
import { useContributionRequestListDetails } from '../../context/contributionRequestListDetails';
import { TasksContributionsContext } from '../../context/tasksProgress/TaskProgressProvider';
import { useNavigateTo } from '../../hooks/useNavigateTo';
import { BottomFixedContainer } from '../controls';
import { FullWidthOnSmallScreenButton } from './FullWidthOnSmallScreenButton';

export const NextTaskButton = () => {
  const details = useContributionRequestListDetails();
  const navigate = useNavigateTo();
  const { getUpload } = useUpload();
  const [contributionIdsByTaskId] = useContext(TasksContributionsContext);

  const id = useMemo(
    () =>
      details?.contributionRequestList?.requests?.find(
        (task) =>
          !contributionIdsByTaskId[task.id]?.length && !getUpload('video', task.id) && !getUpload('image', task.id)
      )?.id,
    [details, contributionIdsByTaskId, getUpload]
  );

  const onClick = useCallback(() => {
    if (!id) return;
    navigate('contributionRequest', { params: { requestId: id } });
  }, [navigate, id]);

  if (!details || !id) return null;

  return (
    <BottomFixedContainer>
      <FullWidthOnSmallScreenButton onClick={onClick}>
        i18n.ContributionRequestListPage.nextTask
      </FullWidthOnSmallScreenButton>
    </BottomFixedContainer>
  );
};
