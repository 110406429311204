import { styled } from '@mui/material';
import { type FC, type MouseEventHandler, memo } from 'react';

import type { UseMediaDevicesDevice } from '../../../../../hooks/media/useMediaDevices';
import { Icon } from '../../../../assets/icons/Icon';
import { IconButton } from '../../../../controls/Button/IconButton';
import { SelectableBoxInput } from '../../../../controls/SelectableBoxInput';
import { Alert } from '../../../../display/Alert';
import { withPopover } from '../../../../feedback/Popover';
import { GridContainer, GridItem } from '../../../../layout/Grid';
import { VisuallyHidden } from '../../../../utilities/VisuallyHidden';

export type SelectedMediaDevice = UseMediaDevicesDevice | false | undefined;

export type MicrophonePopOverProps = {
  devices: UseMediaDevicesDevice[] | undefined;
  selectedDevice: SelectedMediaDevice;
  onSelectDevice: (device: SelectedMediaDevice) => unknown;
  disabled: boolean;
  disableHoverListener?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

const Container = styled('div')(() => ({
  border: 0,
  margin: 0,
  padding: 0,
}));

const tooltipProps = { disableHoverListener: true };

export const MicrophonePopOver: FC<MicrophonePopOverProps> = memo(
  ({ devices, selectedDevice, onSelectDevice, disabled, disableHoverListener, onClick }) => {
    const iconType = selectedDevice ? 'MicrophoneIcon' : 'MicrophoneOffIcon';
    const iconColor = disabled ? 'grey300' : 'white';

    const MicrophonePopoverIcon = withPopover(IconButton, {
      anchorOriginX: 'center',
      anchorOriginY: 'top',
      transformOriginX: 'center',
      transformOriginY: 'bottom',
      children: (
        <GridContainer spacing={0} padding={devices ? 2 : 0} maxWidth={400}>
          <Container as={devices ? 'fieldset' : 'div'}>
            {devices ? (
              <>
                <VisuallyHidden as="legend">i18n.global.microphone.configure</VisuallyHidden>

                {devices.map((device) => (
                  <GridItem xs={12} key={device.deviceId} position="relative">
                    <SelectableBoxInput
                      mainIcon={<Icon type="MicrophoneIcon" />}
                      type="radio"
                      name="microphoneDevice"
                      id={`device-${device.deviceId}`}
                      value={device.deviceId}
                      title={device.label}
                      checked={!!selectedDevice && selectedDevice?.deviceId === device.deviceId}
                      onChange={() => onSelectDevice(device)}
                    />
                  </GridItem>
                ))}

                <GridItem xs={12}>
                  <SelectableBoxInput
                    mainIcon={<Icon type="MicrophoneOffIcon" />}
                    type="radio"
                    name="microphoneDevice"
                    id="none"
                    value="none"
                    title="i18n.global.microphone.none"
                    checked={!selectedDevice}
                    onChange={() => onSelectDevice(false)}
                  />
                </GridItem>
              </>
            ) : (
              <GridItem xs={12}>
                <Alert severity="error" icon={<Icon type="InfoIcon" size="m" />}>
                  i18n.global.selectAudioDevice.permissionsDenied
                </Alert>
              </GridItem>
            )}
          </Container>
        </GridContainer>
      ),
    });

    return (
      <MicrophonePopoverIcon
        onClick={onClick}
        tooltipProps={disableHoverListener ? tooltipProps : undefined}
        disabled={disabled}
        data-testid="select-microphone-button"
        icon={iconType}
        iconColor={selectedDevice ? iconColor : 'white'}
        hoverBackgroundColor={selectedDevice ? undefined : 'negativeDark'}
        hoverColor={selectedDevice ? iconColor : 'white'}
        backgroundColor={selectedDevice ? 'blurred' : 'negative'}
        iconWeight="fill"
        label="i18n.global.microphone.configure"
      />
    );
  }
);
MicrophonePopOver.displayName = 'MicrophonePopOver';
