export type ShaderUniforms<T extends string = string, F extends string = string> = {
  textures: T[];
  floats: F[];
};

const verifyUniforms = <U extends ShaderUniforms>(source: string, uniforms: U) => {
  if (!uniforms.textures.length) {
    console.log('Shader does not have any textures');
  }

  if (uniforms.textures.some((texture) => !source.includes(`uniform sampler2D ${texture}`))) {
    console.error(uniforms.textures, source);
    throw new Error('Shader source does not contain all texture uniforms');
  }

  if (uniforms.floats.some((float) => !source.includes(`uniform float ${float}`))) {
    console.error(uniforms.floats, source);
    throw new Error('Shader source does not contain all float uniforms');
  }

  return uniforms;
};

export const createFragmentShader = <U extends ShaderUniforms>(source: string, uniforms: U) => {
  return {
    init: (gl: WebGL2RenderingContext) => {
      const fShader = gl.createShader(gl.FRAGMENT_SHADER);
      if (!fShader) throw new Error('Failed to create fragment shader');
      gl.shaderSource(fShader, source);
      gl.compileShader(fShader);
      if (!gl.getShaderParameter(fShader, gl.COMPILE_STATUS)) {
        console.error(gl.getShaderInfoLog(fShader));
        throw new Error('Failed to compile fragment shader');
      }
      return fShader;
    },
    uniforms: verifyUniforms(source, uniforms),
  };
};

export type FragmentShader<U extends ShaderUniforms = ShaderUniforms> = ReturnType<typeof createFragmentShader<U>>;

export const SIMPLE_FRAGMENT_SHADER = createFragmentShader(
  `
precision mediump float;
uniform sampler2D texture;
varying vec2 vTex;

void main() {
  vec4 color = texture2D(texture, vTex);
  gl_FragColor = color;
}
`,
  {
    textures: ['texture'],
    floats: [],
  }
);
