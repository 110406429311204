import type { FC, PropsWithChildren } from 'react';

import { ThemeProvider as BaseThemeProvider } from '@cofenster/web-components';

import { GlobalStyles } from './GlobalStyles';

export const ThemeProvider: FC<PropsWithChildren<Record<string, unknown>>> = ({ children }) => (
  <BaseThemeProvider>
    <GlobalStyles />
    {children}
  </BaseThemeProvider>
);
