import type { FC } from 'react';

import type { UseMediaDevicesDevice } from '../../../../../../hooks/media/useMediaDevices';
import { Select, SelectOption } from '../../../../../controls/Select';
import type { SelectedMediaDevice } from '../../MicrophonePopOver';
import { CameraOption } from './Option';

type CameraSelectProps = {
  devices: UseMediaDevicesDevice[];
  selectedDevice: SelectedMediaDevice;
  onSelectDevice: (device: SelectedMediaDevice) => unknown;
};

export const CameraSelect: FC<CameraSelectProps> = ({ devices, selectedDevice, onSelectDevice }) => (
  <Select
    label="i18n.global.pip.dialog.camera"
    name="selectCamera"
    pb={0}
    value={selectedDevice ? selectedDevice.deviceId : 'none'}
    onChange={(event) => {
      const deviceId = event.target.value as string;
      const device = devices.find((device) => device.deviceId === deviceId) ?? false;
      onSelectDevice(device);
    }}
  >
    <SelectOption value="none">
      <CameraOption iconType="WebcamSlashIcon" title="i18n.global.pip.dialog.no-camera" />
    </SelectOption>

    {devices?.map((device) => (
      <SelectOption key={device.deviceId} value={device.deviceId}>
        <CameraOption iconType="WebcamIcon" title={device.label} />
      </SelectOption>
    ))}
  </Select>
);
