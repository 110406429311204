import { type ChangeEventHandler, type FC, useCallback } from 'react';

import { AcceptTypes } from '@cofenster/constants';
import { Button, GridContainer, GridItem, Icon, UploadFileButton } from '@cofenster/web-components';

import { useCaptureAssetCandidateFileContext } from '../../../../../../context/captureAssetFile';
import { BottomFixedContainer } from '../../../../../controls';

type ContributionRequestTitleAndInstructionsProps = {
  goToNextStep: () => void;
  fileType: 'image' | 'video';
};

export const ContributionRequestTitleAndInstructions: FC<ContributionRequestTitleAndInstructionsProps> = ({
  goToNextStep,
  fileType,
}) => {
  const { onCaptureAssetReadyForReview } = useCaptureAssetCandidateFileContext();

  const onFile = useCallback(
    (file: File) => {
      onCaptureAssetReadyForReview(
        {
          blob: file,
          url: URL.createObjectURL(file),
        },
        { uploadSource: 'mobile-library' }
      );
    },
    [onCaptureAssetReadyForReview]
  );

  const handleFilePick: ChangeEventHandler<HTMLInputElement> = (event) => {
    const file = event.target.files?.[0];
    if (!file) return;
    onFile(file);
  };

  return (
    <BottomFixedContainer>
      <GridContainer spacing={1}>
        <GridItem xs={6}>
          <UploadFileButton
            handleFilePick={handleFilePick}
            buttonText={
              fileType === 'video'
                ? 'i18n.ScenePage.InitialScreen.uploadButtonVideo'
                : 'i18n.ScenePage.InitialScreen.uploadButtonImage'
            }
            id="scene-asset-upload"
            inputProps={{ accept: AcceptTypes[fileType] }}
            variant="secondary"
            data-testid="upload-button"
            fullWidth
          />
        </GridItem>
        <GridItem xs={6}>
          <Button startIcon={<Icon type="RecordIcon" size="s" />} fullWidth onClick={goToNextStep}>
            i18n.global.record
          </Button>
        </GridItem>
      </GridContainer>
    </BottomFixedContainer>
  );
};
