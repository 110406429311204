type SupportedMimeType = string | 'image/jpeg' | 'image/png' | 'image/gif' | 'image/webp' | 'image/avif' | undefined;

export const detectImageMimeType = (blob: Blob) =>
  new Promise<SupportedMimeType>((resolve) => {
    const fileReader = new FileReader();
    let type: SupportedMimeType;

    fileReader.onloadend = (event) => {
      try {
        if (!event.target?.result || typeof event.target.result === 'string') return;
        const header = [...new Uint8Array(event.target.result).subarray(0, 4)]
          .map((it: number) => it.toString(16))
          .join('');

        switch (header) {
          case '89504e47':
            type = 'image/png';
            break;
          case '47494638':
            type = 'image/gif';
            break;
          case 'ffd8ffe0':
          case 'ffd8ffe1':
          case 'ffd8ffe2':
          case 'ffd8ffe3':
          case 'ffd8ffe8':
            type = 'image/jpeg';
            break;
          default:
            if (header.startsWith('00020')) {
              type = 'image/avif';
            } else if (header.startsWith('52494646')) {
              type = 'image/webp';
            } else {
              type = blob.type;
            }
            break;
        }
      } finally {
        resolve(type);
      }
    };

    fileReader.onerror = () => resolve(undefined);
    fileReader.onabort = () => resolve(undefined);

    fileReader.readAsArrayBuffer(blob);
  });
