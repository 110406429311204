import type { FC } from 'react';

import type { IconType } from '../../../../assets/icons/Icon';
import { IconButton } from '../../../../controls/Button/IconButton';

type PauseResumeButtonProps = {
  onPauseResume?: () => unknown;
  status: 'recording' | 'paused';
  disabled?: boolean;
};

export const PauseResumeButton: FC<PauseResumeButtonProps> = ({ onPauseResume, status, disabled }) => {
  const iconType: IconType = status === 'paused' ? 'PlayIcon' : 'PauseIcon';

  return (
    <IconButton
      onClick={onPauseResume}
      disabled={disabled}
      data-testid="pause-resume-button"
      icon={iconType}
      iconColor="white"
      iconWeight="fill"
      backgroundColor="blurred"
      label={status === 'paused' ? 'i18n.global.play' : 'i18n.global.pause'}
    />
  );
};
