import { useCallback } from 'react';

import { type CreateConsentsMutationVariables, useCreateConsentsMutation } from '../../generated';
export type { CreateConsentsMutationVariables } from '../../generated';

export const useCreateConsents = () => {
  const [createConsents] = useCreateConsentsMutation();

  return useCallback(
    (projectId: CreateConsentsMutationVariables['projectId'], inputs: CreateConsentsMutationVariables['inputs']) =>
      createConsents({ variables: { projectId, inputs } }),
    [createConsents]
  );
};
