import { GlobalDE } from '@cofenster/web-components';

import { SceneAssetLifecycleTranslationsDE } from '../../components/capture/translations/DE';

import type { EN } from './EN';

export const DE: typeof EN = {
  ...GlobalDE,
  ...SceneAssetLifecycleTranslationsDE,
  'AppStoreCard.availableFor': 'cofenster capture app verfügbar für',
  'BrowserNotSupportedBanner.close': 'Banner schließen',
  'ContributionRequestListPage.header.finished.title': 'Dankeschön!',
  'ContributionRequestListPage.header.finished.subline': 'Alle deine Beiträge wurden zu "{projectName}" von {projectOwner} hochgeladen',
  'ContributionRequestListPage.header.headline': 'Deine Video-Aufgaben',
  'ContributionRequestListPage.header.subline': 'Trage zum Video {projectName} von {firstname} {lastname} bei',
  'ContributionRequestListPage.nextTask': 'Nächste Aufgabe',
  'ContributionRequestListPage.PreviewBriefingVideoDialog.title': 'Vorschau des Briefing-Videos',
  'ContributionRequestListPage.task.noTitle': 'Aufgabe {n}',
  'ContributionRequestListPage.task.status.done': 'Erledigt',
  'ContributionRequestListPage.task.status.error': 'Fehler',
  'ContributionRequestListPage.task.status.processing': 'Wird bearbeitet',
  'ContributionRequestListPage.task.status.uploading': 'Lädt hoch ({progress}%)',
  'ContributionRequestListPage.tasksProgress.allDone': 'Gut gemacht!',
  'ContributionRequestListPage.tasksProgress.progress': '{done} von {total}',
  'ContributionRequestPage.backButton': 'Video-Aufgaben',
  'ContributionRequestPage.contributions': '{count, plural, =0 {Keine Beiträge} one {# Beitrag} other {# Beiträge}}',
  'ContributionRequestPage.noInstructions': 'Keine Instruktionen',
  'ContributionRequestPage.noTitle': 'Aufgabe {n}',
  'ContributionRequestPage.showMore': 'Zeig mehr',
  'CopyDataToClipBoard.codeCopied': 'Code kopiert',
  'DeleteContributionDialog.button': 'Beitrag löschen',
  'DeleteContributionDialog.description': 'Bist du sicher? Dieser Beitrag wird vollständig entfernt werden.',
  'DeleteContributionDialog.headline': 'Beitrag löschen?',
  'ErrorPage.button': 'Zurück zu CoCapture',
  'ErrorPage.emptyRequestList.headline': 'Dieses Request-Liste ist leer',
  'ErrorPage.emptyRequestList.subHeadline': 'Hoppla! Es sieht so aus, als ob mit diesem Link keine Aufnahmeanfragen verknüpft sind. Bitte wenden Sie sich an den Ersteller der Anfrageliste, um dieses Problem zu beheben.',
  'ErrorPage.headline': 'Oh nein, wir haben kein Projekt gefunden, das deinem Link entspricht',
  'ErrorPage.notAcceptingResponses.headline': 'Für dieses Video sind keine Beiträge mehr möglich',
  'ErrorPage.notAcceptingResponses.subHeadline': 'Das Filmen von “{projectName}” wurde beendet. Kontaktiere den/die Ersteller*in dieses Videos wenn du glaubst, dass es sich hierbei um einen Fehler handelt.',
  'ErrorPage.subHeadline': 'Vielleicht wurde das Projekt archiviert oder gelöscht. Nimm Kontakt mit deinem Projektadministrator auf, um deinen Zugang zu klären.',
  'Form.error.email.format': 'Bitte gib eine gültige E-Mail Adresse ein',
  'header.openInApp': 'In der App öffnen',
  'OpenInAppDialog.description.withoutQrCode': 'Öffne die cofenster App und gib den unten stehenden Code ein.',
  'OpenInAppDialog.description.withQrCode': 'Öffne die cofenster App oder die Kamera deines Handys und scanne den QR-Code. Du kannst den Code auch unten kopieren.',
  'OpenInAppDialog.headline.withoutQrCode': 'Kopiere den Code',
  'OpenInAppDialog.headline.withQrCode': 'Scanne den QR-Code',
  'OrientationRestriction.descriptionHorizontal': 'Diese Szene muss im horizontalen Format gefilmt werden.',
  'OrientationRestriction.descriptionVertical': 'Diese Szene muss im vertikalen Format gefilmt werden.',
  'OrientationRestriction.title': 'Drehe dein Telefon',
  'PageFooter.dataProtection': 'Datenschutz',
  'PageFooter.imprint': 'Impressum',
  'PageFooter.terms': 'AGB’s',
  'ScenePage.MobileWebcamRecorder.instructionsButton': 'Anweisungen',
  'SigninByCodePage.headline': 'Projektcode eingeben',
  'SigninByCodePage.hint': 'Du hast keinen Code? Bitte ein Team-Mitglied, ihn mit dir zu teilen oder erstelle ein eigenes Videoprojekt unter',
  'SigninByCodePage.SigninByCodeForm.code.invalid': 'Kein Projekt für den angegebenen Code gefunden',
  'SigninByCodePage.SigninByCodeForm.code.label': 'Code',
  'SigninByCodePage.SigninByCodeForm.code.required': 'Bitte gib den Projektcode ein',
  'SigninByCodePage.SigninByCodeForm.error': 'Es ist ein Fehler aufgetreten',
  'SigninByCodePage.SigninByCodeForm.submit': 'Video Projekt öffnen',
  'SigninByCodePage.subline': 'Bitte gib einen Code ein, um zu deinem cofenster Videoprojekt beizutragen',

  'WelcomePage.button.continue': 'Weiter',
  'WelcomePage.button.signAgreement': 'Vereinbarung unterschreiben',
  'WelcomePage.contributors.form.add': 'Mehr Personen in der Aufnahme? <link>Person hinzufügen</link>',
  'WelcomePage.contributors.form.email.required': 'Bitte gib deine E-Mail ein',
  'WelcomePage.contributors.form.email': 'E-Mail',
  'WelcomePage.contributors.form.name.required': 'Bitte gib deinen vollständigen Namen ein',
  'WelcomePage.contributors.form.name': 'Vollständiger Name',
  'WelcomePage.contributors.form.remove': 'Person entfernen',
  'WelcomePage.welcomeMessage.consentRequired.agreement': 'Durch Klicken auf die Schaltfläche "Vereinbarung unterschreiben" stimme ich der Videonutzungsvereinbarung von {accountName} zu.',
  'WelcomePage.welcomeMessage.consentRequired.descriptionParagraph2': 'Bevor du mit dem Filmen beginnst, lese bitte die von {accountName} bereitgestellte <link>Einverständniserklärung zur Videonutzung</link>.',
  'WelcomePage.welcomeMessage.consentRequired.error.description': 'Wir konnten die unterzeichnete Einwilligungsdatei nicht erstellen. Bitte versuchen Sie die Seite zu aktualisieren oder kontaktieren Sie uns.',
  'WelcomePage.welcomeMessage.consentRequired.error.title': 'Hoppla. Etwas ist schief gelaufen.',
  'WelcomePage.welcomeMessage.descriptionParagraph1.noProjectCreator': 'Deine Teilnahme am Projekt “{projectName}” ist gefragt!',
  'WelcomePage.welcomeMessage.descriptionParagraph1': '{firstname} braucht deine Teilnahme an dem Projekt “{projectName}”!',
  'WelcomePage.welcomeMessage.descriptionParagraph2.noProjectCreator': 'Cofenster hilft dir dabei, deine Szenen aufzunehmen und an den/die Projektersteller*in zu senden.',
  'WelcomePage.welcomeMessage.descriptionParagraph2': 'Cofenster hilft dir dabei, deine Szenen aufzunehmen und an {firstname} zu senden.',
  'WelcomePage.welcomeMessage.identificationRequired.noProjectCreator': 'Bevor du anfängst, gib uns bitte ein paar Informationen über dich, damit der/die Projektersteller*in sich bei Bedarf bei dir melden kann',
  'WelcomePage.welcomeMessage.identificationRequired': 'Bevor du anfängst, gib uns bitte ein paar Informationen über dich, damit {firstname} sich bei Bedarf bei dir melden kann',
  'WelcomePage.welcomeMessage.title.noProjectCreator': 'Hey! Du bist zu einem video eingeladen',
  'WelcomePage.welcomeMessage.title': 'Hey! {firstname} {lastname} hat dich zu einem Video eingeladen',
  'WelcomePage.welcomeMessage.verifyContributors.button': 'Kontaktangaben ändern',
  'WelcomePage.welcomeMessage.verifyContributors.title': 'Deine Kontaktangaben',
};
