import { type ComponentProps, useCallback } from 'react';

import type { ConfirmDialog } from '@cofenster/web-components';

import { useDialogs } from '../context/dialogs/useDialogs';

type UseConfirmDialogProps = Omit<
  ComponentProps<typeof ConfirmDialog>,
  'isOpen' | 'closeDialog' | 'onConfirm' | 'onCancel'
> &
  Partial<Pick<ComponentProps<typeof ConfirmDialog>, 'onConfirm' | 'onCancel'>>;

export const useConfirmDialog = (props: UseConfirmDialogProps) => {
  const { openDialog } = useDialogs();

  return useCallback(
    ({
      title = props.title,
      titleI18nParams = props.titleI18nParams,
      content = props.content,
      cancel = props.cancel,
      confirm = props.confirm,
      variant = props.variant,
      onConfirm = props.onConfirm,
      onCancel = props.onCancel,
    }: Partial<UseConfirmDialogProps> = {}) =>
      new Promise<boolean>((resolve, reject) => {
        openDialog('ConfirmDialog', {
          title,
          titleI18nParams,
          content,
          cancel,
          confirm,
          variant,
          onConfirm: async () => {
            try {
              await onConfirm?.();
              resolve(true);
            } catch (ex) {
              reject(ex);
            }
          },
          onCancel: async () => {
            try {
              await onCancel?.();
              resolve(false);
            } catch (ex) {
              reject(ex);
            }
          },
        });
      }),
    [
      openDialog,
      props.title,
      props.titleI18nParams,
      props.confirm,
      props.cancel,
      props.content,
      props.variant,
      props.onConfirm,
      props.onCancel,
    ]
  );
};
