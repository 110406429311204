import { SnackbarsProvider, TrackingProvider, UploadProvider, useAxe, useVersionLog } from '@cofenster/web-components';
import type { FC } from 'react';
import { RouterProvider } from 'react-router-dom';

import pkg from '../package.json';

import { ErrorBoundary } from './components/ErrorBoundary';
import { ClientProvider } from './context/client';
import { ContributorsProvider } from './context/contributors';
import { SelectedAssetIdProvider } from './context/currentAsset';
import { DialogsProvider } from './context/dialogs';
import { ThemeProvider } from './context/theme';
import { useSceneFileUpload } from './hooks/useSceneFileUpload';
import { I18nProvider } from './i18n';
import { router } from './router/Router';

const STAGE = process.env.STAGE as string;
const WEB_ACTOR_SEGMENT_WRITE_KEY = process.env.WEB_ACTOR_SEGMENT_WRITE_KEY as string;

export const App: FC = () => {
  useVersionLog(pkg);
  useAxe(STAGE);

  return (
    <ClientProvider>
      <ThemeProvider>
        <I18nProvider>
          <ErrorBoundary>
            <TrackingProvider segmentWriteKey={WEB_ACTOR_SEGMENT_WRITE_KEY}>
              <ContributorsProvider>
                <SelectedAssetIdProvider>
                  <UploadProvider useUpload={useSceneFileUpload} warnBeforeUnload>
                    <SnackbarsProvider>
                      <DialogsProvider>
                        <RouterProvider router={router} />
                      </DialogsProvider>
                    </SnackbarsProvider>
                  </UploadProvider>
                </SelectedAssetIdProvider>
              </ContributorsProvider>
            </TrackingProvider>
          </ErrorBoundary>
        </I18nProvider>
      </ThemeProvider>
    </ClientProvider>
  );
};
