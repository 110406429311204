import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useAdditionalTrackingContext, useTracking as useTrackingHook } from '@cofenster/web-components';

import { useContributionRequestListDetails } from '../contributionRequestListDetails/useContributionRequestListDetails';

export const useTracking = () => {
  const { requestId: paramRequestId } = useParams() as { requestId: string };
  const additionalTrackingContext = useAdditionalTrackingContext();
  const context = useTrackingHook();

  const contributionRequestListDetails = useContributionRequestListDetails();
  const request = contributionRequestListDetails?.contributionRequestList.requests.find(
    ({ id }) => id === paramRequestId
  );

  if (!context) throw new Error('Tracking provider missing in tree');

  const { trackEvent: originalTrackEvent } = context;
  type TrackEvent = typeof originalTrackEvent;

  const accountId = contributionRequestListDetails?.account.id;
  const projectId = contributionRequestListDetails?.project.id;
  const projectOwnerId = contributionRequestListDetails?.user.id;
  const projectName = contributionRequestListDetails?.project.name;

  const requestListId = contributionRequestListDetails?.contributionRequestList.id;
  const requestListName = contributionRequestListDetails?.contributionRequestList.name;

  const requestId = request?.id;
  const requestType = request?.type;
  const requestVisibility = request?.showOthersContributions ? 'visible' : 'hidden';
  const requestRecordingCount = (request?.imageAssets.length ?? 0) + (request?.videoAssets.length ?? 0);
  const title = request?.title;
  const description = request?.instruction;

  const trackEvent: TrackEvent = useCallback(
    (track) => {
      const { details, ...rest } = track;

      originalTrackEvent({
        details: {
          accountId,
          projectId,
          projectOwnerId,
          projectName,
          ...(requestListId
            ? {
                requestListId,
                requestListName,
              }
            : {}),
          ...(requestId && {
            requestId,
            requestType,
            requestVisibility,
            requestRecordingCount,
            title,
            description,
          }),
          ...details,
          ...additionalTrackingContext,
        },
        ...rest,
      });
    },
    [
      originalTrackEvent,
      accountId,
      projectId,
      projectOwnerId,
      projectName,
      requestListId,
      requestListName,
      requestId,
      requestType,
      requestVisibility,
      requestRecordingCount,
      title,
      description,
      additionalTrackingContext,
    ]
  );

  return useMemo(() => ({ ...context, trackEvent }), [context, trackEvent]);
};
