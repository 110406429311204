import type { FC } from 'react';

import { IconButton } from '../../../../controls/Button/IconButton';

export type ToggleFrameButtonProps = {
  showFraming: boolean;
  toggleFraming: () => unknown;
};

export const ToggleFrameButton: FC<ToggleFrameButtonProps> = ({ showFraming, toggleFraming }) => {
  const iconType = showFraming ? 'CircleDashedIcon' : 'CircleDashedSlashIcon';
  const tooltip = showFraming ? 'i18n.global.framing.disable' : 'i18n.global.framing.enable';
  return (
    <IconButton
      icon={iconType}
      label="i18n.global.framing.toggle"
      tooltip={tooltip}
      iconColor={showFraming ? 'blue' : 'white'}
      backgroundColor={showFraming ? 'white' : 'blurred'}
      hoverColor={showFraming ? 'white' : undefined}
      hoverBackgroundColor={showFraming ? 'carbon' : undefined}
      data-testid="framing-toggle-button"
      onClick={toggleFraming}
      aria-pressed={showFraming}
    />
  );
};
