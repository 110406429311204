import { useEffect } from 'react';
import { useTracking } from '../../context/tracking';

export const RequestViewedTracker = () => {
  const tracking = useTracking();
  useEffect(() => {
    tracking.trackEvent({
      event: 'requestViewed',
    });
  }, [tracking]);
  return null;
};
