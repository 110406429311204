import type { FC } from 'react';
import type { BackgroundEffect, UseMediaDevicesDevice } from '../../../../..';
import {
  BackgroundEffectButton,
  PauseResumeButton,
  RecordButton,
  RecordingLayout,
  RetakeButton,
  SelectCameraButton,
  TimeRecordingIndicator,
} from '../components';
import type { SelectedMediaDevice } from '../components/MicrophonePopOver';
import { DeleteButton } from '../components/ui/DeleteButton';
import { MuteUnmuteButton } from '../components/ui/MuteUnmuteButton';
import { NoAudioTooltip } from '../components/ui/NoAudioTooltip';
import { SelectMicrophone } from '../components/ui/SelectMicrophoneButton';
import { SystemAudioMutedTooltip } from '../components/ui/SystemAudioMutedTooltip';
import { ToggleFrameButton } from '../components/ui/ToggleFrameButton';

export type RecordingBarProps = {
  isCompact?: boolean;
  status: 'inactive' | 'recording' | 'paused';
  isDisabled?: boolean;

  recordingDuration?: number;

  onStart?: () => unknown;
  onStop?: () => unknown;
  onPause?: () => unknown;
  onResume?: () => unknown;

  onDelete?: () => unknown;
  onRetake?: () => unknown;

  availableCameras?: UseMediaDevicesDevice[];
  selectedCamera?: SelectedMediaDevice;
  selectCamera?: (device: SelectedMediaDevice) => unknown;

  availableMicrophones?: UseMediaDevicesDevice[];
  selectedMicrophone?: SelectedMediaDevice;
  selectMicrophone?: (device: SelectedMediaDevice) => unknown;
  muted?: boolean;
  setMuted?: (muted: boolean) => unknown;

  // Framing Props
  showFraming?: boolean;
  toggleFraming?: () => unknown;

  backgroundEffect?: BackgroundEffect;
  onBackgroundEffectSelect?: (value: BackgroundEffect) => unknown;
  isBackgroundEffectInitializing?: boolean;
};

export const RecordingBar: FC<RecordingBarProps> = ({
  isCompact,
  status,
  isDisabled,
  recordingDuration,
  onStart,
  onStop,
  onPause,
  onResume,
  availableMicrophones,
  selectedMicrophone,
  selectMicrophone,
  muted = false,
  setMuted,
  availableCameras,
  selectedCamera,
  selectCamera,
  onDelete,
  onRetake,
  showFraming,
  toggleFraming,
  backgroundEffect,
  onBackgroundEffectSelect,
  isBackgroundEffectInitializing,
}) => {
  // Statuses
  const isRecording = status === 'recording';
  const isPaused = status === 'paused';
  const isInactive = status === 'inactive';

  // If the stream is paused, it means it was started to begin with. Therefore,
  // pressing the record button should end the recording to allow ending from a
  // paused state. If the stream is not paused, whether the record button should
  // start recording or stop recording depends on whether it is recording yet.
  const onRecord = isPaused ? onStop : isRecording ? onStop : onStart;
  const onPauseResume = isPaused ? onResume : onPause;

  return (
    <RecordingLayout
      isCompact={isCompact}
      mainButton={<RecordButton onRecord={onRecord} isRecording={isRecording} isDisabled={isDisabled} />}
      leftButtons={
        <>
          {!isInactive && onPauseResume && <PauseResumeButton onPauseResume={onPauseResume} status={status} />}

          {isInactive && selectCamera && (
            <SelectCameraButton
              devices={availableCameras}
              onSelectDevice={selectCamera}
              selectedDevice={selectedCamera}
              disabled={false}
            />
          )}

          {isInactive && selectMicrophone && (
            <SystemAudioMutedTooltip openOnHover>
              {({ isOpen, close }) => (
                <SelectMicrophone
                  devices={availableMicrophones}
                  onSelectDevice={selectMicrophone}
                  selectedDevice={selectedMicrophone}
                  disabled={false}
                  disableHoverListener={isOpen}
                  onClick={close}
                />
              )}
            </SystemAudioMutedTooltip>
          )}

          {!isInactive && setMuted && (
            <NoAudioTooltip isMuted={muted}>
              <SystemAudioMutedTooltip openOnHover>
                {({ isOpen, isSystemMuted }) => (
                  <MuteUnmuteButton
                    muted={muted || isSystemMuted}
                    onMuteUnmute={setMuted}
                    disabled={isSystemMuted}
                    disableHoverListener={isOpen}
                  />
                )}
              </SystemAudioMutedTooltip>
            </NoAudioTooltip>
          )}
        </>
      }
      rightButtons={
        <>
          {toggleFraming && <ToggleFrameButton toggleFraming={toggleFraming} showFraming={!!showFraming} />}
          {isInactive && onBackgroundEffectSelect && (
            <BackgroundEffectButton
              selectedEffect={backgroundEffect}
              onEffectSelect={onBackgroundEffectSelect}
              isInitializing={isBackgroundEffectInitializing}
            />
          )}
          {!isInactive && onRetake && <RetakeButton onRetake={onRetake} />}
          {!isInactive && onDelete && <DeleteButton onDelete={onDelete} />}
        </>
      }
      top={
        typeof recordingDuration !== 'undefined' &&
        recordingDuration > 0 && (
          <TimeRecordingIndicator recordingDuration={recordingDuration} isRecording={isRecording} />
        )
      }
    />
  );
};
