import { type FC, useCallback, useEffect, useMemo, useState } from 'react';

import { VIDEO_FORMATS, type VideoFormat } from '@cofenster/constants';
import {
  AudioAnalysisStreamProvider,
  type BackgroundEffect,
  Box,
  FramingSuggestions,
  RecordingBar,
  SUPPORTS_TRANSFORMING_STREAMS,
  type SegmentTracking,
  useBackgroundEffect,
  useFeatureFlags,
  useI18n,
  useMediaStreamRecorder,
  useMergedMediaStream,
  useMuteMediaStream,
  usePersistedState,
  useUserMediaStream,
} from '@cofenster/web-components';
import type { CaptureAssetMetadata } from '../../../../../context/captureAssetFile';
import { useDialogs } from '../../../../../context/dialogs/useDialogs';
import { useTracking } from '../../../../../context/tracking';
import { useMediaDevice } from '../../../../../hooks/media/useMediaDevice';
import { useNavigateBack } from '../../../../../hooks/useNavigateBack';
import type { DeleteRecordingDialogProps } from '../../../../dialog';
import { FormatAwareContentArea } from '../../../../layout';
import { useCaptureBackButtonBehavior } from '../../../BackButtonBehavior';
import { useCaptureAssetLifecycleFlow } from '../../../CaptureAssetLifecycleFlow';
import { useSetBackgroundEffectWithTracking } from '../../../useSetBackgroundEffectWithTracking';
import { PreviewStream } from '../../CoverVideo';
import { useStartRecordingWithNoMicInfo } from '../../useStartRecordingWithNoMicInfo';
import { CameraHint } from '../CameraHint';
import { Countdown } from '../Countdown';
import { CameraPermissionError } from './CameraPermissionError';

export type WebcamRecorderProps = {
  closeRecorder?: () => unknown;
  setIsRecording?: (status: boolean) => void;
  onRecordingStopped: (recordedFile: { url: string; blob: File }, metadata: CaptureAssetMetadata) => unknown;
  showFramingSuggestions: boolean;
};

export const WebcamRecorder: FC<WebcamRecorderProps> = ({
  closeRecorder,
  onRecordingStopped,
  setIsRecording,
  showFramingSuggestions,
}) => {
  const [isCountingDown, setIsCountingDown] = useState(false);
  const { openDialog } = useDialogs();
  const { translate } = useI18n();
  const tracking = useTracking();
  const { hasFeature } = useFeatureFlags();
  const { format: videoFormat } = useCaptureAssetLifecycleFlow();

  const [availableMicrophones, selectedMicrophone, selectMicrophone] = useMediaDevice('audioinput');
  const [availableCameras, selectedCamera, selectCamera] = useMediaDevice('videoinput');

  const [backgroundEffect, setBackgroundEffect] = usePersistedState<BackgroundEffect>(
    'background-effect',
    'NONE',
    true
  );
  const setBackgroundEffectWithTracking = useSetBackgroundEffectWithTracking(tracking, setBackgroundEffect);

  const microphoneConstraints = useMemo(
    () => ({ audio: selectedMicrophone ? { deviceId: selectedMicrophone.deviceId } : false }),
    [selectedMicrophone]
  );
  const { value: microphoneStream } = useUserMediaStream(microphoneConstraints);

  const { value: cameraStream } = useUserMediaStream(
    useMemo(
      () => ({
        video: selectedCamera
          ? {
              deviceId: selectedCamera.deviceId,
              // The frame rate appears to be capped to 20 when in Cypress
              frameRate: { min: 'Cypress' in window ? 20 : 24, ideal: 30 },
              aspectRatio: { exact: VIDEO_FORMATS[videoFormat].aspectRatio },
              width: { ideal: VIDEO_FORMATS[videoFormat].width },
              height: { ideal: VIDEO_FORMATS[videoFormat].height },
            }
          : false,
      }),
      [videoFormat, selectedCamera]
    )
  );

  useWarnAboutIncorrectCameraStream(cameraStream, videoFormat, tracking);

  const [muted, setMuted] = useMuteMediaStream(microphoneStream);

  const { output: videoStream, isInitializing: isBackgroundEffectInitializing } = useBackgroundEffect(
    cameraStream,
    backgroundEffect,
    videoFormat
  );
  const mediaStream = useMergedMediaStream(videoStream, microphoneStream);

  const mediaStreamRecorder = useMediaStreamRecorder(mediaStream);

  useEffect(() => {
    if (mediaStreamRecorder) {
      if (mediaStreamRecorder.state === 'inactive' && mediaStreamRecorder.bytesAvailable > 0) {
        const file = mediaStreamRecorder.asFile(translate('ScenePage.WebcamRecorder.filename'));
        onRecordingStopped(
          {
            url: URL.createObjectURL(file),
            blob: file,
          },
          {
            uploadSource: 'webcam-recording',
            cameraEnabled: Boolean(selectedCamera),
            microphoneEnabled: Boolean(selectedMicrophone),
            effects: backgroundEffect,
          }
        );
      }
    }
  }, [
    mediaStreamRecorder,
    mediaStreamRecorder?.bytesAvailable,
    mediaStreamRecorder?.state,
    selectedCamera,
    selectedMicrophone,
    onRecordingStopped,
    translate,
    backgroundEffect,
  ]);

  useEffect(() => {
    if (!setIsRecording) return;
    if (mediaStreamRecorder?.state === 'recording') {
      setIsRecording(true);
      return () => setIsRecording(false);
    }
    setIsRecording(false);
  }, [mediaStreamRecorder?.state, setIsRecording]);

  const openDeleteDialog = useCallback(
    (props: Pick<DeleteRecordingDialogProps, 'onAction'>) => openDialog('DeleteRecordingDialog', props),
    [openDialog]
  );

  const navigateBack = useNavigateBack();

  const onCancel = useCallback(() => {
    if (mediaStreamRecorder?.state === 'recording') {
      openDeleteDialog({
        onAction: () => {
          mediaStreamRecorder.cancel();
        },
      });
    }
  }, [mediaStreamRecorder, openDeleteDialog]);

  const goBack = useCallback(() => {
    if (mediaStreamRecorder?.state === 'recording') {
      openDialog('DeleteRecordingDialog', {
        onAction: () => {
          mediaStreamRecorder.cancel();
          closeRecorder?.();
          navigateBack();
        },
      });
    } else {
      navigateBack();
    }
  }, [mediaStreamRecorder?.state, mediaStreamRecorder?.cancel, openDialog, closeRecorder, navigateBack]);

  useCaptureBackButtonBehavior(goBack);

  const [showFraming, setShowFraming] = useState(true);
  const toggleFraming = useCallback(() => {
    tracking.trackEvent({
      event: 'recordSettingUpdated',
      details: {
        settingType: 'framing',
        settingName: showFraming ? 'off' : 'on',
      },
    });
    setShowFraming(!showFraming);
  }, [showFraming, tracking]);

  const renderFramingUI = mediaStreamRecorder?.state === 'inactive' && !isCountingDown && showFramingSuggestions;

  const { onStart } = useStartRecordingWithNoMicInfo(availableMicrophones, selectedMicrophone, () =>
    setIsCountingDown(true)
  );

  const onStop = useCallback(() => {
    // https://caniuse.com/auxclick
    const isSafari = !('auxclick' in HTMLElement.prototype);
    // https://bugs.webkit.org/show_bug.cgi?id=276536
    if (isSafari) {
      mediaStreamRecorder?.stop();
    }

    if (cameraStream) cameraStream.getTracks().forEach((track) => track.stop());
    if (microphoneStream) microphoneStream.getTracks().forEach((track) => track.stop());
  }, [mediaStreamRecorder, cameraStream, microphoneStream]);

  return (
    <AudioAnalysisStreamProvider microphoneConstraints={microphoneConstraints}>
      <FormatAwareContentArea>
        {availableCameras !== undefined ? (
          <Box fullHeight backgroundColor="carbon">
            {mediaStream && <PreviewStream stream={videoStream} mirror={true} />}

            {isCountingDown && mediaStreamRecorder && (
              <>
                <Countdown
                  onEnd={() => {
                    setIsCountingDown(false);
                    mediaStreamRecorder.start();
                  }}
                />
                <CameraHint />
              </>
            )}

            {renderFramingUI && showFraming && <FramingSuggestions videoFormat={videoFormat} facingMode="user" />}
            <RecordingBar
              isCompact={videoFormat !== 'Horizontal'}
              status={mediaStreamRecorder?.state ?? 'inactive'}
              isDisabled={!mediaStream}
              recordingDuration={mediaStreamRecorder?.timeRecorded}
              onStart={isCountingDown ? undefined : onStart}
              onStop={onStop}
              onPause={mediaStreamRecorder?.pause}
              onResume={mediaStreamRecorder?.resume}
              muted={muted}
              setMuted={selectedMicrophone ? setMuted : undefined}
              onDelete={onCancel}
              onRetake={onCancel}
              availableMicrophones={availableMicrophones}
              selectedMicrophone={selectedMicrophone}
              selectMicrophone={selectMicrophone}
              availableCameras={availableCameras}
              selectedCamera={selectedCamera}
              selectCamera={selectCamera}
              showFraming={showFraming}
              toggleFraming={renderFramingUI ? toggleFraming : undefined}
              backgroundEffect={backgroundEffect}
              onBackgroundEffectSelect={
                SUPPORTS_TRANSFORMING_STREAMS && hasFeature('VIRTUAL_BACKGROUNDS')
                  ? setBackgroundEffectWithTracking
                  : undefined
              }
              isBackgroundEffectInitializing={isBackgroundEffectInitializing}
            />
          </Box>
        ) : (
          <CameraPermissionError />
        )}
      </FormatAwareContentArea>
    </AudioAnalysisStreamProvider>
  );
};

const useWarnAboutIncorrectCameraStream = (
  cameraStream: MediaStream | null,
  videoFormat: VideoFormat,
  tracking: SegmentTracking
) => {
  useEffect(() => {
    if (!cameraStream) return;

    const videoTrack = cameraStream.getVideoTracks()[0];
    if (!videoTrack) return;

    const { aspectRatio, width, height } = videoTrack.getSettings();
    const format = VIDEO_FORMATS[videoFormat];
    const differentAspectRatio = aspectRatio !== format.aspectRatio;
    const smallerThanHalfWidth = (width ?? 0) < format.width / 2;
    const smallerThanHalfHeight = (height ?? 0) < format.height / 2;
    if (differentAspectRatio || smallerThanHalfWidth || smallerThanHalfHeight) {
      console.warn('Incorrect camera stream acquired', {
        width,
        height,
        aspectRatio,
        videoFormat,
      });
      tracking.trackEvent({
        event: 'incorrectCameraStreamAcquired',
        details: {
          width,
          height,
          aspectRatio,
          videoFormat,
        },
      });
    }
  }, [cameraStream, videoFormat, tracking]);
};
