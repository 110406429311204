import {
  BlankButton,
  Form,
  FormTextField,
  IconButton,
  Responsive,
  Typography,
  styled,
} from '@cofenster/web-components';
import { useFormikContext } from 'formik';
import { type FC, type PropsWithChildren, useCallback } from 'react';
import * as Yup from 'yup';

type Contributor = { name: string; email: string };
type Values = { contributors: Contributor[] };

const validationSchema: Yup.ObjectSchema<Values> = Yup.object().shape({
  contributors: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().trim().required('i18n.WelcomePage.contributors.form.name.required'),
        email: Yup.string()
          .trim()
          .email('i18n.Form.error.email.format')
          .matches(/@[\w-.]+\.[a-z]{2,}$/, 'i18n.Form.error.email.format')
          .required('i18n.WelcomePage.contributors.form.email.required'),
      })
    )
    .required('i18n.form.error.generic.required'),
});

const StyledForm = styled(Form)({ height: '100%' });

const ButtonText = styled(BlankButton)(({ theme }) => ({
  color: theme.palette.brand.blue,
  textDecoration: 'underline',

  '&:focus-visible': theme.mixins.focusRing,

  '&:hover': {
    color: theme.palette.brand.blueDark,
  },
}));

const ContributorDetailsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  width: '100%',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: 0,
  },
}));

const useRemoveContributor = () => {
  const { setValues } = useFormikContext<Values>();
  return useCallback(
    (index: number) =>
      setValues(({ contributors }) => ({ contributors: contributors.filter((_, i) => i !== index) }), true),
    [setValues]
  );
};

const ContributorDetails: FC<{ index: number }> = ({ index }) => {
  const removeContributor = useRemoveContributor();

  return (
    <ContributorDetailsContainer>
      <FormTextField
        id={`contributor-${index}-name`}
        name={`contributors.${index}.name`}
        autoComplete="name"
        label="i18n.WelcomePage.contributors.form.name"
        fullWidth
        data-testid={`contributors-form-${index}-name`}
      />
      <FormTextField
        id={`contributor-${index}-email`}
        name={`contributors.${index}.email`}
        autoComplete="email"
        label="i18n.WelcomePage.contributors.form.email"
        fullWidth
        data-testid={`contributors-form-${index}-email`}
      />
      {index !== 0 && (
        <>
          <Responsive up="sm">
            <IconButton
              icon="CloseIcon"
              type="button"
              onClick={() => removeContributor(index)}
              label="i18n.WelcomePage.contributors.form.remove"
            />
          </Responsive>
          <Responsive down="sm">
            <ButtonText type="button" onClick={() => removeContributor(index)}>
              <Typography variant="l">i18n.WelcomePage.contributors.form.remove</Typography>
            </ButtonText>
          </Responsive>
        </>
      )}
    </ContributorDetailsContainer>
  );
};

const useAddContributor = () => {
  const { setValues } = useFormikContext<Values>();
  return useCallback(
    () => setValues(({ contributors }) => ({ contributors: [...contributors, { name: '', email: '' }] }), true),
    [setValues]
  );
};

const AddContributor: FC = () => {
  const addContributor = useAddContributor();

  return (
    <Typography
      variant="l"
      component="p"
      i18nParams={{
        link: (chunks) => (
          <ButtonText type="button" onClick={addContributor}>
            {chunks}
          </ButtonText>
        ),
      }}
    >
      i18n.WelcomePage.contributors.form.add
    </Typography>
  );
};

export const ContributorsDetails: FC = () => {
  const {
    values: { contributors },
  } = useFormikContext<Values>();
  return (
    <>
      {contributors.map((_, index) => (
        <ContributorDetails key={index} index={index} />
      ))}
      <AddContributor />
    </>
  );
};

const DEFAULT_INITIAL_VALUES = { contributors: [{ name: '', email: '' }] };

export const ContributorsForm: FC<
  PropsWithChildren<{ initialValues?: Values; onSubmit?: (value: Values) => unknown }>
> = ({ children, initialValues = DEFAULT_INITIAL_VALUES, onSubmit = console.warn }) => {
  return (
    <StyledForm
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        onSubmit(values);
      }}
    >
      {children}
    </StyledForm>
  );
};
