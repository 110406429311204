import { type Dispatch, type SetStateAction, useCallback, useMemo, useSyncExternalStore } from 'react';

type JSONSerializable = null | string | number | boolean | { [key: string]: JSONSerializable } | JSONSerializable[];

const parseMaybeJSON = <T extends JSONSerializable>(maybeJSON: string | null, defaultValue: T) =>
  maybeJSON === null ? defaultValue : (JSON.parse(maybeJSON) as T);

export const useBrowserStorageState = <T extends JSONSerializable>(
  key: string,
  defaultValue: T,
  session = false
): [T, Dispatch<SetStateAction<T>>] => {
  const storageArea = session ? sessionStorage : localStorage;

  const get = useCallback(() => storageArea.getItem(key), [key, storageArea]);

  const subscribe = useCallback(
    (callback: () => unknown) => {
      const listener = (event: StorageEvent) => {
        if (event.key === key && event.storageArea === storageArea) {
          callback();
        }
      };
      window.addEventListener('storage', listener);
      return () => window.removeEventListener('storage', listener);
    },
    [key, storageArea]
  );

  const storeValue = useSyncExternalStore(subscribe, get);

  const value = useMemo(() => parseMaybeJSON(storeValue, defaultValue), [storeValue, defaultValue]);

  const setStateLike = useCallback<Dispatch<SetStateAction<T>>>(
    (valueOrFn) => {
      if (typeof valueOrFn === 'function') {
        const oldStoreValue = get();
        const oldValue = parseMaybeJSON(oldStoreValue, defaultValue);
        const newValue = valueOrFn(oldValue);
        const newStoreValue = JSON.stringify(newValue);
        storageArea.setItem(key, newStoreValue);
        window.dispatchEvent(
          new StorageEvent('storage', {
            key,
            storageArea: storageArea,
            oldValue: oldStoreValue,
            newValue: newStoreValue,
            url: window.location.href,
          })
        );
        return newValue;
      }
      const oldStoreValue = get();
      const newStoreValue = JSON.stringify(valueOrFn);
      storageArea.setItem(key, newStoreValue);
      window.dispatchEvent(
        new StorageEvent('storage', {
          key,
          storageArea: storageArea,
          oldValue: oldStoreValue,
          newValue: newStoreValue,
          url: window.location.href,
        })
      );
      return valueOrFn;
    },
    [key, storageArea, get, defaultValue]
  );

  return [value, setStateLike];
};
