import { useEffect, useMemo } from 'react';

/**
 * Prevents browser throttling timers
 * @see: https://developer.chrome.com/blog/timer-throttling-in-chrome-88/#minimal-throttling
 * @see: https://marcgg.com/blog/2016/11/01/javascript-audio/
 */
export const useSilentBackgroundAudio = (on = false) => {
  const [audio, audioContext, source] = useMemo(() => {
    const audio = new Audio();
    audio.autoplay = true;
    audio.loop = true;

    const audioContext = new AudioContext();
    const source = audioContext.createMediaElementSource(audio);
    return [audio, audioContext, source] as const;
  }, []);

  useEffect(() => {
    return () => {
      source.disconnect();
      audioContext.close();
    };
  }, [audioContext, source]);

  useEffect(() => {
    if (audio && audioContext && on) {
      const oscillator = audioContext.createOscillator();
      const gainNode = audioContext.createGain();
      oscillator.connect(gainNode);
      gainNode.gain.value = 0.001;
      oscillator.frequency.value = 100;
      oscillator.type = 'triangle';
      gainNode.connect(audioContext.destination);
      oscillator.start();
      audio.play().catch(console.error); // should be caught or else it prints an error to the console

      return () => {
        oscillator.stop();
        oscillator.disconnect();
        gainNode.disconnect();
        audio.pause();
      };
    }
  }, [audio, audioContext, on]);
};
