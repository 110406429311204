import { type RefObject, useEffect } from 'react';

// There is an iOS Safari specific issue with displaying the thumbnail of video
// elements. A work around is to append a media fragment to the source (e.g.
// `#t=0.01`), but this does not work for blob URLs. Instead, we need to preload
// the metadata and manually move the time to be non-zero.
// See: https://www.notion.so/cofenster/CoCapture-Thumbnail-missing-from-preview-initially-on-iOS-Safari-05ed0b4ef62a4452b963c5628c744e7c?pvs=4
// See: https://stackoverflow.com/a/74231942
export const useSeekOnLoadedMetadata = (videoRef: RefObject<HTMLVideoElement | null>, time = 0.1) => {
  useEffect(() => {
    const video = videoRef.current;

    if (!video) return;

    const setInitialTime = () => {
      video.currentTime = time;
    };

    video.addEventListener('loadedmetadata', setInitialTime);

    return () => {
      video.removeEventListener('loadedmetadata', setInitialTime);
    };
  }, [videoRef, time]);
};
