import { styled } from '@mui/material';
import type { FC } from 'react';

import type { BackgroundEffect } from '../../../../hooks/media/backgroundEffect';
import { preventForwardProps } from '../../../../utilities';
import { IconButton } from '../../../controls';
import { BackgroundEffectButton, RecordButton } from '../components';
import { NativeCaptureInput } from '../components/NativeCaptureInput';

const FooterContainer = styled(
  'div',
  preventForwardProps(['orientation'])
)<{ orientation: 'portrait' | 'landscape' }>(({ theme, orientation }) => ({
  position: 'fixed',
  zIndex: theme.zIndex.above,

  display: 'grid',
  gridTemplateColumns: orientation === 'landscape' ? '1fr' : '1fr min-content 1fr',
  gridTemplateRows: orientation === 'landscape' ? '1fr min-content 1fr' : '1fr',
  justifyContent: 'space-around',
  alignItems: 'center',
  flexDirection: orientation === 'landscape' ? 'column-reverse' : 'row',

  top: orientation === 'landscape' ? 0 : undefined,
  bottom: orientation === 'landscape' ? 0 : theme.spacing(3),
  left: orientation === 'portrait' ? 0 : undefined,
  right: orientation === 'portrait' ? 0 : theme.spacing(3),
}));

const Container = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'inherit',
}));

type ImageCaptureBarMobileProps = {
  orientation: 'portrait' | 'landscape';
  flipCamera: VoidFunction;
  handleLibraryUpload: (file: File) => void;
  onCapture: VoidFunction;
  isRecordButtonDisabled?: boolean;
  isFlipButtonDisabled?: boolean;
  backgroundEffect?: BackgroundEffect;
  onBackgroundEffectSelect?: (value: BackgroundEffect) => unknown;
  isBackgroundEffectInitializing?: boolean;
};

export const ImageCaptureBarMobile: FC<ImageCaptureBarMobileProps> = ({
  orientation,
  flipCamera,
  handleLibraryUpload,
  onCapture,
  isRecordButtonDisabled,
  isFlipButtonDisabled,
  backgroundEffect,
  onBackgroundEffectSelect,
  isBackgroundEffectInitializing,
}) => (
  <FooterContainer orientation={orientation}>
    <Container>
      <NativeCaptureInput accept="image/*" capture={false} onFile={handleLibraryUpload}>
        {(startCapture) => (
          <IconButton
            iconColor="white"
            backgroundColor="blurred"
            onClick={startCapture}
            icon="ImagesIcon"
            label="i18n.global.capture.start"
          />
        )}
      </NativeCaptureInput>
    </Container>

    <Container>
      <RecordButton onRecord={onCapture} isDisabled={isRecordButtonDisabled} />
    </Container>

    <Container>
      <IconButton
        disabled={isFlipButtonDisabled}
        iconColor="white"
        backgroundColor="blurred"
        onClick={flipCamera}
        icon="SwitchCameraIcon"
        label="i18n.global.flipCamera"
      />
      {onBackgroundEffectSelect && (
        <BackgroundEffectButton
          selectedEffect={backgroundEffect}
          onEffectSelect={onBackgroundEffectSelect}
          isInitializing={isBackgroundEffectInitializing}
        />
      )}
    </Container>
  </FooterContainer>
);
