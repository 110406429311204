import type { FC } from 'react';

import { Box, Icon, Text, styled } from '@cofenster/web-components';

const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const StyledText = styled(Text)(() => ({
  display: 'inline-block',
  maxWidth: 560,
  textAlign: 'center',
}));

export const CameraPermissionError: FC = () => {
  return (
    <StyledBox fullHeight gap={1}>
      <Icon type="WebcamIcon" color="negative" size="xxl" />
      <StyledText variant="xl" color="carbon">
        i18n.ScenePage.CameraPermissionError.text
      </StyledText>
    </StyledBox>
  );
};
