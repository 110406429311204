import { useMemo } from 'react';

import { useDeleteAssetFromContributionRequestByActorMutation } from '../../../api/generated';

export const useDeleteAssetFromContributionRequestByActorPredefined = (
  contributionRequestId: string,
  assetId: string
) => {
  const [deleteAssetFromContributionRequestByActor, { loading }] = useDeleteAssetFromContributionRequestByActorMutation(
    {
      variables: { contributionRequestId, input: { assetId } },
      refetchQueries: ['ContributionRequestListByActor'],
    }
  );

  return useMemo(() => {
    return {
      deleteAssetFromContributionRequestByActor: () => deleteAssetFromContributionRequestByActor(),
      loading,
    };
  }, [deleteAssetFromContributionRequestByActor, loading]);
};

export const useDeleteAssetFromContributionRequestByActor = (contributionRequestId: string) => {
  const [deleteAssetFromContributionRequestByActor, { loading }] =
    useDeleteAssetFromContributionRequestByActorMutation();

  return useMemo(() => {
    return {
      deleteAssetFromContributionRequestByActor: (assetId: string) =>
        deleteAssetFromContributionRequestByActor({
          variables: { contributionRequestId, input: { assetId } },
        }),
      loading,
    };
  }, [deleteAssetFromContributionRequestByActor, loading, contributionRequestId]);
};
