import type { FC, ReactNode } from 'react';

import type { VideoFormat } from '@cofenster/constants';
import { ContentArea, type ContentAreaProps } from '@cofenster/web-components';

import { useCaptureAssetLifecycleFlow } from '../../capture/CaptureAssetLifecycleFlow';

type FormatAwareContentAreaProps = Omit<ContentAreaProps, 'videoFormat' | 'children'> & {
  children: ReactNode | ((format: VideoFormat) => ReactNode);
};

export const FormatAwareContentArea: FC<FormatAwareContentAreaProps> = ({ children, ...rest }) => {
  const { format } = useCaptureAssetLifecycleFlow();
  return (
    <ContentArea {...rest} videoFormat={format}>
      {typeof children === 'function' ? children(format) : children}
    </ContentArea>
  );
};
