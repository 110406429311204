import { preventForwardProps, styled } from '@cofenster/web-components';

import { Content } from '../Content';

// 1. A little odd but the page content container may hold all sorts of
//    absolutely positioned or fixed elements, which need to sit on top of the
//    header and footer.
export const PageContent = styled(
  Content,
  preventForwardProps(['verticallyCentered'])
)<{ verticallyCentered?: boolean }>(({ theme, verticallyCentered }) => ({
  flex: 1,
  position: 'relative',
  alignSelf: 'center',
  padding: theme.spacing(3, 3),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  justifyContent: verticallyCentered ? 'center' : undefined,
  zIndex: theme.zIndex.above, // 1

  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5, 5),
  },
}));
