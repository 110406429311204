import { useEffect } from 'react';
import { useTracking } from '../context/tracking';

export const useTrackBriefingVideoPlayPause = (element: HTMLVideoElement | null) => {
  const tracking = useTracking();

  useEffect(() => {
    const onPlay = () =>
      tracking.trackEvent({
        event: 'briefingVideoPlayed',
        details: { source: 'player' },
      });

    const onPause = () =>
      tracking.trackEvent({
        event: 'briefingVideoPaused',
        details: { source: 'player' },
      });

    element?.addEventListener('play', onPlay);
    element?.addEventListener('pause', onPause);

    return () => {
      element?.removeEventListener('play', onPlay);
      element?.removeEventListener('pause', onPause);
    };
  }, [element, tracking]);
};
