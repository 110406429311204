import { type FC, type ReactEventHandler, useCallback, useRef } from 'react';

import { Box, NativeVideoPlayer, Typography, useFileCache, useSeekOnLoadedMetadata } from '@cofenster/web-components';

import { FormatAwareContentArea } from '../../../../layout';
import { useCaptureAssetLifecycleFlow } from '../../../CaptureAssetLifecycleFlow';
import type { AssetReviewComponentProps } from '../../../types';

import { useCaptureAssetCandidateFileContext } from '../../../../../context/captureAssetFile';
import { WithGapContainer } from '../../../Container';
import { useIsBlobEmpty } from '../../../useIsBlobEmpty';
import { ReviewRecordingInfoAndActions } from './ReviewRecordingInfoAndActions';
import { useReviewRecording } from './useReviewRecording';

// We need to do a duration recalculation due to Chrome's bug,
// in which recorded video's duration is set as Infinity,
// in order to know how long the video is we have to recalculate.
const forceRecalculateVideoDuration: ReactEventHandler<HTMLVideoElement> = (e) => {
  if (e.currentTarget.duration === Number.POSITIVE_INFINITY) {
    e.currentTarget.currentTime = Number.MAX_VALUE;
  } else {
    e.currentTarget.currentTime = Number.EPSILON;
  }
};

export const ReviewRecording: FC<AssetReviewComponentProps> = ({ assetBlob, uploadId, showUploadRetakeMessage }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  useSeekOnLoadedMetadata(videoRef);

  const { captureType } = useCaptureAssetLifecycleFlow();
  const { clearCaptureAssetFile, metadata } = useCaptureAssetCandidateFileContext();
  const { uploadRecording, removeRecording } = useReviewRecording(assetBlob, clearCaptureAssetFile);
  const { setCachedFile, renameCachedFile } = useFileCache('video');

  const submitRecording = useCallback(async () => {
    await setCachedFile(uploadId, assetBlob, metadata);
    await uploadRecording();
    await renameCachedFile(uploadId, `${uploadId}-preview`, 10 * 60 * 1000);

    clearCaptureAssetFile();
  }, [setCachedFile, uploadRecording, renameCachedFile, uploadId, assetBlob, clearCaptureAssetFile, metadata]);

  const { isBlobEmpty } = useIsBlobEmpty(assetBlob);

  return (
    <WithGapContainer>
      <FormatAwareContentArea>
        <Box fullHeight backgroundColor="carbon">
          {isBlobEmpty ? (
            <Typography color="white">i18n.global.error.generic.unknown</Typography>
          ) : (
            <NativeVideoPlayer
              src={URL.createObjectURL(assetBlob)}
              data-testid="video-preview"
              onDurationChange={forceRecalculateVideoDuration}
              objectFit={captureType === 'screenRecording' ? 'contain' : 'cover'}
              preload="metadata"
              ref={videoRef}
              actions={['FULLSCREEN']}
            />
          )}
        </Box>
      </FormatAwareContentArea>
      <ReviewRecordingInfoAndActions
        clearRecording={removeRecording}
        onSubmit={isBlobEmpty ? undefined : submitRecording}
        showUploadRetakeMessage={showUploadRetakeMessage}
      />
    </WithGapContainer>
  );
};
