import { type FC, useCallback } from 'react';

import { Box, IconButton, Typography, detectImageMimeType, styled, useFileCache } from '@cofenster/web-components';

import { useCaptureAssetCandidateFileContext } from '../../../../context/captureAssetFile';
import { useTrackRejectRecording } from '../../../../hooks/useTrackRejectRecording';
import { FormatAwareContentArea } from '../../../layout';
import { useCaptureAssetLifecycleFlow } from '../../CaptureAssetLifecycleFlow';
import { ReviewRecordingInfoAndActions } from '../../CaptureVideo/CaptureRecording/ReviewRecording/ReviewRecordingInfoAndActions';
import { WithGapContainer } from '../../Container';
import type { AssetReviewComponentProps } from '../../types';
import { useIsBlobEmpty } from '../../useIsBlobEmpty';

const IconsWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: theme.spacing(1),
}));

export const ReviewImage: FC<AssetReviewComponentProps> = ({ assetBlob, uploadId, showUploadRetakeMessage }) => {
  const { candidateFile, clearCaptureAssetFile, metadata } = useCaptureAssetCandidateFileContext();
  const { upload } = useCaptureAssetLifecycleFlow();
  const { setCachedFile, renameCachedFile } = useFileCache('image');
  const trackReject = useTrackRejectRecording(metadata, assetBlob, 'image');
  const onReject = useCallback(async () => {
    clearCaptureAssetFile();
    trackReject();
  }, [trackReject, clearCaptureAssetFile]);

  const beginUpload = useCallback(async () => {
    if (!candidateFile) return;
    const fileToUpload = new File(
      [candidateFile.blob],
      candidateFile.blob instanceof File ? candidateFile.blob.name : 'image-capture',
      {
        type:
          candidateFile.blob instanceof File ? candidateFile.blob.type : await detectImageMimeType(candidateFile.blob),
      }
    );

    await setCachedFile(uploadId, candidateFile.blob, metadata);
    await upload(fileToUpload, metadata);
    await renameCachedFile(uploadId, `${uploadId}-preview`, 10 * 60 * 1000);

    clearCaptureAssetFile();
  }, [candidateFile, clearCaptureAssetFile, upload, metadata, setCachedFile, renameCachedFile, uploadId]);

  const { isBlobEmpty } = useIsBlobEmpty(assetBlob);

  return (
    <WithGapContainer>
      <FormatAwareContentArea>
        <Box fullHeight>
          {isBlobEmpty ? (
            <Typography color="white">i18n.global.error.generic.unknown</Typography>
          ) : (
            <img src={URL.createObjectURL(assetBlob)} width="auto" height="100%" alt="" data-testid="image-preview" />
          )}
          <IconsWrapper>
            <IconButton
              icon="DownloadIcon"
              iconSize="m"
              iconColor="white"
              hoverColor="white"
              backgroundColor="carbon_alpha30"
              hoverBackgroundColor="carbon_alpha50"
              target="_blank"
              href={URL.createObjectURL(assetBlob)}
              download
              label="i18n.global.download"
              data-testid="image-preview-download-button"
            />

            <IconButton
              icon="TrashIcon"
              iconSize="m"
              iconColor="white"
              hoverColor="white"
              backgroundColor="carbon_alpha30"
              hoverBackgroundColor="carbon_alpha50"
              onClick={onReject}
              label="i18n.global.delete"
              data-testid="image-preview-retake-button"
            />
          </IconsWrapper>
        </Box>
      </FormatAwareContentArea>

      <ReviewRecordingInfoAndActions
        clearRecording={onReject}
        onSubmit={isBlobEmpty ? undefined : beginUpload}
        showUploadRetakeMessage={showUploadRetakeMessage}
      />
    </WithGapContainer>
  );
};
