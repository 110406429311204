import { styled } from '@cofenster/web-components';

export const Content = styled('main')(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(0, 3),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(0, 5),
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: 960,
    padding: theme.spacing(0, 3),
  },
}));

Content.defaultProps = { id: 'main' };
