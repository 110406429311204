import { useMemo } from 'react';

type MediaTrackSettingsDisplaySurface = 'browser' | 'monitor' | 'window';
export const useDisplayMediaStreamSurface = (mediaStream: MediaStream | null) =>
  useMemo(() => {
    if (!mediaStream) return null;
    const videoTrack = mediaStream.getVideoTracks()[0];
    if (!videoTrack) return null;
    return (videoTrack.getSettings() as { displaySurface: MediaTrackSettingsDisplaySurface }).displaySurface ?? null;
  }, [mediaStream]);
