import { useEffect, useState } from 'react';

export type UseMediaDevicesDevice = MediaDeviceInfo;

export type UseMediaDevicesOptions = {
  type: MediaDeviceKind;
};

const getDevices = async (type: UseMediaDevicesOptions['type']) => {
  try {
    let devices = await navigator.mediaDevices.enumerateDevices();

    if (!devices.every(({ label }) => !!label)) {
      const promptStream = await navigator.mediaDevices.getUserMedia({
        audio: type !== 'videoinput',
        video: type === 'videoinput',
      });
      devices = await navigator.mediaDevices.enumerateDevices();
      promptStream.getTracks().forEach((track) => track.stop());
    }

    return devices.filter(({ kind }) => kind === type);
  } catch {
    return undefined;
  }
};

export const useMediaDevices = ({ type }: UseMediaDevicesOptions) => {
  const [mediaDevices, setMediaDevices] = useState<UseMediaDevicesDevice[] | undefined>([]);

  useEffect(() => {
    const handler = () => getDevices(type).then(setMediaDevices);

    handler();
    navigator.mediaDevices.addEventListener('devicechange', handler);
    return () => navigator.mediaDevices.removeEventListener('devicechange', handler);
  }, [type]);

  return mediaDevices;
};
