import { IconButton, styled } from '@cofenster/web-components';

export const PlayIconButton = styled(IconButton)(({ theme, iconSize }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  color: theme.palette.common.white,
  backgroundColor: theme.palette.brand.carbon_alpha30,
  borderRadius: '50%',
  height: iconSize === 's' ? 32 : 48,
  width: iconSize === 's' ? 32 : 48,
  '&:hover': {
    backgroundColor: theme.palette.brand.carbon_alpha50,
    color: theme.palette.common.white,
  },
}));
