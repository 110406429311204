import { type FC, type PropsWithChildren, createContext, useCallback, useContext, useMemo } from 'react';

import type { VideoFormat } from '@cofenster/constants';

import type { CaptureAssetMetadata } from '../../../context/captureAssetFile';
import { useDialogs } from '../../../context/dialogs/useDialogs';
import type { SupportedDevices } from '../../../hooks/usePermissions';

export type CaptureType = 'camera' | 'screenRecording' | 'screenRecordingStart' | 'image';
export type OnProgress = (state: 'Uploading' | 'Processing', progress: number | undefined) => void;

type CaptureAssetLifecycleFlowProps = {
  format: VideoFormat;
  requiredPermissions: SupportedDevices;
  captureType: CaptureType;
  assetType: 'video' | 'image';
  onDelete(): void;
  upload: (file: File, metadata: CaptureAssetMetadata | undefined, onProgress?: OnProgress) => Promise<unknown>;
  onRetake?: VoidFunction;
  title?: string | null;
  instruction?: string | null;
};

const CaptureAssetLifecycleFlowContext = createContext<CaptureAssetLifecycleFlowProps>(
  null as unknown as CaptureAssetLifecycleFlowProps
);

export const useCaptureAssetLifecycleFlow = () => useContext(CaptureAssetLifecycleFlowContext);

const useOnDeleteWithConfirmation = (onDelete: CaptureAssetLifecycleFlowProps['onDelete']) => {
  const { openDialog } = useDialogs();

  return useCallback(() => {
    openDialog('DeleteContributionDialog', {
      onDelete,
    });
  }, [openDialog, onDelete]);
};

export const CaptureAssetLifecycleFlowProvider: FC<PropsWithChildren<CaptureAssetLifecycleFlowProps>> = ({
  format,
  requiredPermissions,
  upload,
  onDelete,
  onRetake,
  captureType,
  assetType,
  title,
  instruction,
  children,
}) => {
  const onDeleteWithConfirmation = useOnDeleteWithConfirmation(onDelete);

  const contextValue = useMemo(
    () => ({
      format,
      requiredPermissions,
      upload,
      onDelete: onDeleteWithConfirmation,
      captureType,
      assetType,
      title,
      instruction,
      onRetake,
    }),
    [
      format,
      requiredPermissions,
      upload,
      onDeleteWithConfirmation,
      captureType,
      assetType,
      title,
      instruction,
      onRetake,
    ]
  );
  return (
    <CaptureAssetLifecycleFlowContext.Provider value={contextValue}>
      {children}
    </CaptureAssetLifecycleFlowContext.Provider>
  );
};
