import { useCallback } from 'react';

import {
  type SigninActorByCodeMutationResult,
  type SigninActorByCodeMutationVariables,
  useSigninActorByCodeMutation,
} from '../../generated';

export type SigninActorByCodeResult = NonNullable<SigninActorByCodeMutationResult['data']>['signinActorByCode'];

export const useSigninActorByCode = () => {
  const [signinActorByCode] = useSigninActorByCodeMutation();
  return useCallback(
    (code: SigninActorByCodeMutationVariables['code']) => signinActorByCode({ variables: { code } }),
    [signinActorByCode]
  );
};
