import { styled } from '@cofenster/web-components';
import { recordingPageComponentsZIndexes } from '../../capture/recordingPageComponentsZIndexes';

// 1. The `BottomFixedContainer` only displays buttons. There may be instances
//    where two buttons appear in the same row. If any button has long text, it
//    will wrap onto two lines, causing the buttons to have different heights.
//    To prevent this, ensure consistent button height regardless of text length.
export const BottomFixedContainer = styled('div')(({ theme }) => ({
  width: '100%',
  marginTop: 'auto',

  [theme.breakpoints.down('md')]: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: recordingPageComponentsZIndexes.bottomFixedActions,
    padding: theme.spacing(1, 2),
    background: theme.palette.brand.linen,
    borderTop: `1px solid ${theme.palette.brand.linen300}`,
    '& button': {
      height: '100%', // 1
    },
    '& button, & label': {
      textAlign: 'center',
    },
  },
}));
