import { styled } from '@mui/material';

export const BlurredContainer = styled('div')(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  padding: theme.spacing(2),
  borderRadius: theme.shape['borderRadius-l'],
  background: 'rgb(10 36 44 / 0.3)',
  backdropFilter: 'blur(13px)',
}));
