import { useCallback } from 'react';

import type { CaptureAssetMetadata } from '../context/captureAssetFile';
import { useTracking } from '../context/tracking';

export const useTrackRejectRecording = (
  metadata: CaptureAssetMetadata | undefined,
  blob: Blob,
  assetType: 'video' | 'image'
) => {
  const tracking = useTracking();
  return useCallback(() => {
    tracking.trackEvent({
      event: 'RetakeOrRejectAsset',
      details: {
        ...metadata,
        fileType: blob.type,
        fileSize: blob.size,
        fileName: blob instanceof File ? blob.name : undefined,
        assetType,
      },
    });
  }, [tracking, metadata, blob, assetType]);
};
