import type { Breakpoint, Theme } from '@mui/material';
import { handleBreakpoints } from '@mui/system';

// biome-ignore lint/suspicious/noExplicitAny: can legitimately be anything
type Transformer = (value: any) => { [property: string]: any };

export const responsiveStyle = (
  theme: Theme,
  // biome-ignore lint/suspicious/noExplicitAny: there might be a better way
  values: Partial<Record<Breakpoint, any>>,
  propertyOrTransformer: string | Transformer
) => {
  const transformer =
    typeof propertyOrTransformer === 'string'
      ? // biome-ignore lint/suspicious/noExplicitAny: can legitimately be anything
        (value: any) => ({ [propertyOrTransformer]: value })
      : propertyOrTransformer;
  return handleBreakpoints({ theme }, values, transformer);
};
