import { type FC, useEffect, useRef, useState } from 'react';

import { styled } from '@cofenster/web-components';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'absolute',
  inset: 0,
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.brand.carbon_alpha50,
  color: theme.palette.brand.white,
  ...theme.typography['button-m'],
  fontSize: 100,
  fontWeight: 'bold',
}));

export type CountdownProps = {
  time?: number;
  onEnd: () => unknown;
};

export const Countdown: FC<CountdownProps> = ({ time = 3, onEnd }) => {
  const interval = useRef<number | undefined>(undefined);
  const [countdown, setCountDown] = useState(time);

  useEffect(() => {
    if (countdown === 0) {
      window.clearInterval(interval.current);
      onEnd();
    }
  }, [countdown, onEnd]);

  useEffect(() => {
    interval.current = window.setInterval(() => {
      setCountDown((countdown) => countdown - 1);
    }, 1000);

    return () => {
      window.clearInterval(interval.current);
    };
  }, []);

  return <Container data-testid="recording-countdown">{countdown}</Container>;
};
