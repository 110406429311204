import { useCallback, useMemo } from 'react';

import { VIDEO_FORMATS, type VideoFormat } from '@cofenster/constants';
import { type BackgroundEffect, createVideoFromMediaStream, useCanvasPainter } from '@cofenster/web-components';

import type {
  CaptureAssetCandidateFileContextType,
  CaptureAssetMetadata,
  CaptureAssetSource,
} from '../../../context/captureAssetFile';
import { useI18n } from '../../../i18n';

export const useCaptureImage = ({
  mediaStream,
  uploadSource,
  videoFormat,
  save,
  backgroundEffect,
}: {
  mediaStream: MediaStream | null;
  uploadSource: CaptureAssetSource;
  videoFormat?: VideoFormat;
  save: CaptureAssetCandidateFileContextType['onCaptureAssetReadyForReview'];
  backgroundEffect: BackgroundEffect;
}) => {
  const { translate } = useI18n();
  const filename = translate('ScenePage.WebcamImageCapture.filename');
  const metadata = useMemo(() => ({ uploadSource }) as CaptureAssetMetadata, [uploadSource]);
  const canvasPainter = useCanvasPainter(VIDEO_FORMATS[videoFormat ?? 'Horizontal']);

  return useCallback(async () => {
    // It is important to generate the video element *on capture*, and not
    // preemptively in the render, as this causes the picture to be made too
    // early on iOS Safari for some reason.
    // See: https://www.notion.so/cofenster/CoCapture-Picture-is-made-too-early-on-iOS-Safari-a20fd3aa950a4aa8ab6b61a72715471e?pvs=4
    const video = await createVideoFromMediaStream(mediaStream, videoFormat);

    if (!video || !canvasPainter) return;
    canvasPainter.paint(video);
    canvasPainter.asFile(filename).then((file) =>
      save(
        { blob: file, url: URL.createObjectURL(file) },
        {
          ...metadata,
          microphoneEnabled: !!mediaStream?.getAudioTracks()?.length,
          cameraEnabled: !!mediaStream?.getVideoTracks()?.length,
          effects: backgroundEffect,
        }
      )
    );
  }, [canvasPainter, filename, mediaStream, metadata, save, videoFormat, backgroundEffect]);
};
