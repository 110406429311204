import { type FC, useMemo } from 'react';

import { VIDEO_FORMATS } from '@cofenster/constants';
import {
  type BackgroundEffect,
  Box,
  GridContainer,
  GridItem,
  ImageCaptureBar,
  SUPPORTS_TRANSFORMING_STREAMS,
  useBackgroundEffect,
  useFeatureFlags,
  usePersistedState,
  useUserMediaStream,
} from '@cofenster/web-components';

import { useCaptureAssetCandidateFileContext } from '../../../../context/captureAssetFile';
import { useTracking } from '../../../../context/tracking';
import { useMediaDevice } from '../../../../hooks/media/useMediaDevice';
import { FormatAwareContentArea } from '../../../layout';
import { CaptureActions } from '../../CaptureActions';
import { useCaptureAssetLifecycleFlow } from '../../CaptureAssetLifecycleFlow';
import { PreviewStream } from '../../CaptureVideo/CoverVideo';
import { useSetBackgroundEffectWithTracking } from '../../useSetBackgroundEffectWithTracking';
import { CameraPermissionError } from '../CameraPermissionError';
import { useCaptureImage } from '../useCaptureImage';

type BrowserCaptureByWebcamProps = {
  showRecordViaApp: boolean;
};

export const BrowserCaptureByWebcam: FC<BrowserCaptureByWebcamProps> = ({ showRecordViaApp }) => {
  const [availableCameras, selectedCamera, selectCamera] = useMediaDevice('videoinput');
  const { onCaptureAssetReadyForReview } = useCaptureAssetCandidateFileContext();
  const { format: videoFormat } = useCaptureAssetLifecycleFlow();
  const format = VIDEO_FORMATS[videoFormat];
  const { hasFeature } = useFeatureFlags();
  const tracking = useTracking();

  const [backgroundEffect, setBackgroundEffect] = usePersistedState<BackgroundEffect>(
    'background-effect',
    'NONE',
    true
  );
  const setBackgroundEffectWithTracking = useSetBackgroundEffectWithTracking(tracking, setBackgroundEffect);

  const { output: mediaStream, isInitializing: isBackgroundEffectInitializing } = useBackgroundEffect(
    useUserMediaStream(
      useMemo(() => {
        if (!selectedCamera) return null;
        const { width, height, aspectRatio } = format;
        return {
          video: {
            aspectRatio,
            deviceId: selectedCamera.deviceId,
            width: { ideal: width },
            height: { ideal: height },
          },
        };
      }, [selectedCamera, format])
    ).value,
    backgroundEffect,
    videoFormat
  );

  const onCapture = useCaptureImage({
    mediaStream,
    videoFormat,
    save: onCaptureAssetReadyForReview,
    uploadSource: 'webcam-recording',
    backgroundEffect,
  });

  return (
    <>
      <GridContainer spacing={2}>
        <GridItem xs={12}>
          <FormatAwareContentArea>
            {availableCameras ? (
              <Box fullHeight backgroundColor="carbon">
                {mediaStream && <PreviewStream stream={mediaStream} mirror={true} data-testid="camera-preview" />}
                <ImageCaptureBar
                  devices={availableCameras}
                  onSelectDevice={selectCamera}
                  selectedDevice={selectedCamera}
                  backgroundEffect={backgroundEffect}
                  onBackgroundEffectSelect={
                    SUPPORTS_TRANSFORMING_STREAMS && hasFeature('VIRTUAL_BACKGROUNDS')
                      ? setBackgroundEffectWithTracking
                      : undefined
                  }
                  isBackgroundEffectInitializing={isBackgroundEffectInitializing}
                  disabled={false}
                  onCapture={onCapture}
                  isCompact={videoFormat === 'Vertical'}
                />
              </Box>
            ) : (
              <CameraPermissionError />
            )}
          </FormatAwareContentArea>
        </GridItem>

        <CaptureActions showRecordViaApp={showRecordViaApp} type="image" />
      </GridContainer>
    </>
  );
};
