import { useMemo } from 'react';

import { VIDEO_FORMATS, type VideoFormat } from '@cofenster/constants';

import { usePromise } from '../utils/usePromise';

type ElementFactory = Pick<Document, 'createElement'>;

export const useVideoFromMediaStream = (
  stream: MediaStream | null,
  defaultVideoFormat: VideoFormat = 'Horizontal',
  objectFit: 'contain' | 'cover' | 'fill' | 'none' = 'contain',
  elementFactory: ElementFactory = document
) => {
  return usePromise(
    useMemo(
      () => createVideoFromMediaStream(stream, defaultVideoFormat, objectFit, elementFactory),
      [stream, defaultVideoFormat, objectFit, elementFactory]
    )
  ).value;
};

const timeout = (ms: number, reason = 'Timeout') =>
  new Promise<never>((_, reject) => setTimeout(reject, ms, [new Error(reason)]));

const withTimeout =
  (milliseconds: number, reason = 'Timeout') =>
  <T>(promise: Promise<T>) =>
    Promise.race([promise, timeout(milliseconds, reason)]);

export const createVideoFromMediaStream = async (
  stream: MediaStream | null,
  defaultVideoFormat: VideoFormat = 'Horizontal',
  objectFit: 'contain' | 'cover' | 'fill' | 'none' = 'contain',
  elementFactory: ElementFactory = document
) => {
  if (!stream) return null;

  const video = elementFactory.createElement('video');
  video.srcObject = stream;
  video.style.objectFit = objectFit;
  video.controls = false;
  video.playsInline = true;
  video.autoplay = true;

  const format = VIDEO_FORMATS[defaultVideoFormat];
  const size = await withTimeout(5000)(
    new Promise<{ width: number; height: number }>((resolve) => {
      video.onloadedmetadata = () =>
        resolve({
          width: video.videoWidth || format.width,
          height: video.videoHeight || format.height,
        });
    })
  ).catch(() => null);

  if (!size) return null;

  // safari doesn't seem to always autoplay
  video.play().catch(() => undefined);

  video.width = size.width;
  video.height = size.height;

  return video as HTMLVideoElement & { readonly srcObject: MediaStream };
};
