import { styled } from '@mui/material';

export const RedPulsingBall = styled('div')<{ visible: boolean }>(({ theme, visible }) => ({
  display: 'inline-flex',
  width: theme.spacing(1),
  height: theme.spacing(1),
  background: theme.palette.brand.negative,
  borderRadius: '50%',
  opacity: visible ? 1 : 0,
  animation: visible ? 'pulse 1.5s infinite' : undefined,
  '@keyframes pulse': {
    '0%': {
      transform: 'scale(0.90)',
      boxShadow: '0 0 0 0 rgb(0 0 0 / 0.7)',
    },

    '70%': {
      transform: 'scale(1)',
      boxShadow: '0 0 0 3px rgb(0 0 0 / 0)',
    },

    '100%': {
      transform: 'scale(0.90)',
      boxShadow: '0 0 0 0 rgb(0 0 0 / 0)',
    },
  },
}));
