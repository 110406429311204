import { styled } from '@mui/material';
import type { FC } from 'react';

import { BlankButton } from '../../../../controls/Button/BlankButton';
import { VisuallyHidden } from '../../../../utilities/VisuallyHidden';

const ButtonContainer = styled(BlankButton)(({ theme }) => ({
  borderRadius: '50%',
  border: `4px solid ${theme.palette.brand.white}`,
  padding: '6px',
  width: theme.spacing(10),
  height: theme.spacing(10),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '&::before': {
    content: '""',
    backgroundColor: theme.palette.brand.negative,
    height: theme.spacing(7.5),
    width: theme.spacing(7.5),
    borderRadius: '50%',
    transition: '200ms',
  },

  // 1. When recording, transform the red circle into a smaller red square
  '&[aria-pressed="true"]::before': {
    borderRadius: theme.spacing(1), // 1
    transform: 'scale(0.63)', // 1
  },

  '&:not([disabled]):hover::before': {
    backgroundColor: theme.palette.brand.negativeDark,
  },

  '&:focus': {
    outline: 0,
    borderColor: theme.palette.brand.blue,
  },

  '&:disabled': {
    filter: 'grayscale(100%)',
    cursor: 'not-allowed',
  },
}));

type RecordButtonProps = {
  onRecord?: () => unknown;
  isRecording?: boolean;
  isDisabled?: boolean;
};

export const RecordButton: FC<RecordButtonProps> = ({ onRecord, isRecording, isDisabled }) => (
  <ButtonContainer onClick={onRecord} aria-pressed={isRecording} data-testid="record-button" disabled={isDisabled}>
    <VisuallyHidden>i18n.global.record</VisuallyHidden>
  </ButtonContainer>
);
