import { type FC, type PropsWithChildren, useCallback } from 'react';

import {
  type BackgroundEffect,
  BackgroundEffectButton,
  BlankButton,
  IconButton,
  Text,
  preventForwardProps,
  styled,
} from '@cofenster/web-components';

import { useCaptureAssetCandidateFileContext } from '../../../../../../context/captureAssetFile';
import { NativeCaptureInput } from '../../../../../controls';
import { recordingPageComponentsZIndexes } from '../../../../recordingPageComponentsZIndexes';

const recordComponentSize = 82;

const WhiteBox = styled('div')(() => ({
  width: recordComponentSize,
  height: recordComponentSize,
  borderRadius: '50%',
  border: '3px solid white',
  backgroundColor: 'transparent',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 0,
}));

const RedBox = styled(BlankButton)<{ status: 'inactive' | 'recording' | 'paused' }>(({ theme, status }) => {
  const isRecordingAlreadyStarted = status === 'recording' || status === 'paused';
  return {
    width: isRecordingAlreadyStarted ? 38 : 64,
    height: isRecordingAlreadyStarted ? 38 : 64,
    borderRadius: isRecordingAlreadyStarted ? 8 : '50%',
    border: 'none',
    backgroundColor: theme.palette.brand.negative,
    '&:hover': {
      backgroundColor: theme.palette.brand.negativeDark,
    },
  };
});

const RecordingButton = ({
  onClick,
  status,
}: { onClick: () => unknown; status: 'inactive' | 'recording' | 'paused' }) => {
  return (
    <WhiteBox>
      <RedBox status={status} onTouchEnd={onClick} />
    </WhiteBox>
  );
};

const HorizontalFooterContainer = styled('div')(({ theme }) => ({
  gap: theme.spacing(1),
  position: 'fixed',
  right: 30,
  top: 0,
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  zIndex: recordingPageComponentsZIndexes.recordingBarControls,
  justifyContent: 'space-around',
}));

const VerticalFooterContainer = styled('div')(({ theme }) => ({
  gap: theme.spacing(1),
  position: 'fixed',
  bottom: 30,
  left: 0,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  zIndex: recordingPageComponentsZIndexes.recordingBarControls,
  justifyContent: 'space-around',
  alignItems: 'center',
}));

type FooterProps = {
  orientation: string;
};

const FooterContainer: FC<PropsWithChildren<FooterProps>> = ({ orientation, children }) => {
  if (orientation === 'portrait') return <VerticalFooterContainer>{children}</VerticalFooterContainer>;
  return <HorizontalFooterContainer>{children}</HorizontalFooterContainer>;
};

const durationToTime = (msDuration: number): string => {
  const h = Math.floor(msDuration / 1000 / 60 / 60);
  const m = Math.floor((msDuration / 1000 / 60 / 60 - h) * 60);
  const s = Math.floor(((msDuration / 1000 / 60 / 60 - h) * 60 - m) * 60);

  const seconds: string = s < 10 ? `0${s}` : `${s}`;
  const minutes: string = m < 10 ? `0${m}` : `${m}`;

  return `${minutes}:${seconds}`;
};

const ContainerBar = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: theme.palette.brand.carbon_alpha30,
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  minWidth: 64,
}));

const RedBullet = styled('div')(({ theme }) => ({
  width: 8,
  height: 8,
  borderRadius: '50%',
  backgroundColor: theme.palette.brand.negative,
  marginLeft: 4,
}));

const DurationBar = ({ duration }: { duration: number }) => {
  return (
    <ContainerBar>
      <Text variant="s" color="white" tabularNums>
        {durationToTime(duration)}
      </Text>
      <RedBullet />
    </ContainerBar>
  );
};

const ButtonContainer = styled(
  'div',
  preventForwardProps(['orientation'])
)<{ orientation: 'portrait' | 'landscape' }>(({ orientation, theme }) => ({
  display: 'flex',
  alignSelf: 'flex-end',
  flexDirection: orientation === 'portrait' ? 'column' : 'row',
  gap: theme.spacing(1),
}));

const RecordingButtonContainer = styled(
  'div',
  preventForwardProps(['orientation'])
)<{ orientation: 'portrait' | 'landscape' }>(({ orientation, theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: orientation === 'portrait' ? 'column' : 'row',
  gap: theme.spacing(2),
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  margin: theme.spacing(0, 0.5),
  borderRadius: theme.shape.borderRadius,
  height: 56,
  width: 56,
}));

const RecordingBarWithoutDurationContainer = styled('div')<{ orientation: string }>(({ orientation }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  width: orientation === 'landscape' ? undefined : '100%',
  height: orientation === 'landscape' ? '100%' : undefined,
  flexDirection: orientation === 'landscape' ? 'column-reverse' : 'row',
}));

export type RecordingBarMobileProps = {
  status: 'inactive' | 'recording' | 'paused';
  start: VoidFunction;
  stop: VoidFunction;
  recordingDuration?: number;
  flipCamera: () => unknown;
  pauseRecording: () => unknown;
  resumeRecording: () => unknown;
  isReady: boolean;
  isCountingDown: boolean;
  orientation: 'portrait' | 'landscape';
  // Framing Props
  showFraming?: boolean;
  toggleFraming?: () => unknown;
  backgroundEffect?: BackgroundEffect;
  onBackgroundEffectSelect?: (value: BackgroundEffect) => unknown;
  isBackgroundEffectInitializing?: boolean;
};

export const RecordingBarMobile: FC<RecordingBarMobileProps> = ({
  status,
  start,
  stop,
  recordingDuration,
  flipCamera,
  pauseRecording,
  resumeRecording,
  isCountingDown,
  isReady,
  orientation,
  showFraming,
  toggleFraming,
  backgroundEffect,
  onBackgroundEffectSelect,
  isBackgroundEffectInitializing,
}) => {
  const { onCaptureAssetReadyForReview } = useCaptureAssetCandidateFileContext();

  const onPrimaryButtonClick = useCallback(() => {
    if (status === 'recording' || status === 'paused') return stop();
    return start();
  }, [status, start, stop]);

  const handleLibraryUpload = (file: File) => {
    onCaptureAssetReadyForReview(
      {
        blob: file,
        url: URL.createObjectURL(file),
      },
      { uploadSource: 'mobile-library' }
    );
  };

  const isBeforeStartedRecording = status !== 'paused' && status !== 'recording';
  const isPausedOrRecording = status === 'paused' || status === 'recording';

  if (!isReady || isCountingDown) return null;

  return (
    <FooterContainer orientation={orientation}>
      {typeof recordingDuration === 'number' && recordingDuration > 0 && <DurationBar duration={recordingDuration} />}
      <RecordingBarWithoutDurationContainer orientation={orientation}>
        {isBeforeStartedRecording && (
          <ButtonContainer orientation={orientation}>
            <NativeCaptureInput type="video" capture={false} onFile={handleLibraryUpload}>
              {(startCapture) => (
                <StyledIconButton
                  icon="ImagesIcon"
                  label="i18n.ScenePage.RecordingBarMobile.start"
                  onClick={startCapture}
                  iconColor="white"
                  backgroundColor="blurred"
                />
              )}
            </NativeCaptureInput>
          </ButtonContainer>
        )}

        {isPausedOrRecording && (
          <StyledIconButton
            icon={status === 'paused' ? 'PlayIcon' : 'PauseIcon'}
            iconWeight="fill"
            iconSize="l"
            label={
              status === 'paused'
                ? 'i18n.ScenePage.RecordingBarMobile.resume'
                : 'i18n.ScenePage.RecordingBarMobile.pause'
            }
            onClick={status === 'paused' ? resumeRecording : pauseRecording}
            iconColor="white"
            backgroundColor="carbon_alpha30"
            hoverColor="white"
            hoverBackgroundColor="carbon_alpha50"
          />
        )}

        <RecordingButtonContainer orientation={orientation}>
          <RecordingButton onClick={onPrimaryButtonClick} status={status} />
        </RecordingButtonContainer>

        <ButtonContainer orientation={orientation}>
          {!!toggleFraming && (
            <StyledIconButton
              style={{ visibility: isBeforeStartedRecording ? 'visible' : 'hidden' }}
              icon={showFraming ? 'CircleDashedSlashIcon' : 'CircleDashedIcon'}
              label={showFraming ? 'i18n.global.framing.disable' : 'i18n.global.framing.enable'}
              onClick={toggleFraming}
              iconColor="white"
              backgroundColor="blurred"
            />
          )}

          <StyledIconButton
            style={{ visibility: isBeforeStartedRecording ? 'visible' : 'hidden' }}
            icon="SwitchCameraIcon"
            label="i18n.ScenePage.RecordingBarMobile.flipCamera"
            onClick={flipCamera}
            iconColor="white"
            backgroundColor="blurred"
          />

          {isBeforeStartedRecording && onBackgroundEffectSelect && (
            <BackgroundEffectButton
              selectedEffect={backgroundEffect}
              onEffectSelect={onBackgroundEffectSelect}
              isInitializing={isBackgroundEffectInitializing}
            />
          )}
        </ButtonContainer>
      </RecordingBarWithoutDurationContainer>
    </FooterContainer>
  );
};
