import { Card, GridContainer, GridItem } from '@cofenster/web-components';
import { type FC, type PropsWithChildren, useCallback, useEffect } from 'react';
import { useCreateConsents } from '../../api/hooks/consent/useCreateConsents';
import type { VideoAsset } from '../../api/hooks/videoAsset/usePollingVideoAsset';
import { Layout } from '../../components';
import { useContributors } from '../../context/contributors';
import { useTracking } from '../../context/tracking';
import { useI18n } from '../../i18n';
import { WelcomeMessage, WelcomeMessageConsentRequired, WelcomeMessageIdentificationRequired } from './WelcomeMessage';
import { WelcomePicture } from './WelcomePicture';
import { WelcomeVideo } from './WelcomeVideo';

export const WelcomePageContent: FC<{
  accountName: string;
  identificationRequired: { type: 'consent'; documentUrl: string } | { type: 'identification' } | null;
  onContinue: () => unknown;
  projectCreator?: { firstname: string; lastname: string };
  projectId: string;
  projectName: string;
  briefingVideo?: VideoAsset;
  code?: string;
  qrcode?: string;
}> = ({
  accountName,
  identificationRequired,
  onContinue,
  projectCreator,
  projectId,
  projectName,
  briefingVideo,
  code,
  qrcode,
}) => {
  const { locale } = useI18n();
  const createConsents = useCreateConsents();
  const tracking = useTracking();

  const { getByProject, setByProject } = useContributors();
  const contributors = getByProject(projectId);
  const resetContributors = useCallback(() => setByProject(projectId, []), [setByProject, projectId]);

  // Clear data when identification is not required, so we do not store any user
  // data without reason
  useEffect(() => {
    if (!identificationRequired) resetContributors();
  }, [identificationRequired, resetContributors]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: only track once
  useEffect(() => {
    tracking.trackEvent({
      event: 'requestListAccessed',
      details: {
        consentStatus:
          identificationRequired?.type === 'consent'
            ? contributors.length && contributors.every((contributor) => contributor.consent)
              ? 'given'
              : 'required'
            : 'not required',
        contactStatus:
          identificationRequired?.type === 'identification'
            ? contributors.length
              ? 'identified'
              : 'required'
            : 'not required',
        briefingEnabled: !!briefingVideo,
      },
    });
  }, [tracking, identificationRequired, briefingVideo]);

  if (
    identificationRequired?.type === 'consent' &&
    (!contributors.length || contributors.some((contributor) => !contributor.consent))
  ) {
    return (
      <WelcomePageContentWrapper briefingVideo={briefingVideo} code={code} qrcode={qrcode}>
        <WelcomeMessageConsentRequired
          onSignAgreement={async ({ contributors }) => {
            await createConsents(
              projectId,
              contributors.map(({ name, email }) => ({ name, email, locale }))
            );
            tracking.trackEvent({
              event: 'consentFormSigned',
            });
            onContinue();
          }}
          accountName={accountName}
          documentUrl={identificationRequired.documentUrl}
          projectCreator={projectCreator}
          projectId={projectId}
          projectName={projectName}
        />
      </WelcomePageContentWrapper>
    );
  }

  if (identificationRequired?.type === 'identification' && !contributors.length) {
    return (
      <WelcomePageContentWrapper briefingVideo={briefingVideo} code={code} qrcode={qrcode}>
        <WelcomeMessageIdentificationRequired
          onContinue={onContinue}
          projectCreator={projectCreator}
          projectId={projectId}
          projectName={projectName}
        />
      </WelcomePageContentWrapper>
    );
  }

  return (
    <WelcomePageContentWrapper briefingVideo={briefingVideo} code={code} qrcode={qrcode}>
      <WelcomeMessage
        contributors={contributors}
        onContinue={onContinue}
        onResetContributors={resetContributors}
        projectName={projectName}
        projectCreator={projectCreator}
      />
    </WelcomePageContentWrapper>
  );
};

const WelcomePageContentWrapper: FC<
  PropsWithChildren<{
    briefingVideo?: VideoAsset;
    code?: string;
    qrcode?: string;
  }>
> = ({ briefingVideo, code, qrcode, children }) => (
  <Layout code={code} qrcode={qrcode}>
    <GridContainer spacing={{ xs: briefingVideo ? 2 : 0, md: 2 }}>
      <GridItem xs={12} md={5} display="flex" alignItems="center" justifyContent="center">
        {briefingVideo?.videoUrl ? <WelcomeVideo briefingVideo={briefingVideo} /> : <WelcomePicture />}
      </GridItem>
      <GridItem xs={12} md={7} display="flex" flexDirection="column">
        <Card spacing={{ all: 5 }}>{children}</Card>
      </GridItem>
    </GridContainer>
  </Layout>
);
