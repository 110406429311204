import { type PropsWithChildren, type Ref, forwardRef } from 'react';
import { Link, type LinkProps, type Params } from 'react-router-dom';

import { generatePath } from '@cofenster/web-components';

import { type RouteName, routes } from '../../../router/routes';

export type RouterLinkProps = Omit<LinkProps, 'to'> & {
  to: RouteName;
  params?: Params;
};

export const RouterLink = forwardRef<typeof Link, PropsWithChildren<RouterLinkProps>>(
  ({ children, to, params, ...rest }, ref) => (
    <Link
      {...rest}
      to={generatePath(routes[to], params)}
      ref={ref as Ref<HTMLAnchorElement>}
      rel={rest.target === '_blank' ? 'noopener noreferrer' : undefined}
    >
      {children}
    </Link>
  )
);

RouterLink.displayName = 'RouterLink';
