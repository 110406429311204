import { styled } from '@cofenster/web-components';
import type { FC } from 'react';

const WELCOME_MESSAGE_IMAGE = {
  large: {
    png: '/assets/images/welcome-message.png',
    avif: '/assets/images/welcome-message.avif',
    webp: '/assets/images/welcome-message.webp',
  },
  small: {
    png: '/assets/images/welcome-message-mobile.png',
    avif: '/assets/images/welcome-message-mobile.avif',
    webp: '/assets/images/welcome-message-mobile.webp',
  },
} as const;

const Container = styled('picture')(({ theme }) => ({
  width: '100%',

  display: 'none',
  [theme.breakpoints.up('md')]: {
    display: 'block',
  },

  height: 648,
  [theme.breakpoints.down('md')]: {
    height: 192,
  },
}));

const Picture = styled('img')(({ theme }) => ({
  height: '100%',
  width: '100%',
  borderRadius: theme.spacing(2),
  objectFit: 'cover',
}));

export const WelcomePicture: FC = () => {
  return (
    <Container>
      <source srcSet={WELCOME_MESSAGE_IMAGE.large.avif} media="(min-width: 900px)" type="image/avif" />
      <source srcSet={WELCOME_MESSAGE_IMAGE.large.webp} media="(min-width: 900px)" type="image/webp" />
      <source srcSet={WELCOME_MESSAGE_IMAGE.large.png} media="(min-width: 900px)" />
      <source srcSet={WELCOME_MESSAGE_IMAGE.small.avif} type="image/avif" />
      <source srcSet={WELCOME_MESSAGE_IMAGE.small.webp} type="image/webp" />
      <Picture src={WELCOME_MESSAGE_IMAGE.small.png} alt="" />
    </Container>
  );
};
