import { styled } from '@mui/material';
import { type CSSProperties, type FC, type PropsWithChildren, memo } from 'react';

import { useAudioAnalysisStream } from '../../../../../contexts/audioAnalysisStream/useAudioAnalysisStream';
import { useAudioVolume } from '../../../../../hooks/media/useAudioVolume';
import { preventForwardProps } from '../../../../../utilities/preventForwardProps';
import type { IconType } from '../../../../assets/icons/Icon';
import { IconButton } from '../../../../controls/Button/IconButton';

type MuteUnmuteButtonProps = {
  onMuteUnmute?: (value: boolean) => unknown;
  muted: boolean;
  disabled?: boolean;
  disableHoverListener?: boolean;
};

const tooltipProps = { disableHoverListener: true };

const StyledIconButton = styled(
  IconButton,
  preventForwardProps(['muted'])
)<{ muted: boolean }>(({ muted, theme }) => {
  const volumeColor = muted ? '#910a01' : 'rgb(10 36 44 / 0.6)';
  const noVolumeColor = muted ? theme.palette.brand.negativeDark : 'rgb(10 36 44 / 0.3)';

  return {
    background: `linear-gradient(0deg, ${volumeColor}, ${volumeColor} calc(var(--volume-level) * 100%), ${noVolumeColor} calc(var(--volume-level) * 100%), ${noVolumeColor})`,
  };
});

const MuteUnmuteButtonInner: FC<MuteUnmuteButtonProps> = memo(
  ({ onMuteUnmute, muted, disabled, disableHoverListener }) => {
    const iconType: IconType = muted ? 'MicrophoneOffIcon' : 'MicrophoneIcon';

    return (
      <StyledIconButton
        muted={muted}
        tooltipProps={disableHoverListener ? tooltipProps : undefined}
        onClick={() => onMuteUnmute?.(!muted)}
        disabled={disabled}
        data-testid="mute-unmute-button"
        icon={iconType}
        iconColor="white"
        iconWeight="fill"
        hoverColor={muted ? 'white' : undefined}
        backgroundColor={muted ? 'negative' : 'blurred'}
        hoverBackgroundColor={muted ? 'negativeDark' : undefined}
        label={muted ? 'i18n.global.unmute' : 'i18n.global.mute'}
      />
    );
  }
);

export const MuteUnmuteButton: FC<PropsWithChildren<MuteUnmuteButtonProps>> = memo((props) => {
  const { mediaStream } = useAudioAnalysisStream();
  const { volume } = useAudioVolume(mediaStream, 30, 1);

  return (
    <div
      style={
        {
          '--volume-level': volume,
        } as CSSProperties
      }
    >
      <MuteUnmuteButtonInner {...props} />
    </div>
  );
});
