import { type FC, type ReactNode, useEffect, useMemo } from 'react';

import { FeatureFlagsProvider } from '@cofenster/web-components';

import {
  type ContributionRequestListByActor,
  useContributionRequestListByActor,
} from '../../api/hooks/contributionRequestList/useContributionRequestListByActor';
import { ErrorPage } from '../../pages/ErrorPage';

import { ContributionRequestListDetailsContext } from './ContributionRequestListDetailContext';

export type ContributionRequestListDetailProviderProps = {
  children: (contributionRequestListDetail: ContributionRequestListByActor | undefined) => ReactNode;
};

export const ContributionRequestListDetailProvider: FC<ContributionRequestListDetailProviderProps> = ({ children }) => {
  const { contributionRequestListByActor, loading, error, stopPolling } = useContributionRequestListByActor();
  const featureFlags = useMemo(
    () => contributionRequestListByActor?.featureFlags ?? [],
    [contributionRequestListByActor?.featureFlags]
  );

  const nonNetworkError = error && !error.networkError;
  const unknownState = !loading && !contributionRequestListByActor;

  useEffect(() => {
    if (nonNetworkError) stopPolling();
  }, [nonNetworkError, stopPolling]);

  if (nonNetworkError || unknownState) {
    return <ErrorPage />;
  }

  return (
    <FeatureFlagsProvider featureFlags={featureFlags}>
      <ContributionRequestListDetailsContext.Provider value={contributionRequestListByActor}>
        {children(contributionRequestListByActor)}
      </ContributionRequestListDetailsContext.Provider>
    </FeatureFlagsProvider>
  );
};
