import { useCallback } from 'react';

import type { VideoCaptureSource, VideoFit, VideoFormat } from '@cofenster/constants';
import type { UploadType } from '@cofenster/web-components';

import { useUploadImageAsset } from '../api/hooks/imageAsset/useUploadImageAsset';
import { useUploadVideoAsset } from '../api/hooks/videoAsset/useUploadVideoAsset';

export const useSceneFileUpload = () => {
  const uploadVideo = useUploadVideoAsset();
  const uploadImage = useUploadImageAsset();

  return useCallback(
    async (
      type: UploadType,
      file: File,
      uploadId: string,
      onProgress: (id: string, progress: number) => unknown,
      abortSignal: AbortController['signal'],
      options?: {
        videoFormat?: VideoFormat;
        videoFit?: VideoFit;
        videoCaptureSource?: VideoCaptureSource;
      }
    ): Promise<string | undefined> => {
      switch (type) {
        case 'video':
          return uploadVideo(file, uploadId, onProgress, abortSignal, options);
        case 'image':
          return uploadImage(file, uploadId, onProgress, abortSignal);
        case 'audio':
          throw new Error('Uploading audio assets is not supported');
        default:
          return undefined;
      }
    },
    [uploadVideo, uploadImage]
  );
};
