import { type FC, useState } from 'react';

import { AspectRatioBox, NativeVideoPlayer, styled, useIsHovered, useIsVideoPlaying } from '@cofenster/web-components';

import type { VideoAsset } from '../../api/hooks/videoAsset/usePollingVideoAsset';
import { PlayIconButton } from '../../components/controls/PlayIconButton';
import { useTrackBriefingVideoPlayPause } from '../../hooks/useTrackBriefingVideoPlayPause';

const BriefingContainer = styled('picture')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  [theme.breakpoints.down('md')]: {
    marginLeft: 'auto',
    marignRight: 'auto',
  },
}));

export const WelcomeVideo: FC<{ briefingVideo: VideoAsset }> = ({ briefingVideo }) => {
  const [element, setElement] = useState<HTMLVideoElement | null>(null);
  const isPlaying = useIsVideoPlaying(element);
  const isHovered = useIsHovered(element);
  useTrackBriefingVideoPlayPause(element);

  if (!briefingVideo?.videoUrl) return null;

  return (
    <BriefingContainer>
      <AspectRatioBox ratio={1}>
        <NativeVideoPlayer
          ref={setElement}
          src={briefingVideo.videoUrl}
          poster={briefingVideo.thumbnailUrl ?? undefined}
          controls={isHovered}
          actions={['FULLSCREEN']}
        />
        {!isPlaying && (
          <PlayIconButton
            icon="PlayIcon"
            label="i18n.global.play"
            iconSize="m"
            iconWeight="fill"
            onClick={() => element?.play()}
          />
        )}
      </AspectRatioBox>
    </BriefingContainer>
  );
};
