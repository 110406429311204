import { type HTMLProps, forwardRef, useEffect, useState } from 'react';

export const MediaProviderVideo = forwardRef<
  HTMLVideoElement,
  Omit<HTMLProps<HTMLVideoElement>, 'src'> & { srcObject: MediaProvider | null }
>(function MediaProviderVideo({ srcObject, ...props }, ref) {
  const [innerRef, setInnerRef] = useState<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (innerRef) {
      innerRef.srcObject = srcObject;
    }
  }, [innerRef, srcObject]);

  useEffect(() => {
    if (!ref) return;
    if (typeof ref === 'function') ref(innerRef);
    else ref.current = innerRef;
  }, [innerRef, ref]);

  return <video ref={setInnerRef} {...props} />;
});
