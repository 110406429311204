import type { ChangeEventHandler, ComponentProps, FC, PropsWithChildren } from 'react';

import { Box, Button, Icon, type IconType, Typography, styled } from '@cofenster/web-components';

const StyledButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  textAlign: 'center',
  gap: theme.spacing(1),
  minHeight: theme.spacing(9),
  width: '100%',
  borderRadius: theme.shape['borderRadius-l'],

  '&:focus-visible': theme.mixins.focusRing,
}));

type ButtonProps = {
  isUpload: boolean;
  onClick?: VoidFunction;
  inputId?: string;
  handleFilePick?: ChangeEventHandler<HTMLInputElement>;
  inputProps?: Partial<ComponentProps<'input'>>;
};

const ButtonHandler: FC<PropsWithChildren<ButtonProps>> = ({
  isUpload,
  inputId,
  handleFilePick,
  inputProps,
  onClick,
  ...rest
}) => {
  if (!isUpload) return <StyledButton {...rest} type="button" onClick={onClick} variant="tertiary" />;

  return (
    <>
      <input id={inputId} type="file" hidden onChange={handleFilePick} {...inputProps} />
      <StyledButton {...rest} htmlFor={inputId} component="label" variant="tertiary" />
    </>
  );
};

const WrappingBox = styled(Box)(() => ({
  overflow: 'visible',
}));

type InfoBannerProps = {
  title: string;
  isUpload: boolean;
  onClick?: VoidFunction;
  icon: IconType;
  inputId?: string;
  handleFilePick?: ChangeEventHandler<HTMLInputElement>;
  inputProps?: Partial<ComponentProps<'input'>>;
  'data-testid'?: string;
};

export const InfoBanner: FC<InfoBannerProps> = ({
  title,
  isUpload,
  inputId,
  inputProps,
  handleFilePick,
  onClick,
  icon,
  'data-testid': dataTestId,
}) => {
  return (
    <WrappingBox backgroundColor="white">
      <ButtonHandler
        isUpload={isUpload}
        inputId={inputId}
        inputProps={inputProps}
        handleFilePick={handleFilePick}
        onClick={onClick}
        data-testid={dataTestId}
      >
        <Icon type={icon} color="blue" />
        <Typography variant="l">{title}</Typography>
      </ButtonHandler>
    </WrappingBox>
  );
};

InfoBanner.displayName = 'InfoBanner';
