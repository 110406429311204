import { styled } from '@mui/material';
import type { FC } from 'react';

import type { VideoFormat } from '@cofenster/constants';

import { HeadHorizontal, HeadPortrait, HeadSquare, HeadVertical } from './Head';

const Container = styled('div')(() => ({
  position: 'absolute',
  inset: 0,
}));

const SafeZoneVertical = styled(Container)(({ theme }) => ({
  '&::before, &::after': {
    position: 'absolute',
    display: 'block',
    backgroundColor: theme.palette.brand.carbon_alpha10,
    width: '100%',
    content: "''",
  },
  '&::before': {
    top: 0,
    height: '11%',
    borderBottom: `1px dashed ${theme.palette.brand.white}`,
  },
  '&::after': {
    bottom: 0,
    height: '22%',
    borderTop: `1px dashed ${theme.palette.brand.white}`,
  },
}));

export type FramingSuggestionsProps = {
  videoFormat: VideoFormat;
  facingMode: 'user' | 'environment';
};

export const FramingSuggestions: FC<FramingSuggestionsProps> = ({ videoFormat, facingMode }) => {
  const testId = 'framing-frame';
  switch (videoFormat) {
    case 'Horizontal':
      return (
        <Container data-testid={testId}>
          <HeadHorizontal facingMode={facingMode} />
        </Container>
      );
    case 'Vertical':
      return (
        <SafeZoneVertical data-testid={testId}>
          <HeadVertical />
        </SafeZoneVertical>
      );
    case 'Square':
      return (
        <Container data-testid={testId}>
          <HeadSquare />
        </Container>
      );
    case 'SocialMedia':
      return (
        <Container data-testid={testId}>
          <HeadPortrait />
        </Container>
      );
    default:
      return null;
  }
};
