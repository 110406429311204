import { useCallback, useEffect } from 'react';

import {
  type UseMediaDevicesDevice as MediaDevice,
  useMediaDevices,
  usePersistedState,
} from '@cofenster/web-components';

import { useTracking } from '../../context/tracking';

export type SelectedMediaDevice = MediaDevice | false | undefined;

export function useMediaDevice(
  type: 'audioinput' | 'videoinput',
  defaultPick: 'first' | 'none' = 'first'
): [MediaDeviceInfo[] | undefined, SelectedMediaDevice, (device: SelectedMediaDevice) => unknown] {
  const availableDevices = useMediaDevices({ type });

  const [preferredDevice, setPreferredDevice] = usePersistedState<SelectedMediaDevice>(
    `preferred.${type}.device`,
    defaultPick === 'first' ? availableDevices?.[0] : false,
    true
  );

  const tracking = useTracking();
  const selectDeviceWithTracking = useCallback(
    (device: SelectedMediaDevice) => {
      setPreferredDevice(device);
      tracking.trackEvent({
        event: 'recordSettingUpdated',
        details: {
          settingType: type === 'audioinput' ? 'microphone' : 'camera',
          ...(device
            ? {
                settingName: device.label,
                deviceId: device.deviceId,
                deviceGroupId: device.groupId,
                deviceKind: device.kind,
              }
            : { settingName: 'nodevice' }),
        },
      });
    },
    [type, tracking, setPreferredDevice]
  );

  useEffect(() => {
    if (preferredDevice === false) return;
    if (!availableDevices?.length) return;
    if (!preferredDevice || !availableDevices.find(({ deviceId }) => deviceId === preferredDevice.deviceId)) {
      setPreferredDevice(defaultPick === 'first' ? availableDevices?.[0] : false);
    }
  }, [preferredDevice, defaultPick, availableDevices, setPreferredDevice]);

  const selectedDevice = availableDevices?.length ? preferredDevice : false;

  return [availableDevices, selectedDevice, selectDeviceWithTracking];
}
