import { useContext, useMemo } from 'react';

import { TasksContext, TasksContributionsContext } from './TaskProgressProvider';

export const useTasksProgress = () => {
  const taskIds = useContext(TasksContext);
  const [contributionsByTaskId] = useContext(TasksContributionsContext);

  return useMemo(() => {
    const totalCount = taskIds.length;
    const doneList = taskIds.filter((taskId) => contributionsByTaskId[taskId]?.length);
    const doneCount = doneList.length;
    return { totalCount, doneCount, doneList };
  }, [taskIds, contributionsByTaskId]);
};
