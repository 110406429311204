import { useContext } from 'react';
import { ContributorsContext } from './ContributorsContext';

export const useContributors = () => {
  const context = useContext(ContributorsContext);

  if (!context) throw new Error('useContributors must be used within a ContributorsProvider');

  return context;
};
