import { useTheme } from '@mui/material';
import type { FC, SVGProps } from 'react';

import type { BrandColor } from '../../../theming/theme';

import { SvgIcon } from './SvgIcon';
import type { IconSize } from './types';

export type CircleProgressIconProps = {
  /**
   * between 0-max
   * @default 0
   */
  value?: number;
  /**
   * @default 1
   */
  max?: number;
  size?: IconSize;
  color?: BrandColor;
  trackColor?: BrandColor;
  backgroundColor?: BrandColor;
  isThin?: boolean;
};
const deg2Rad = (degrees: number) => (degrees * Math.PI) / 180;
const pointFromPolar = (degrees: number, radius = 1, cx = 0, cy = 0) =>
  `${cx + radius * Math.cos(deg2Rad(degrees))} ${cy + radius * Math.sin(deg2Rad(degrees))}`;

const clamp =
  (max = 1, min = 0) =>
  (value: number) =>
    Math.min(max, Math.max(min, value));

const segmentPath = (from = 0, to = 1, outerRadius = 12, innerRadius = 8, cx = 12, cy = 12) => {
  const zeroAngleDegrees = -90;

  const [fromDegrees, toDegrees] = [from, to]
    .map(clamp(1, 0))
    .map((v) => v * 360)
    .sort((a, b) => a - b) as [number, number];
  const diff = toDegrees - fromDegrees;

  if (diff === 360) {
    return `M ${cx} ${cy - outerRadius}
    A ${outerRadius} ${outerRadius} 0 1 0 ${cx} ${cy + outerRadius}
    A ${outerRadius} ${outerRadius} 0 1 0 ${cx} ${cy - outerRadius}
    Z
    M ${cx} ${cy - innerRadius}
    A ${innerRadius} ${innerRadius} 0 1 1 ${cx} ${cy + innerRadius}
    A ${innerRadius} ${innerRadius} 0 1 1 ${cx} ${cy - innerRadius}
    Z`;
  }

  const arc = diff > 180 ? 1 : 0;
  const a = pointFromPolar(fromDegrees + zeroAngleDegrees, outerRadius, cx, cy);
  const b = pointFromPolar(toDegrees + zeroAngleDegrees, outerRadius, cx, cy);
  const c = pointFromPolar(toDegrees + zeroAngleDegrees, innerRadius, cx, cy);
  const d = pointFromPolar(fromDegrees + zeroAngleDegrees, innerRadius, cx, cy);

  return `
    M${a}
    A${outerRadius},${outerRadius},0,${arc},1,${b}
    L${c}
    A${innerRadius},${innerRadius},0,${arc},0,${d}
    Z
  `;
};
const CircularSegment: FC<
  {
    cx?: number;
    cy?: number;
    outerR?: number;
    innerR?: number;
    from?: number;
    to?: number;
    Component?: 'path' | FC<SVGProps<SVGPathElement>>;
  } & Omit<SVGProps<SVGPathElement>, 'd'>
> = ({ cx = 0, cy = 0, outerR = 1, innerR = 0, from = 0, to = 1, Component = 'path', ...pathProps }) => {
  return <Component d={segmentPath(from, to, outerR, innerR, cx, cy)} {...pathProps} />;
};

export const CircleProgressIcon: FC<CircleProgressIconProps> = ({
  value = 0,
  max = 1,
  color = 'blue',
  trackColor = 'linen50',
  backgroundColor = 'transparent',
  size = 'l',
  isThin = false,
}) => {
  const { palette } = useTheme();
  const cx = 12;
  const cy = 12;
  const outerR = 12;
  const innerR = isThin ? outerR - 1 : outerR - 3;

  return (
    <SvgIcon
      size={size}
      role="progressbar"
      aria-roledescription="progress"
      aria-valuenow={value}
      aria-valuemin={0}
      aria-valuemax={max}
    >
      {backgroundColor !== 'transparent' && (
        <circle cx={cx} cy={cy} r={innerR} fill={palette.brand[backgroundColor]} stroke="none" />
      )}
      {trackColor !== 'transparent' && (
        <CircularSegment
          cx={cx}
          cy={cx}
          outerR={outerR}
          innerR={innerR}
          fill={palette.brand[trackColor]}
          stroke="none"
        />
      )}
      <CircularSegment
        to={value / max}
        cx={cx}
        cy={cx}
        outerR={outerR}
        innerR={innerR}
        fill={palette.brand[color]}
        stroke="none"
      />
    </SvgIcon>
  );
};
