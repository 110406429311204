import { styled } from '@mui/material';
import type { FC } from 'react';

import type { UseMediaDevicesDevice } from '../../../../../hooks/media/useMediaDevices';
import { Icon } from '../../../../assets/icons/Icon';
import { IconButton } from '../../../../controls/Button/IconButton';
import { SelectableBoxInput } from '../../../../controls/SelectableBoxInput';
import { withPopover } from '../../../../feedback/Popover';
import { GridContainer, GridItem } from '../../../../layout/Grid';
import { VisuallyHidden } from '../../../../utilities/VisuallyHidden';
import type { SelectedMediaDevice } from '../MicrophonePopOver';

export type CameraPopOverProps = {
  devices: UseMediaDevicesDevice[] | undefined;
  selectedDevice: SelectedMediaDevice;
  onSelectDevice: (device: SelectedMediaDevice) => unknown;
  allowSelectNone?: boolean;
  disabled?: boolean;
};

const Container = styled('div')(() => ({
  border: 0,
  margin: 0,
  padding: 0,
}));

export const CameraPopOver: FC<CameraPopOverProps> = ({
  devices,
  selectedDevice,
  onSelectDevice,
  allowSelectNone = false,
  disabled = false,
}) => {
  const iconType = selectedDevice ? 'WebcamIcon' : 'WebcamSlashIcon';
  const iconColor = disabled ? 'grey300' : 'white';

  const CameraPopOverIcon = withPopover(IconButton, {
    anchorOriginX: 'center',
    anchorOriginY: 'top',
    transformOriginX: 'center',
    transformOriginY: 'bottom',
    children: devices && (
      <GridContainer spacing={0} padding={2} maxWidth={400}>
        <Container as="fieldset">
          <VisuallyHidden as="legend">i18n.global.camera.configure</VisuallyHidden>

          {devices.map((device) => {
            return (
              <GridItem xs={12} key={device.deviceId}>
                <SelectableBoxInput
                  mainIcon={<Icon type="CameraIcon" />}
                  type="radio"
                  name="cameraDevice"
                  id={`device-${device.deviceId}`}
                  value={device.deviceId}
                  title={device.label}
                  checked={!!selectedDevice && selectedDevice?.deviceId === device.deviceId}
                  onChange={() => onSelectDevice(device)}
                />
              </GridItem>
            );
          })}

          {(allowSelectNone || devices.length === 0) && (
            <GridItem xs={12}>
              <SelectableBoxInput
                mainIcon={<Icon type="WebcamSlashIcon" />}
                type="radio"
                name="cameraDevice"
                id="none"
                value="none"
                title="i18n.global.camera.none"
                checked={!selectedDevice}
                onChange={() => onSelectDevice(false)}
              />
            </GridItem>
          )}
        </Container>
      </GridContainer>
    ),
  });

  return (
    <CameraPopOverIcon
      icon={iconType}
      iconColor={selectedDevice ? iconColor : 'white'}
      hoverBackgroundColor={selectedDevice ? undefined : 'negativeDark'}
      hoverColor={selectedDevice ? iconColor : 'white'}
      backgroundColor={selectedDevice ? 'blurred' : 'negative'}
      disabled={disabled}
      data-testid="select-camera-button"
      label="i18n.global.camera.configure"
    />
  );
};
