import { styled } from '@mui/material';
import type { FC } from 'react';

import type { PiPAnchor, PiPSize } from '../../../../../hooks/media/useCanvasPainter';
import type { UseMediaDevicesDevice } from '../../../../../hooks/media/useMediaDevices';
import { useBooleanState } from '../../../../../hooks/state/useBooleanState';
import { Button } from '../../../../controls/Button';
import { IconButton } from '../../../../controls/Button/IconButton';
import { Dialog, DialogContent } from '../../../../feedback/Dialog';
import { GridContainer, GridItem } from '../../../../layout/Grid';
import type { SelectedMediaDevice } from '../MicrophonePopOver';

import { AnchorSelect } from './AnchorSelect';
import { CameraSelect } from './CameraSelect';
import { SizeSelect } from './SizeSelect';

type IconOptionType = 'WebcamIcon' | 'WebcamSlashIcon';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  paddingTop: theme.spacing(2),
}));

export type PiPCameraDialogProps = {
  devices: UseMediaDevicesDevice[] | undefined;
  selectedDevice: SelectedMediaDevice;
  onSelectDevice: (device: SelectedMediaDevice) => unknown;
  allowSelectNone?: boolean;
  disabled?: boolean;
  pipAnchor?: PiPAnchor;
  pipSize?: PiPSize;
  setPipAnchor?: (anchor: PiPAnchor) => void;
  setPipSize?: (size: PiPSize) => void;
};

const PulsatingButton = styled(IconButton)(() => ({
  animation: 'pulsate 0.5s ease-in-out 10 both',
  '@keyframes pulsate': {
    'from, to': { transform: 'scale(1)' },
    '50%': { transform: 'scale(0.9)' },
  },
}));

export const PiPCameraDialog: FC<PiPCameraDialogProps> = ({
  devices,
  selectedDevice,
  onSelectDevice,
  disabled,
  pipAnchor,
  pipSize,
  setPipAnchor,
  setPipSize,
}) => {
  const [isOpen, openDialog, closeDialog] = useBooleanState(false);

  if (!devices || !onSelectDevice) return null;

  const iconType: IconOptionType = selectedDevice ? 'WebcamIcon' : 'WebcamSlashIcon';
  const iconColor = disabled ? 'grey300' : 'white';

  return (
    <>
      <PulsatingButton
        onClick={openDialog}
        icon={iconType}
        iconColor={iconColor}
        backgroundColor="blurred"
        label="i18n.global.camera.configure"
      />

      <StyledDialog open={isOpen} onClose={closeDialog} title="i18n.global.pip.dialog.title">
        <DialogContent>
          <GridContainer pt={2}>
            <GridItem xs={12}>
              <CameraSelect devices={devices} selectedDevice={selectedDevice} onSelectDevice={onSelectDevice} />
            </GridItem>

            {selectedDevice && (
              <>
                {setPipSize && pipSize && (
                  <GridItem xs={12}>
                    <SizeSelect setPipSize={setPipSize} pipSize={pipSize} />
                  </GridItem>
                )}

                {pipAnchor && setPipAnchor && (
                  <GridItem xs={12}>
                    <AnchorSelect pipAnchor={pipAnchor} setPipAnchor={setPipAnchor} />
                  </GridItem>
                )}
              </>
            )}

            <GridItem xs={12}>
              <Button onClick={closeDialog} fullWidth>
                i18n.global.pip.dialog.close
              </Button>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </StyledDialog>
    </>
  );
};
