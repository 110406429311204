import { useEffect, useMemo, useState } from 'react';

import { VIDEO_FORMATS, type VideoFormat } from '@cofenster/constants';

import type { MPMask } from '@mediapipe/tasks-vision';
import { createConfidenceMaskPipeline } from '../../../utilities/webgl/confidenceMask';
import { createTwoPassBlur } from '../../../utilities/webgl/twoPassBlur';
import { useNativeCaptureSupported } from '../../dom/useNativeCaptureSupported';
import { usePromise } from '../../utils/usePromise';
import { type BackgroundEffect, createColor, createImage } from './backgroundEffects';
import { useImageSegmenterWithBackgroundConfidenceMask } from './useImageSegmenterWithBackgroundConfidenceMask';
import { useTransformedVideoStream } from './useTransformedVideoStream';
import { clipVideoFrameUsingCanvas, coverClipRect, createCanvasIdeallyOffscreen } from './utils';

export const useBackgroundEffect = (
  stream: MediaStream | null,
  effect: BackgroundEffect,
  videoFormat: VideoFormat = 'Horizontal',
  everyNthFrame = 1,
  primary = '#CCCCCC',
  secondary = '#333333'
) => {
  const initialized = useTruthyOnce(!!stream && effect !== 'NONE');
  const isMobileDevice = useNativeCaptureSupported();

  const offscreenCanvas = useMemo(() => {
    if (!initialized) return null;
    return createCanvasIdeallyOffscreen(VIDEO_FORMATS[videoFormat].width, VIDEO_FORMATS[videoFormat].height);
  }, [initialized, videoFormat]);

  useEffect(() => {
    if (!offscreenCanvas) return;
    const format = VIDEO_FORMATS[videoFormat];
    const settings = stream?.getVideoTracks()[0]?.getSettings();
    const { width, height } =
      settings?.width && settings?.height
        ? coverClipRect(settings.width, settings.height, format.aspectRatio)
        : new DOMRectReadOnly(0, 0, format.width, format.height);
    offscreenCanvas.width = width;
    offscreenCanvas.height = height;
  }, [offscreenCanvas, stream, videoFormat]);

  const [imageSegmenter] = useImageSegmenterWithBackgroundConfidenceMask(offscreenCanvas, isMobileDevice);
  const { value: background } = usePromise(
    useMemo(async () => {
      if (effect === 'NONE') return effect;
      if (effect === 'BLUR') return effect;
      if (effect === 'PRIMARY') return createColor(primary);
      if (effect === 'SECONDARY') return createColor(secondary);
      return createImage(effect, videoFormat);
    }, [effect, videoFormat, primary, secondary])
  );

  const overlayPainter = useMemo(() => {
    if (!offscreenCanvas) return null;
    if (!imageSegmenter) return null;
    if (!background) return null;

    if (background === 'NONE') return null;
    if (background === 'BLUR') return createBlurPainter(offscreenCanvas, imageSegmenter.confidenceMask.invert);
    return createImagePainter(offscreenCanvas, background, imageSegmenter.confidenceMask.invert);
  }, [offscreenCanvas, background, imageSegmenter]);

  const transformer = useMemo(() => {
    if (!imageSegmenter) return null;
    if (!overlayPainter) return null;
    if (!stream) return null;

    const backgroundIndex = imageSegmenter.getLabels().indexOf(imageSegmenter.confidenceMask.name);

    let count = 0;
    let mask: MPMask | null = null;

    // new TransformStream must be created for each new stream
    return new TransformStream<VideoFrame, VideoFrame>({
      async transform(videoFrame, controller) {
        const { aspectRatio: formatAspectRatio } = VIDEO_FORMATS[videoFormat];
        const displayAspectRatio = videoFrame.displayWidth / videoFrame.displayHeight;

        // todo: fix
        // there's rounding to multiple of 2 in coverClipRect
        if (Math.abs(displayAspectRatio - formatAspectRatio) > 0.05) {
          // todo: use safeClipVideoFrame
          // clipUsingBuffer is not working properly
          videoFrame = await clipVideoFrameUsingCanvas(
            videoFrame,
            coverClipRect(videoFrame.displayWidth, videoFrame.displayHeight, formatAspectRatio)
          );
        }

        if (mask && count % everyNthFrame !== 0) {
          overlayPainter.paint(mask.getAsWebGLTexture(), videoFrame);
          controller.enqueue(
            overlayPainter.toVideoFrame({
              timestamp: videoFrame.timestamp,
            })
          );
        } else {
          const clone = videoFrame.clone();
          imageSegmenter.segmentForVideo(clone, performance.now(), (result) => {
            if (mask) mask.close();
            mask = result.confidenceMasks?.[backgroundIndex]?.clone() ?? null;
            if (mask) {
              overlayPainter.paint(mask.getAsWebGLTexture(), clone);
              controller.enqueue(
                overlayPainter.toVideoFrame({
                  timestamp: videoFrame.timestamp,
                })
              );
            } else {
              console.warn('No mask');
            }
            result.close();
            clone.close();
          });
        }
        count++;
        videoFrame.close();
      },
    });
  }, [imageSegmenter, overlayPainter, stream, everyNthFrame, videoFormat]);

  useEffect(() => {
    if (overlayPainter) return () => overlayPainter.close();
  }, [overlayPainter]);

  const output = useTransformedVideoStream(stream, transformer);

  const isInitializing = Boolean(stream && (!output || stream === output) && effect !== 'NONE');

  return useMemo(() => ({ output, isInitializing }), [output, isInitializing]);
};

const useTruthyOnce = (condition = false) => {
  const [truthyOnce, setTruthyOnce] = useState(condition);

  useEffect(() => {
    if (condition) setTruthyOnce(true);
  }, [condition]);

  return truthyOnce;
};

const createBlurPainter = (canvas: OffscreenCanvas | HTMLCanvasElement, invertMask: boolean) => {
  const gl = canvas.getContext('webgl2') as WebGL2RenderingContext;
  if (!gl) throw new Error('Failed to get webgl2 context');

  const blurPipeline = createTwoPassBlur(gl);
  const confidenceMaskPipeline = createConfidenceMaskPipeline(gl, blurPipeline.texture, invertMask);

  return {
    paint(texture: WebGLTexture, videoFrame: TexImageSource) {
      blurPipeline.paintToTexture(videoFrame);
      confidenceMaskPipeline.paint(texture, videoFrame);
    },
    toVideoFrame(init?: VideoFrameInit) {
      return new VideoFrame(canvas, init);
    },
    close() {
      confidenceMaskPipeline.destroy();
      blurPipeline.destroy();
    },
  };
};

const createImagePainter = (
  canvas: OffscreenCanvas | HTMLCanvasElement,
  image: TexImageSource,
  invertMask: boolean
) => {
  const gl = canvas.getContext('webgl2') as WebGL2RenderingContext;
  if (!gl) throw new Error('Failed to get webgl2 context');

  const confidenceMaskPipeline = createConfidenceMaskPipeline(gl, undefined, invertMask);
  confidenceMaskPipeline.setOverlayTextureImage(image);

  return {
    paint(texture: WebGLTexture, videoFrame: TexImageSource) {
      confidenceMaskPipeline.paint(texture, videoFrame);
    },
    toVideoFrame(init?: VideoFrameInit) {
      return new VideoFrame(canvas, init);
    },
    close() {
      confidenceMaskPipeline.destroy();
    },
  };
};
