import { useEffect } from 'react';

import { useTracking } from '../context/tracking';

const useEffectWhen = (
  condition: boolean,
  callback: Parameters<typeof useEffect>[0],
  dependencies: Parameters<typeof useEffect>[1]
) => {
  // biome-ignore lint/correctness/useExhaustiveDependencies: by design
  useEffect(() => {
    if (condition) {
      return callback();
    }
  }, dependencies);
};

// 1. effects should run only if desktopSurface is not null (any screen is enabled)
export const useTrackRecordSettingUpdated = (desktopSurface: string | null, pipSize: string, pipAnchor: string) => {
  const track = useTracking();

  // 1
  useEffectWhen(
    !!desktopSurface, // 1
    () => {
      track?.trackEvent({
        event: 'recordSettingUpdated',
        details: {
          settingType: 'screenshare',
          settingName: desktopSurface,
        },
      });
    },
    [desktopSurface]
  );

  useEffectWhen(
    !!desktopSurface, // 1
    () => {
      track?.trackEvent({
        event: 'recordSettingUpdated',
        details: {
          settingType: 'recordingSize',
          settingName: pipSize,
        },
      });
    },
    [pipSize]
  );

  useEffectWhen(
    !!desktopSurface, // 1
    () => {
      track?.trackEvent({
        event: 'recordSettingUpdated',
        details: {
          settingType: 'recordingPosition',
          settingName: pipAnchor,
        },
      });
    },
    [pipAnchor]
  );
};
