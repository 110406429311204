import { useCallback, useEffect, useState } from 'react';

export const useMediaStreamActive = (stream: MediaStream | null) => {
  const [isActive, setIsActive] = useState<null | boolean>(stream?.active ?? null);
  const [tracks, setTracks] = useState<MediaStreamTrack[]>(stream?.getTracks().slice() ?? []);

  const updateIsActive = useCallback(() => setIsActive(stream?.active ?? null), [stream]);
  const updateTracks = useCallback(() => setTracks(stream?.getTracks().slice() ?? []), [stream]);

  useEffect(() => {
    updateTracks();
    updateIsActive();
    if (stream) {
      // Chromium-based browser support active/inactive events
      stream.addEventListener('active', updateIsActive);
      stream.addEventListener('inactive', updateIsActive);
      // Some Chromium-based browsers don't support addtrack/removetrack events
      stream.addEventListener('addtrack', updateTracks);
      stream.addEventListener('removetrack', updateTracks);
      return () => {
        stream.removeEventListener('active', updateIsActive);
        stream.removeEventListener('inactive', updateIsActive);
        stream.removeEventListener('addtrack', updateTracks);
        stream.removeEventListener('removetrack', updateTracks);
      };
    }
  }, [stream, updateIsActive, updateTracks]);

  useEffect(() => {
    tracks.forEach((track) => track.addEventListener('ended', updateIsActive));
    return () => tracks.forEach((track) => track.removeEventListener('ended', updateIsActive));
  }, [tracks, updateIsActive]);

  return isActive;
};
