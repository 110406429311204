import { useCallback, useEffect, useState } from 'react';

import { useFileCache } from './useFileCache';

export const useCachedFile = (type: string, filename: string) => {
  const [file, setFile] = useState<File | null | undefined>(undefined);
  const { getCachedFile, EVENT_NAME } = useFileCache(type);

  const updateCacheState = useCallback(async () => {
    const file = await getCachedFile(filename);
    setFile(file);
  }, [getCachedFile, filename]);

  useEffect(() => {
    updateCacheState();
    window.addEventListener(EVENT_NAME, updateCacheState);

    return () => {
      window.removeEventListener(EVENT_NAME, updateCacheState);
    };
  }, [updateCacheState, EVENT_NAME]);

  return typeof file === 'undefined' ? { loading: true, file: null } : { loading: false, file };
};
