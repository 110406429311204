import { useEffect, useMemo } from 'react';

import { createVideoFromMediaStream } from '../useVideoFromMediaStream';

declare class MediaStreamTrackProcessor {
  constructor(options: { track: MediaStreamTrack });
  readonly readable: ReadableStream;
}

declare class MediaStreamTrackGenerator extends MediaStreamTrack {
  constructor(options: { kind: 'video' });
  readonly writable: WritableStream;
}

const noop = () => undefined;

const SUPPORTS_MODERN =
  typeof MediaStreamTrackProcessor !== 'undefined' && typeof MediaStreamTrackGenerator !== 'undefined';

const useTransformedVideoStreamModern = (
  stream: MediaStream | null,
  transformer: TransformStream<VideoFrame, VideoFrame> | null
) => {
  const [output, close] = useMemo(() => {
    if (!SUPPORTS_MODERN) throw new Error('Not supported');
    if (!transformer) return [stream, noop];
    if (!stream) return [stream, noop];
    if (!stream.getVideoTracks().length) return [stream, noop];

    const [videoTrack] = stream.getVideoTracks();
    if (!videoTrack) return [stream, noop];

    const trackProcessor = new MediaStreamTrackProcessor({ track: videoTrack });
    const trackGenerator = new MediaStreamTrackGenerator({ kind: 'video' });
    const abortController = new AbortController();
    trackProcessor.readable
      .pipeThrough(transformer)
      .pipeTo(trackGenerator.writable, { signal: abortController.signal })
      .catch((reason: unknown) => (reason !== 'Clean' ? console.error(reason) : noop));

    const output = new MediaStream([trackGenerator]);
    const close = () => {
      abortController.abort('Clean');
      stopStream(output);
    };

    return [output, close];
  }, [stream, transformer]);

  useEffect(() => {
    return close;
  }, [close]);

  return output;
};

const createSource = (stream: MediaStream): UnderlyingDefaultSource<VideoFrame> => {
  let disposeFn: (handle?: number) => void;
  let disposeArg: number | undefined = undefined;
  return {
    async start(controller) {
      const video = await createVideoFromMediaStream(stream);
      if (!video) return;
      disposeFn = (handle?: number) => handle && video.cancelVideoFrameCallback(handle);

      const draw: VideoFrameRequestCallback = async (_, metadata) => {
        controller.enqueue(new VideoFrame(video, { timestamp: metadata.mediaTime * 1000000 }));
        disposeArg = video.requestVideoFrameCallback(draw);
      };
      disposeArg = video.requestVideoFrameCallback(draw);
    },
    cancel() {
      disposeFn?.(disposeArg);
    },
  };
};

const createSink = (canvas: HTMLCanvasElement): UnderlyingSink<VideoFrame> => {
  const ctx = canvas.getContext('2d');
  return {
    start(controller) {
      if (!ctx) {
        return controller.error('No context');
      }
    },
    write(videoFrame) {
      ctx?.drawImage(videoFrame, 0, 0, canvas.width, canvas.height);
      videoFrame.close?.();
    },
  };
};

const SUPPORTS_LEGACY =
  typeof VideoFrame !== 'undefined' && typeof HTMLVideoElement.prototype.requestVideoFrameCallback !== 'undefined';

const useTransformedVideoStreamLegacy = (
  stream: MediaStream | null,
  transformer: TransformStream<VideoFrame, VideoFrame> | null
) => {
  const [output, close] = useMemo(() => {
    if (!SUPPORTS_LEGACY) throw new Error('Not supported');
    if (!transformer) return [stream, noop];
    if (!stream) return [stream, noop];
    if (!stream.getVideoTracks().length) return [stream, noop];

    const track = stream.getVideoTracks()[0];
    if (!track) return [stream, noop];

    const { width, height, frameRate } = track.getSettings();
    if (!width) return [stream, noop];
    if (!height) return [stream, noop];

    const fps = frameRate ?? 25;
    const source = createSource(stream);
    const readable = new ReadableStream(source);

    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const sink = createSink(canvas);
    const writable = new WritableStream(sink);

    readable.pipeThrough(transformer).pipeTo(writable).catch(console.error);

    const output = canvas.captureStream(fps);

    const close = () => {
      stopStream(output);
      source?.cancel?.();
    };
    return [output, close];
  }, [stream, transformer]);

  useEffect(() => {
    return close;
  }, [close]);

  return output;
};

const stopStream = (stream: MediaStream) => stream.getTracks().forEach((track) => track.stop());

const useTransformedVideoStreamFirefox = (
  stream: MediaStream | null,
  _: TransformStream<VideoFrame, VideoFrame> | null
) => {
  console.warn('Video stream processing not available in this browser');
  return stream;
};

export const SUPPORTS_TRANSFORMING_STREAMS = SUPPORTS_MODERN || SUPPORTS_LEGACY;

export const useTransformedVideoStream = SUPPORTS_MODERN
  ? useTransformedVideoStreamModern
  : SUPPORTS_LEGACY
    ? useTransformedVideoStreamLegacy
    : useTransformedVideoStreamFirefox;
