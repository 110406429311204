import { useCallback } from 'react';

import { useFileUpload } from '@cofenster/web-components';

import { useInitImageUpload } from './useInitImageUpload';

export const useUploadImageAsset = () => {
  const initImageUpload = useInitImageUpload();
  const uploadFile = useFileUpload();

  return useCallback(
    async (
      file: File,
      uploadId: string,
      onProgress: (id: string, progress: number) => unknown,
      signal: AbortController['signal']
    ) => {
      const result = await initImageUpload();

      if (!result.data?.initImageUpload) throw new Error('Failed to init image upload');

      const { uploadUrl, imageAssetId } = result.data.initImageUpload;

      const uploadResult = await uploadFile(uploadUrl, file, uploadId, onProgress, signal);

      if (!uploadResult) {
        // Upload failed or was aborted
        return undefined;
      }
      return imageAssetId;
    },
    [initImageUpload, uploadFile]
  );
};
