import type { FC } from 'react';

import { CameraPopOver, type CameraPopOverProps } from '../CameraPopOver';

export type SelectCameraProps = Omit<CameraPopOverProps, 'anchorEl'>;

export const SelectCameraButton: FC<SelectCameraProps> = ({
  devices,
  selectedDevice,
  onSelectDevice,
  allowSelectNone,
  disabled,
}) => {
  if (!devices || !onSelectDevice) return null;

  return (
    <CameraPopOver
      devices={devices}
      onSelectDevice={onSelectDevice}
      selectedDevice={selectedDevice}
      allowSelectNone={allowSelectNone}
      disabled={disabled}
    />
  );
};
