import type { FC } from 'react';

import type { PiPAnchor } from '../../../../../../hooks/media/useCanvasPainter';
import type { IconType } from '../../../../../assets/icons/Icon';
import { Select, SelectOption } from '../../../../../controls/Select';
import { GridContainer, GridItem } from '../../../../../layout/Grid';
import { Typography } from '../../../../../typography/Typography';
import { VisuallyHidden } from '../../../../../utilities/VisuallyHidden';
import { CameraOption } from '../CameraSelect/Option';

const SectionHeader: FC<{ title: string }> = ({ title }) => <Typography variant="h6">{title}</Typography>;

const iconTypeByAnchor: Record<PiPAnchor, IconType> = {
  'top-right': 'ArrowBoxedUpRightIcon',
  'top-left': 'ArrowBoxedUpLeftIcon',
  'bottom-right': 'ArrowBoxedDownRightIcon',
  'bottom-left': 'ArrowBoxedDownLeftIcon',
};

const getLabel = (anchor: PiPAnchor) => `i18n.global.pip.dialog.position.${anchor}`;

const anchors: PiPAnchor[] = ['top-right', 'bottom-right', 'bottom-left', 'top-left'];

type AnchorSelectProps = {
  pipAnchor: PiPAnchor;
  setPipAnchor: (anchor: PiPAnchor) => void;
};

export const AnchorSelect: FC<AnchorSelectProps> = ({ pipAnchor, setPipAnchor }) => {
  return (
    <GridContainer display="flex" flexDirection="column" spacing={1}>
      <GridItem>
        <SectionHeader title="Position" />
      </GridItem>

      <GridItem>
        <VisuallyHidden id="selectPositionLabel">i18n.global.pip.dialog.position</VisuallyHidden>
        <Select
          label=""
          aria-label="i18n.global.pip.dialog.position"
          name="SelectPosition"
          value={pipAnchor}
          pb={0}
          onChange={(event) => {
            const anchor = event.target.value as PiPAnchor;
            setPipAnchor(anchor);
          }}
          renderValue={(value) => {
            const anchor = value as PiPAnchor;
            return <CameraOption iconType={iconTypeByAnchor[anchor]} title={getLabel(anchor)} />;
          }}
          inputProps={{
            'aria-labelledby': 'selectPositionLabel',
          }}
        >
          {anchors.map((anchor: PiPAnchor) => {
            return (
              <SelectOption key={anchor} value={anchor}>
                <CameraOption iconType={iconTypeByAnchor[anchor]} title={getLabel(anchor)} />
              </SelectOption>
            );
          })}
        </Select>
      </GridItem>
    </GridContainer>
  );
};
