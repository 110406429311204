import { Navigate, Outlet, Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

import { CookieBanner } from '@cofenster/web-components';

import { ErrorBoundary } from '../components/ErrorBoundary';
import { ContributionRequestListPage } from '../pages/ContributionRequestListPage';
import { ContributionRequestPage } from '../pages/ContributionRequestPage';
import { ErrorPage } from '../pages/ErrorPage';
import { SigninByCodePage } from '../pages/SigninByCodePage';
import { WelcomePage } from '../pages/WelcomePage';
import { Sentry } from '../sentry';
import { routes } from './routes';

const Root = () => (
  <ErrorBoundary>
    <Outlet />
    <CookieBanner />
  </ErrorBoundary>
);

export const router = Sentry.wrapCreateBrowserRouter(createBrowserRouter)(
  createRoutesFromElements(
    <Route path="/" element={<Root />}>
      <Route path={routes.home} element={<Navigate to={routes.signinByCode} />} />
      <Route path={routes.signinByCode} element={<SigninByCodePage />} />
      <Route path={routes.welcome} element={<WelcomePage />} />
      <Route path={routes.contributionRequestList} element={<ContributionRequestListPage />} />
      <Route path={routes.contributionRequest} element={<ContributionRequestPage />} />
      <Route path="*" element={<ErrorPage />} />
    </Route>
  )
);
