import {
  type FC,
  type PropsWithChildren,
  createContext,
  memo,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { type BackgroundEffect, useTracking as useWebComponentsTracking } from '@cofenster/web-components';

type CaptureAssetCandidateFile = {
  url: string;
  blob: Blob;
};

export type CaptureAssetCandidateFileContextType = {
  candidateFile: CaptureAssetCandidateFile | undefined;
  onCaptureAssetReadyForReview: (file: CaptureAssetCandidateFile, metadata: CaptureAssetMetadata) => void;
  clearCaptureAssetFile: VoidFunction;
  metadata: CaptureAssetMetadata | undefined;
};

const CaptureAssetCandidateFileContext = createContext<CaptureAssetCandidateFileContextType | undefined>(undefined);

export type CaptureAssetMetadata =
  | {
      uploadSource: Exclude<CaptureAssetSource, 'desktop-screen-recording'>;
      cameraEnabled?: boolean;
      microphoneEnabled?: boolean;
      effects?: BackgroundEffect;
    }
  | {
      uploadSource: 'desktop-screen-recording';
      cameraEnabled: boolean;
      microphoneEnabled: boolean;
      effects?: BackgroundEffect;
    };

export type CaptureAssetSource =
  | 'desktop-library'
  | 'desktop-screen-recording'
  | 'mobile-library'
  | 'mobile-webcam-recording'
  | 'webcam-recording'
  | 'stock-footage';

export const CaptureAssetCandidateFileProvider: FC<
  PropsWithChildren<{
    useTracking?: () => ReturnType<typeof useWebComponentsTracking>;
  }>
> = memo(({ children, useTracking = useWebComponentsTracking }) => {
  const [metadata, setMetadata] = useState<CaptureAssetMetadata | undefined>();
  const [file, setFile] = useState<CaptureAssetCandidateFile | undefined>(undefined);
  const clearCaptureAssetFile = useCallback(() => setFile(undefined), []);
  const tracking = useTracking();
  const onCaptureAssetReady = useCallback(
    (recordedFile: CaptureAssetCandidateFile, metadata?: CaptureAssetMetadata) => {
      setFile(recordedFile);
      setMetadata(metadata);

      tracking.trackEvent({ event: 'uploadAssetSelected', details: metadata });
    },
    [tracking]
  );

  const contextValue = useMemo(
    () => ({
      candidateFile: file,
      clearCaptureAssetFile,
      onCaptureAssetReadyForReview: onCaptureAssetReady,
      metadata,
    }),
    [file, clearCaptureAssetFile, onCaptureAssetReady, metadata]
  );

  return (
    <CaptureAssetCandidateFileContext.Provider value={contextValue}>
      {children}
    </CaptureAssetCandidateFileContext.Provider>
  );
});
CaptureAssetCandidateFileProvider.displayName = 'CaptureAssetFileProvider';

export const useCaptureAssetCandidateFileContext = () => {
  const context = useContext(CaptureAssetCandidateFileContext);
  if (!context) {
    throw new Error('useCaptureAssetCandidateFileContext must be used within a CaptureAssetCandidateFileProvider');
  }
  return context;
};
