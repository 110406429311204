import { styled } from '@mui/material';
import type { FC } from 'react';

import { Typography } from '../../../../typography/Typography';

import { BlurredContainer } from './BlurredContainer';
import { RedPulsingBall } from './RedPulsingBall';

const Container = styled('span')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
  width: theme.spacing(7),
}));

const durationToTime = (msDuration: number) => {
  const h = Math.floor(msDuration / 1000 / 60 / 60);
  const m = Math.floor((msDuration / 1000 / 60 / 60 - h) * 60);
  const s = Math.floor(((msDuration / 1000 / 60 / 60 - h) * 60 - m) * 60);

  const seconds: string = s < 10 ? `0${s}` : `${s}`;
  const minutes: string = m < 10 ? `0${m}` : `${m}`;

  return `${minutes}:${seconds}`;
};

type TimeRecordingIndicatorProps = {
  recordingDuration: number;
  isRecording: boolean;
};

export const TimeRecordingIndicator: FC<TimeRecordingIndicatorProps> = ({ recordingDuration, isRecording }) => {
  return (
    <BlurredContainer>
      <Container>
        <Typography color="white" tabularNums>
          {durationToTime(recordingDuration)}
        </Typography>
        <RedPulsingBall visible={isRecording} />
      </Container>
    </BlurredContainer>
  );
};
