import { type FC, useState } from 'react';

import { GridContainer, GridItem, useNativeCaptureSupported } from '@cofenster/web-components';

import { useCaptureAssetCandidateFileContext } from '../../../../context/captureAssetFile';
import { CaptureActions } from '../../CaptureActions';
import type { CaptureComponentProps } from '../../types';

import { CoCaptureScreenRecording } from './CoCaptureScreenRecording';
import { CoCaptureScreenRecordingMobile } from './CoCaptureScreenRecordingMobile';
import { WebcamRecorder } from './WebcamRecorder';
import { WebcamRecorderMobile } from './WebcamRecorderMobile';

export const VideoCapture: FC<CaptureComponentProps> = ({ type, showRecordViaApp, showFramingSuggestions }) => {
  const nativeCaptureSupported = useNativeCaptureSupported();
  const [isRecording, setIsRecording] = useState(false);
  const { onCaptureAssetReadyForReview } = useCaptureAssetCandidateFileContext();

  if (type === 'screenRecording') {
    if (nativeCaptureSupported) {
      return <CoCaptureScreenRecordingMobile />;
    }

    return <CoCaptureScreenRecording />;
  }

  return (
    <>
      <GridContainer spacing={2}>
        <GridItem xs={12}>
          {nativeCaptureSupported ? (
            <WebcamRecorderMobile
              onRecordingStopped={onCaptureAssetReadyForReview}
              showFramingSuggestions={showFramingSuggestions}
            />
          ) : (
            <WebcamRecorder
              onRecordingStopped={onCaptureAssetReadyForReview}
              setIsRecording={setIsRecording}
              showFramingSuggestions={showFramingSuggestions}
            />
          )}
        </GridItem>
        {!isRecording && !nativeCaptureSupported && <CaptureActions showRecordViaApp={showRecordViaApp} type="video" />}
      </GridContainer>
    </>
  );
};
