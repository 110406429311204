import { useEffect, useMemo, useState } from 'react';

export const useIsSystemMuted = (audioTrack: MediaStreamTrack | undefined) => {
  const [isSystemMuted, setIsSystemMuted] = useState(!!audioTrack?.muted);

  useEffect(() => {
    if (!audioTrack) return;

    setIsSystemMuted(audioTrack.muted);

    const onMute = () => setIsSystemMuted(true);
    const onUnmute = () => setIsSystemMuted(false);

    audioTrack.addEventListener('mute', onMute);
    audioTrack.addEventListener('unmute', onUnmute);

    return () => {
      audioTrack.removeEventListener('mute', onMute);
      audioTrack.removeEventListener('unmute', onUnmute);
    };
  }, [audioTrack]);

  return useMemo(() => ({ isSystemMuted }), [isSystemMuted]);
};
