import {
  type FC,
  type PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useCaptureAssetCandidateFileContext } from '../../../context/captureAssetFile';
import { useNavigateBack } from '../../../hooks/useNavigateBack';

type MaybeFunction = VoidFunction | undefined;

type BackButtonBehaviorContextValue = {
  onClick(): void;
  setCustomBehavior(customBehavior: MaybeFunction): void;
};

type Options = {
  customBackButtonBehavior?: MaybeFunction;
};

const BackButtonBehaviorContext = createContext<BackButtonBehaviorContextValue>({
  onClick: () => {
    throw new Error('BackButtonBehaviorContext not initialized');
  },
  setCustomBehavior: () => {
    throw new Error('BackButtonBehaviorContext not initialized');
  },
});

export const BackButtonBehaviorProvider: FC<PropsWithChildren> = ({ children }) => {
  const navigateBack = useNavigateBack();
  const [options, setOptions] = useState<Options>({
    customBackButtonBehavior: undefined,
  });
  const setCustomBehavior = useCallback((customBackButtonBehavior: MaybeFunction) => {
    setOptions({ customBackButtonBehavior });
  }, []);

  const { clearCaptureAssetFile } = useCaptureAssetCandidateFileContext();
  const { customBackButtonBehavior } = options;
  const onClick = useCallback(() => {
    if (customBackButtonBehavior) {
      customBackButtonBehavior();
    } else {
      navigateBack();
      clearCaptureAssetFile();
    }
  }, [customBackButtonBehavior, clearCaptureAssetFile, navigateBack]);

  const contextValue = useMemo(
    () => ({
      onClick,
      setCustomBehavior,
    }),
    [onClick, setCustomBehavior]
  );

  return <BackButtonBehaviorContext.Provider value={contextValue}>{children}</BackButtonBehaviorContext.Provider>;
};

export const useCaptureBackButtonBehavior = (customBehavior?: MaybeFunction) => {
  const context = useContext(BackButtonBehaviorContext);

  const { setCustomBehavior } = context;

  useEffect(() => {
    setCustomBehavior(customBehavior);
    return () => setCustomBehavior(undefined);
  }, [customBehavior, setCustomBehavior]);

  return context;
};
